import React, { useEffect, useState } from 'react';
import StoreApi from '../../../services/StoreApi';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

interface ClienteFormProps {
    data?: any;
    onSuccess?: () => void;
}
const cookies = new Cookies();

export const ClienteForm = (data: ClienteFormProps) => {
    const [formData, setFormData] = useState({
        codUserInstance: cookies.get('user').user_instance[0].id || '',
        nomeRazaoSocial: data?.data?.nomeRazaoSocial || '',
        apelidoNomeFantasia: data?.data?.apelidoNomeFantasia || '',
        cpfCnpj: data?.data?.cpfCnpj || '',
        rg: data?.data?.rg || '',
        dataNascimento: data?.data?.dataNascimento || '',
        telefone: data?.data?.telefone || '',
        celular: data?.data?.celular || '',
        fax: data?.data?.fax || '',
        email: data?.data?.email || '',
        site: data?.data?.site || '',
        endereco: data?.data?.endereco || '',
        numero: data?.data?.numero || '',
        complemento: data?.data?.complemento || '',
        bairro: data?.data?.bairro || '',
        cidade: data?.data?.cidade || '',
        estado: data?.data?.estado || '',
        cep: data?.data?.cep || '',
        sexo: data?.data?.sexo || '',
        expedicaoRg: data?.data?.expedicaoRg || '',
        ufRg: data?.data?.ufRg || '',
        indicadorIeDestinatario: data?.data?.indicadorIeDestinatario || '',
        ie: data?.data?.ie || '',
        tipoListaPreco: data?.data?.tipoListaPreco || '',
    } as any);

    const [mask, setMask] = useState(data?.data?.cpfCnpj !== undefined ? "999.999.999-99" : ''); // Começa sem máscara
    const [maskApplied, setMaskApplied] = useState(false); // Controla se a máscara já foi aplicada

    useEffect(() => {
        const numericValue = formData.cpfCnpj.replace(/\D/g, ""); // Remove caracteres não numéricos
console.log(data, cookies.get('user').user_instance[0].id);
        if (maskApplied) {
            if (numericValue.length === 11) {
                setMask("999.999.999-99"); // CPF
            } else if (numericValue.length >= 12 && numericValue.length <= 14) {
                setMask("99.999.999/9999-99"); // CNPJ
            }
            else {
                setMask(""); // Remove a máscara se o valor for inválido
                setMaskApplied(false);
            }

        }
    }, [formData.cpfCnpj, maskApplied]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        if (name === "cpfCnpj") {

            const numericValue = value.replace(/\D/g, ""); // Remove caracteres não numéricos


            if (numericValue.length === 0) {
                setFormData((prev: any) => ({
                    ...prev,
                    ['cpfCnpj']: '',
                }));
                setMask(""); // Remove a máscara se o valor for inválido
                setMaskApplied(false);
            }
            else {
                setMask(""); // Remove a máscara se o valor for inválido
                setMaskApplied(false);
            }

        }
        setFormData((prev: any) => ({
            ...prev,
            [name]: value, // Mantém o valor digitado no estado
        }));


    };

    const handleBlur = () => {
        const numericValue = formData.cpfCnpj.replace(/\D/g, "");

        if (numericValue.length >= 11 && numericValue.length <= 14) {
            setMaskApplied(true); // Ativa a máscara quando atingir CPF ou CNPJ
        } else {
            setMask(""); // Remove a máscara se o valor for inválido
            setMaskApplied(false);
        }
    };

    const handleValidationErrors = (errors: Record<string, string[]>) => {
        Object.keys(errors).forEach((field) => {
            errors[field].forEach((message) => {
                toast.error(formatValidationMessage(field, message), {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                });
            });
        });
    };

    const formatValidationMessage = (field: string, message: string) => {
        // Mapeia mensagens comuns de validação para torná-las mais amigáveis
        const fieldNames: Record<string, string> = {
            cpfCnpj: 'CPF ou CNPJ',
            endereco: 'Endereço',
            numero: 'Número'
        };

        const translatedMessage: any = {
            'validation.required': `${fieldNames[field] || field} é obrigatório.`,
            'validation.email': `${fieldNames[field] || field} deve ser um e-mail válido.`,
            'validation.max': `${fieldNames[field] || field} ultrapassou o tamanho máximo permitido.`,
            'validation.min': `${fieldNames[field] || field} tem um valor muito curto.`,
            'validation.in': `${fieldNames[field] || field} contém um valor inválido.`
        };

        return translatedMessage[message] || `${fieldNames[field] || field}: ${message}`;
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();

        setFormData((prev: any) => ({
            ...prev,
            ['cpfCnpj']: formData.cpfCnpj.replace(/\D/g, ""),
            ['telefone']: formData.telefone.replace(/\D/g, ""),
        }));

        if (data?.data?.id !== undefined) {
            StoreApi.updateCliente(data?.data?.id, formData)
                .then((r: any) => {
                    if (r.data.erros !== undefined) {
                        handleValidationErrors(r.data.erros);
                    }
                    else if (r.data.erro !== undefined) {
                        handleValidationErrors(r.data.erro);
                    }
                    else {
                        toast.success("Cliente foi atualizado.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            theme: "light",
                        });

                        // ✅ Acessando diretamente a função onSuccess da prop
                        if (data.onSuccess) {
                            data.onSuccess();
                        }
                    }
                })
                .catch((error) => {
                    console.error("Erro ao atualizar cliente:", error);
                    alert("Erro ao atualizar cliente.");
                });
        }
        else {
            StoreApi.createCliente({
                ...formData,
                ['cpfCnpj']: formData.cpfCnpj.replace(/\D/g, ""),
                ['telefone']: formData.telefone.replace(/\D/g, ""),
            })
                .then((r: any) => {
                    console.log(r);
                    if (r.data.erros !== undefined) {
                        handleValidationErrors(r.data.erros);
                    }
                    else if (r.data.erro !== undefined) {
                        handleValidationErrors(r.data.erro);
                    }
                    else {
                        toast.success("Cliente foi criado.", {
                            position: "top-right",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            theme: "light",
                        });

                        // ✅ Acessando diretamente a função onSuccess da prop
                        if (data.onSuccess) {
                            data.onSuccess();
                        }
                    }
                })
                .catch((error) => {
                    console.error("Erro ao adicionar cliente:", error);
                    alert("Erro ao adicionar cliente.");
                });
        }

        }

        return (
            <div className="block w-full p-2 bg-white h-full">
                <label htmlFor="valor-produto" className="block text-md font-semibold text-gray-700 mb-1">
                    Informações Cliente:
                </label>
                <section id="editor1" className="bg-white p-2 rounded-lg w-[100%] grid grid-cols-1 gap-2 md:grid-cols-4 lg:grid-cols-4">
                    {Object.keys(formData).map((key) => (
                        <>
                        {key !== 'codUserInstance' &&
                        <div key={key}>
                            <label htmlFor={key} className="block text-md font-semibold text-gray-700 mb-1 capitalize">
                                {key === 'nomeRazaoSocial' ? "Nome Completo / Razão Social" : key === 'apelidoNomeFantasia' ? 'Apelido / Nome Fantasia' : key === 'indicadorIeDestinatario' ? 'Indicador IE Destinatario' : key === 'ie' ? 'IE' : key === 'rg' ? 'RG' : key === 'cpfCnpj' ? 'CPF / CNPJ' : key === 'expedicaoRg' ? 'Expedição RG' : key === 'ufRg' ? 'UF RG' : key.replace(/([A-Z])/g, ' $1').trim()}:
                            </label>
                            {key === 'telefone' || key === 'celular' ? (
                                <InputMask
                                    mask={key === 'telefone' ? "(99) 9999-9999" : "(99) 99999-9999"}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    name={key}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                    placeholder={key === 'telefone' ? "(00) 0000-0000" : "(00) 00000-0000"}
                                />
                            ) : key === 'email' ? (
                                <input
                                    type="email"
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                    placeholder="email@example.com"
                                />
                            ) : key === 'cep' ? (
                                <InputMask
                                    mask="99999-999"
                                    value={formData[key]}
                                    onChange={handleChange}
                                    name={key}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                    placeholder="00000-000"
                                />
                            ) : key === 'dataNascimento' ? (
                                <InputMask
                                    mask="99/99/9999"
                                    value={formData[key]}
                                    onChange={handleChange}
                                    name={key}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                    placeholder="DD/MM/AAAA"
                                />
                            ) : key === 'cpfCnpj' ? (
                                <InputMask
                                    mask={mask}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    name={key}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                    placeholder="000.000.000-00 ou 00.000.000/0000-00"
                                />
                            ) : key !== '' && (
                                <input
                                    id={key}
                                    type="text"
                                    name={key}
                                    value={formData[key]}
                                    onChange={handleChange}
                                    className="w-full p-2 border border-gray-300 rounded-lg shadow-sm focus:ring-2 text-black focus:ring-blue-500 focus:outline-none h-10"
                                />
                            )}

                        </div>
                        }
                  </>  ))}
                    <div className="md:col-span-2 lg:col-span-3 flex gap-4">
                        <button
                            onClick={handleSubmit}
                            className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                        >
                            Salvar
                        </button>
                    </div>
                </section>
            </div>
        );
    };

    export default ClienteForm;
