import api from "./Api";

const listUser = (username: any) => {
    return api.get("/user", {
        params: {
            username
        },
    }).then((response: any) => {

        return response;
    });
};

const showUser = (id: any) => {
    return api.get("/user/"+id).then((response: any) => {
        return response;
    });
};

const showUsersAtendentes = () => {
    return api.get("/instances/atendente/").then((response: any) => {
        return response;
    });
};

const showUsersAtendentesAll = () => {
    return api.get("/instances/atendentes/all").then((response: any) => {
        return response;
    });
};

const updateUserInstanceActive = (id: any) => {
    return api.put("/user/instance/active/"+id, {
    }).then((response: any) => {
        return response;
    });
};

const updateUserInstanceActiveIA = (id: any) => {
    return api.put("/user/instance/active/ai/"+id, {
    }).then((response: any) => {
        return response;
    });
};


export default {
    listUser,
    showUser,
    updateUserInstanceActive,
    showUsersAtendentesAll,
    updateUserInstanceActiveIA

}