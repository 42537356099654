
import React, { useEffect, useState } from 'react';
function Modal({ ...props }) {

	console.log(props['show']);


    useEffect(() => {
        if (props['show'] === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [props['show']]); 
    
	   const sizeClass = () => {
        switch (props['size']) {
            case 'small':
                return 'max-w-md';
            case 'large':
                return 'max-w-2xl';
            case 'extra large':
                return 'max-w-4xl';
            case 'fullscreen':
                return 'max-w-full h-full'; 
            default:
                return 'max-w-xl'; 
        }
    };

    return (
        <>  
            {props['show'] !== false ? (
                <div
                    className={`fixed w-screen h-full bg-gray-300 bg-opacity-75 grid z-999 items-center justify-center flex overflow-x-hidden overflow-y-auto inset-0 rounded border-xl`}
                    id="edit-admin-modal"
                >      
                    <div className={`relative w-screen h-full ${sizeClass()} md:h-auto border-xl rounded-full`}>
                        <div className="relative bg-white rounded-lg shadow bg-gray-100 border-xl rounded-full">
                            <div className=" justify-end p-5 rounded-t bg-green-400">
                                <h3 className="text-xl font-semibold justify-end bg-gray-100">{props['title']}</h3>
                            </div>
                            <div className={`justify-center border-xl ${props['colorbackground'] !== undefined ? props['colorbackground'] : 'bg-gray-100'}`} > 
                                {props['list']}
                            </div>
                            <div className="justify-center text-dark rounded-b bg-gray-100 border-xl">
                                {props['footer']} 
                            </div>
                        </div>
                    </div>
                </div>
            ) : ''}
        </>
    );
}

export default Modal;