import React, { useRef, useEffect, useState } from "react";
import EmojiPicker from "../../../components/Utils/EmojiPicker";

interface ChatComponentProps {
  showChat: boolean;
  setShowChat: (show: boolean) => void;
  SelectedContactImage: string;
  SelectedContactName: string;
  Conversas: any[];
  SelectedContact: string;
  wrapText: (text: string, length: number) => string;
  formatLastMessageTime: (timestamp: number) => string;
  renderConversation: (text: string) => string;
  mensagemResponse: string;
  onEmojiSelectResponse: (emoji: string) => void;
  mensagemResponseOriginal: string;
  handleKeyDown: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  handleChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const ChatComponent: React.FC<ChatComponentProps> = ({
  showChat,
  setShowChat,
  SelectedContactImage,
  SelectedContactName,
  Conversas,
  SelectedContact,
  wrapText,
  formatLastMessageTime,
  renderConversation,
  mensagemResponse,
  onEmojiSelectResponse,
  mensagemResponseOriginal,
  handleKeyDown,
  handleChange,
}) => {
  const chatboxRef = useRef<HTMLDivElement>(null);
  const [isTyping, setIsTyping] = useState(false); // Indicador de digitação
  const [visibleMessages, setVisibleMessages] = useState(20); // Número de mensagens visíveis

  // Scroll automático para o final ao carregar o chat
  useEffect(() => {
    if (chatboxRef.current) {
      chatboxRef.current.scrollTop = chatboxRef.current.scrollHeight;
    }
  }, [Conversas, mensagemResponse]);

  // Simula um indicador de digitação
  useEffect(() => {
    if (mensagemResponseOriginal) {
      setIsTyping(true);
      const typingTimeout = setTimeout(() => setIsTyping(false), 1000); // Simula 1 segundo de digitação
      return () => clearTimeout(typingTimeout);
    } else {
      setIsTyping(false);
    }
  }, [mensagemResponseOriginal]);

  // Carregar mais mensagens ao rolar para cima
  const handleScroll = () => {
    if (chatboxRef.current) {
      const { scrollTop } = chatboxRef.current;
      if (scrollTop === 0) {
        setVisibleMessages((prev) => prev + 10); // Carrega mais 10 mensagens
      }
    }
  };

  // Filtra mensagens visíveis (oculta mensagens muito antigas)
  const filteredMessages = Array.isArray(Conversas) ? Conversas.slice(-visibleMessages) : [];

  return (
    <div
      id="chat-container"
      className={`${showChat ? "" : "hidden"} fixed bottom-0 right-4 w-96`}
    >
      <div className="bg-white shadow-lg rounded-lg max-w-lg w-full">
        {/* Cabeçalho do Chat */}
        <div className="p-4 border-b bg-green-500 text-white rounded-t-lg flex justify-between items-center">
          <div className="flex items-center">
            <img
              className="w-10 h-10 rounded-full"
              src={SelectedContactImage || "./Anoni.jpg"}
              alt="Contato"
            />
            <div className="ml-4">
              <p className="text-white font-semibold">{SelectedContactName}</p>
              {isTyping && (
                <p className="text-xs text-gray-200">Digitando...</p>
              )}
            </div>
          </div>
          <button
            onClick={() => setShowChat(false)}
            className="text-gray-300 hover:text-gray-400 focus:outline-none focus:text-gray-400"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Área de Mensagens */}
        <div
          id="chatbox"
          ref={chatboxRef}
          className="p-4 h-80 overflow-y-auto bg-gray-50 scrollbar-thin scrollbar-thumb-gray-300 scrollbar-track-gray-100"
          onScroll={handleScroll}
        >
          {filteredMessages.length > 0 &&
            filteredMessages.map((r: any, index: number) => {
              const messageData = JSON.parse(r.data);
              const isReceived = messageData.Type === "receveid_message";
              const isSent = messageData.Type === "send_message";

              if (
                isReceived &&
                SelectedContact ===
                  messageData.Body.Info.SenderJid.split("@")[0]
              ) {
                return (
                  <div key={index} className="mb-4">
                    <div className="flex justify-center mb-2">
                      <div
                        className="rounded py-2 px-4 bg-gray-200"
                      >
                        <p className="text-xs text-gray-600">
                          {new Date(r.created_at).toLocaleString("pt-BR")}
                        </p>
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div
                        className="rounded-lg py-2 px-3 bg-white shadow-sm"
                        style={{ maxWidth: "70%" }}
                      >
                        <p className="text-sm text-gray-800">
                          {wrapText(
                            messageData.Body.Info.Source.message.conversation,
                            32
                          )}
                        </p>
                        <p className="text-right text-xs text-gray-500 mt-1">
                          {formatLastMessageTime(
                            messageData.Body.Info.Source.messageTimestamp
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              } else if (
                isSent &&
                SelectedContact ===
                  messageData.Body.Info.RemoteJid.split("@")[0]
              ) {
                return (
                  <div key={index} className="mb-4">
                    <div className="flex justify-center mb-2">
                      <div
                        className="rounded py-2 px-4 bg-gray-200"
                      >
                        <p className="text-xs text-gray-600">
                          {new Date(r.created_at).toLocaleString("pt-BR")}
                        </p>
                      </div>
                    </div>
                    <div className="flex justify-end mb-2">
                      <div
                        className="rounded-lg py-2 px-3 bg-green-100 shadow-sm"
                        style={{ maxWidth: "70%" }}
                      >
                        <p className="text-sm text-gray-800">
                          {renderConversation(
                            messageData.Body.Info.Source.message.conversation
                          )}
                        </p>
                        <p className="text-right text-xs text-gray-500 mt-1">
                          {formatLastMessageTime(
                            messageData.Body.Info.Source.messageTimestamp
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              }
              return null;
            })}

          {/* Mensagem de Resposta (Exemplo) */}
          {mensagemResponse && (
            <div className="mb-4">
              <div className="flex justify-center mb-2">
                <div
                  className="rounded py-2 px-4 bg-gray-200"
                >
                  <p className="text-xs text-gray-600">
                    {new Date().toLocaleString("pt-BR")}
                  </p>
                </div>
              </div>
              <div className="flex justify-end mb-2">
                <div
                  className="rounded-lg py-2 px-3 bg-green-100 shadow-sm"
                  style={{ maxWidth: "70%" }}
                >
                  <p className="text-sm text-gray-800">
                    {renderConversation(mensagemResponse)}
                  </p>
                  <p className="text-right text-xs text-gray-500 mt-1">
                    {formatLastMessageTime(Date.now())}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* Área de Input */}
        <div className="p-4 border-t flex items-start space-x-2 bg-gray-100">
          <EmojiPicker onEmojiSelect={onEmojiSelectResponse} />
          <textarea
            placeholder="Digite uma mensagem..."
            className="w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 resize-none"
            rows={1}
            value={mensagemResponseOriginal}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
          />
          <button className="bg-green-500 text-white px-4 py-2 rounded-md hover:bg-green-600 transition duration-300">
            Enviar
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatComponent;