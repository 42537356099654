import React, { useEffect, useRef, useState, ChangeEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import moment from 'moment';
import chatProApi from '../../../services/ChatProApi';
import chatConfigApi from '../../../services/ChatConfigApi';
import Cookies from 'universal-cookie';
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import UserApi from '../../../services/UserApi';
import AuthService from "../../../services/Auth";
import BotConfig from './BotConfig';
import { MultiSelectComboBox, MultiSelectComboBoxChangeEvent } from '@hilla/react-components/MultiSelectComboBox';
import { Button, Dialog, DialogTrigger, Heading, Input, Label, Modal, TextField } from 'react-aria-components';
import { BanknotesIcon, BoltIcon, ChatBubbleLeftRightIcon, ChevronLeftIcon, FunnelIcon, HomeIcon, LinkIcon, ShoppingCartIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { ChatBubbleBottomCenterTextIcon, RocketLaunchIcon } from '@heroicons/react/24/outline';
import StoreApi from '../../../services/StoreApi';
import { CheckCircleIcon } from '@heroicons/react/20/solid'
import ChatComponent from './ChatComponent';
import Pako from 'pako';
import ChatProApi from '../../../services/ChatProApi';

function Home() {
  const paginate = 4;
  const cookies = new Cookies();
  const [infoInstance, setInfoInstance] = useState([{}] as any);
  const [profiles, setProfiles] = useState<any[]>([]);
  const [atendentes, setAtendentes] = useState([] as any);
  const [clientes, setClientes] = useState([] as any);
  const [filaEspera, setFilaEspera] = useState([] as any);
  var [mensagemResponse, setMensagemResponse] = useState("");
  var [mensagemResponseOriginal, setMensagemResponseOriginal] = useState("");
  var [mensagemsInicio, setMensagemInicio] = useState("");
  var [mensagemsFim, setMensagemFim] = useState("");
  var [motivoCancelamento, setMotivoCancelamento] = useState("");
  const instancesCliente = cookies.get('user')?.instances_cliente;

  const [items, setItems] = useState([] as any);
  const [pedidos, setPedidos] = useState([] as any);
  const userInstance = cookies.get('instancias');
  const [perfisClientes, setPerfisClientes] = useState<{ [key: string]: any }>({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [filterStatus, setFilterStatus] = useState<string>("");
  const [filterDate, setFilterDate] = useState<string>("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {

    // Converte Base64 para Uint8Array (binário)
    const compressedDataAtendentes = Uint8Array.from(atob(cookies.get('atendentes')), c => c.charCodeAt(0));
    const jsonString = new TextDecoder().decode(Pako.inflate(compressedDataAtendentes));
    setAtendentes(JSON.parse(jsonString));
    const cookiesData = cookies.get('clientes');
    if (cookiesData) {
      const parsedData = decompressAndDecode(cookiesData);
    
    }

    const carregarPerfis = async () => {
      setLoading(true); // Ativa carregamento
      const perfis: { [key: string]: any } = {};

      for (const pedido of pedidos) {
        try {
          console.log(pedido)
          const response = await chatProApi.getProfile({ number: "55" + pedido.telefoneCliente });
          perfis[pedido.telefoneCliente] = response;
        } catch (error) {
          console.error(`Erro ao carregar perfil do cliente ${pedido.telefoneCliente}:`, error);
        }
      }

      setPerfisClientes(perfis);
      setLoading(false); // Finaliza carregamento
    };

    if (pedidos.length > 0) {
      carregarPerfis();
    } else {
      setLoading(false); // Se não há pedidos, remove o carregamento
    }

    fetchProfiles();
  }, [pedidos]);

  const decompressAndDecode = (value: string) => {
    try {
        console.log('Valor inicial (Base64):', value);

        // ✅ Decodifica de Base64
        const binaryString = atob(value);
        console.log('Valor decodificado:', binaryString);

        // ✅ Converte para array de bytes
        const byteArray = Uint8Array.from(binaryString, (char) => char.charCodeAt(0));
        console.log('Array de bytes:', byteArray);

        // ✅ Descompacta usando pako (gzip)
        const decodedString = Pako.inflate(byteArray, { to: 'string' });

        // ✅ Faz o parse para JSON
        if (decodedString.startsWith('{') || decodedString.startsWith('[')) {
            console.log('JSON detectado:', decodedString);
            
            setClientes(JSON.parse(decodedString));
        } else {
            throw new Error('String descompactada não é JSON válida');
        }
    } catch (error) {
        console.error('Erro ao descomprimir o valor:', error);
        return null;
    }
  };

  // Função para buscar pedidos
  const fetchPedidos = async (page = 1) => {
    setLoading(true);

    // Obter cookie e verificar se é válido
    const user = cookies.get("user");
    if (user === null) {
      setLoading(false);
      console.error("Erro: Cookie 'user' não encontrado.");
      return;
    }

    let filters: Record<string, any> = {};

    // Definir filtro da empresa dependendo do papel do usuário
    if (user?.roles?.[0]?.name === "atendente") {
      filters = {
        codStoreEmpresa: { filterType: "EQUALS", filterValue: user?.data?.instances_cliente?.[0]?.id }
      };
    } else if (user?.data?.user_instance?.[0]?.id) {
      filters = {
        codStoreEmpresa: { filterType: "EQUALS", filterValue: user?.data?.user_instance?.[0]?.id }
      };
    }

    // Aplicar filtros opcionais
    if (filterStatus) {
      filters["status"] = { filterType: "EQUALS", filterValue: filterStatus };
    }

    if (filterDate) {
      filters["created_at"] = {
        filterType: "ILIKE",
        filterValue: `${filterDate}%` // Apenas adiciona '%' no final para busca parcial
      };
    }

    // Parâmetros de ordenação
    const orderBy = { id: "desc" };

    try {
      // Chamada para API
      const response = await StoreApi.listStorePedido(paginate, filters, orderBy, page);

      // Verificar se os dados foram retornados corretamente
      if (!response?.data?.data) {
        throw new Error("Resposta inválida da API. Verifique o backend.");
      }

      // Atualizar estado com os pedidos
      setPedidos(response.data.data || []);
      setCurrentPage(response.data.current_page);
      setTotalPages(response.data.last_page);
    } catch (error) {
      console.error("Erro ao buscar pedidos:", error);
      setPedidos([]);
    }
    setLoading(false);
  };

  // Handlers para navegação
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      fetchPedidos(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      fetchPedidos(currentPage + 1);
    }
  };

  const handleFinalizarPedido = (pedido: any) => {
    var bodyPedido = pedido;
    bodyPedido.status = 'entregue';
    StoreApi.updateStorePedido(bodyPedido.id, bodyPedido).then((r) => {
      fetchPedidos();
    })
  }

  const handleAceitarPedido = (pedido: any) => {
    var bodyPedido = pedido;
    bodyPedido.status = 'em_preparacao';
    StoreApi.updateStorePedido(bodyPedido.id, bodyPedido).then((r) => {
      fetchPedidos();
    })
  }

  const handleCancelarPedido = (pedido: any) => {
    var bodyPedido = pedido;
    bodyPedido.status = 'cancelado';
    bodyPedido.motivoCancelamento = motivoCancelamento;
    StoreApi.updateStorePedido(bodyPedido.id, bodyPedido).then((r) => {
      fetchPedidos();
    })
  }

  const handlePedidoSaiuParaEntrega = (pedido: any) => {
    var bodyPedido = pedido;
    bodyPedido.status = 'saiu_para_entrega';
    StoreApi.updateStorePedido(bodyPedido.id, bodyPedido).then((r) => {
      fetchPedidos();
    })
  }

  const formatCurrency = (val: number, sign: string) => {
    const c = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(val);

    const z = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(0);

    if (c !== z) {
      return c;
    }
    else {
      return z;
    }
  };

  const contatosPorCategoria: { [key: string]: number } =
    userInstance && userInstance.length > 0 && userInstance.codUserInstance !== 0
      ? userInstance[0]?.categoria_atendente?.reduce((acc: any, categoria: any) => {
        if (filaEspera !== undefined && filaEspera !== null && categoria.id !== undefined && categoria.id !== 0) {
          acc[categoria.id] = filaEspera?.filter((fila: any) => fila.codCategoria === categoria.id).length;
          return acc;
        }

      }, {})
      : {};

  const contatosPorCategoriaAtendente: { [key: string]: number } =
    instancesCliente && instancesCliente.length > 0 && instancesCliente.codUserInstance !== 0
      ? instancesCliente[0]?.categoria_atendente?.reduce((acc: any, categoria: any) => {
        if (filaEspera !== undefined && filaEspera !== null) {
          acc[categoria.id] = filaEspera?.filter((fila: any) => fila.codCategoria === categoria.id).length;
          return acc;
        }
      }, {})
      : {};

  const [showChat, setShowChat] = useState(false);
  const [number, setNumber] = useState(null);
  const [editing, setEditing] = useState<'response' | 'inicio' | 'fim'>('response');

  const [contatos, setContatos] = useState([] as any);

  const [ImgPerfil, setImgPerfil] = useState("");
  const [SelectedContact, setSelectedContact] = useState(0);
  const [SelectedContactImage, setSelectedContactImage] = useState("");
  const [SelectedContactName, setSelectedContactName] = useState("");
  const [Conversas, setConversas] = useState({} as any);

  const handleToggle = (event: any) => {
    const targetId = event.currentTarget.getAttribute('data-target');
    const submenu = document.getElementById(targetId);
    const icon = event.currentTarget.querySelector('.menu-icon');

    // Toggle submenu visibility
    if (submenu !== null) {
      submenu.classList.toggle('max-h-0');
      submenu.classList.toggle('max-h-screen');
      submenu.classList.toggle('overflow-hidden');
    }

    // Toggle icon rotation
    icon.classList.toggle('rotate-180');
  };

  const wrapText = (text: string, maxLineLength: number): string => {
    let wrappedText = '';
    let currentLineLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (currentLineLength >= maxLineLength && text[i] !== '\n') {
        wrappedText += '\n';
        currentLineLength = 0;
      }
      wrappedText += text[i];
      currentLineLength++;

      if (text[i] === '\n') {
        currentLineLength = 0;
      }
    }

    return wrappedText;
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    setMensagemResponseOriginal(originalText);
    const wrappedText = wrapText(originalText, 32);
    setMensagemResponse(wrappedText);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (editing === 'response') {
        setMensagemResponse(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'inicio') {
        setMensagemInicio(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'fim') {
        setMensagemFim(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      }
    }
  };

  const renderConversation = (conversation: any) => {
    return conversation.split('\n\n').map((text: any, index: any, array: any) => (
      <React.Fragment key={index}>
        <p className="text-sm mt-1">{text}</p>
        {index < array.length - 1 && <br />}
      </React.Fragment>
    ));
  }


  const formatLastMessageTime = (unixTimestamp: any) => {
    const date = new Date(unixTimestamp * 1000); // Convertendo de segundos para milissegundos
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    };
    return date.toLocaleString('pt-BR', options);
  };

  const fetchProfiles = async () => {
    try {
      const compressedData = Uint8Array.from(atob(cookies.get('atendentes')), c => c.charCodeAt(0));

      // Descomprime os dados
      const jsonString = new TextDecoder().decode(Pako.inflate(compressedData));

      const atendentes = JSON.parse(jsonString) || [];

      const profileData = await Promise.all(
        atendentes.map(async (a: any) => {
          try {
            const response = await ChatProApi.getProfile({ number: "55" + a.telefone });

            const departamentosAtendente = a.instances_cliente?.flatMap((instance: any) => instance.atendente_alocados) || [];
            const departamentosSelecionados = cookies.get('instancias')[0].categoria_atendente.filter((departamento: any) =>
              departamentosAtendente.some((alocado: any) =>
                alocado.codAtendente === a.id && alocado.idCategoria === departamento.id
              )
            );

            const img = response?.data?.response?.imgUrl || "./Anoni.jpg";
            return { ...a, img, departamentosSelecionados };
          } catch (error) {
            console.error("Erro ao buscar perfil:", error);
            return { ...a, img: "./Anoni.jpg" }; // Retorna com imagem padrão se houver erro
          }
        })
      );

      setProfiles(profileData);
    } catch (error) {
      console.error("Erro ao buscar perfis:", error);
    }
  };

  useEffect(() => {
    chatProApi.listChats().then((contatos: any) => {
      if (contatos?.data?.response?.length > 0) {

        setContatos(contatos.data?.response);
        setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
        chatProApi.getProfile({ 'number': contatos.data?.response[0]['jid'].split('@')[0] }).then((r: any) => {
          setNumber(contatos.data?.response[0]['jid'].split('@')[0]);
          if (r.data?.response?.name !== undefined) {
            setSelectedContactName(r?.data.response?.name);
          }
          if (r?.data?.response?.imgUrl !== undefined)
            setSelectedContactImage(r?.data.response?.imgUrl);
        })

        if (cookies.get('user')?.roles[0]?.name === 'atendente') {
          UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
            if (user.data.user_instance !== undefined || user.data.user_instance.length > 0)
              setConversas(user.data.user_instance[0]?.user_instance_web_hook);

          })
        }
        else {
          if (cookies.get('user') !== undefined)
            UserApi.showUser(cookies.get('user').id).then((user: any) => {

              setConversas(user.data.user_instance[0]?.user_instance_web_hook);

            })
        }

      }
      else {

      }
    })

    fetchPedidos();

    if (cookies.get('user')?.roles[0]?.name !== 'atendente') {
      setItems(cookies.get('user')?.user_instance[0]?.categoria_atendente);
      chatConfigApi.getFilaEspera({
        "filters": {
          "codUserInstance": cookies.get('instancias')[0].id
        }
      }).then((response) => {
        setFilaEspera(response);
      });

    }
    else {
      setItems(cookies.get('user')?.instances_cliente[0]?.categoria_atendente);
      chatConfigApi.getFilaEspera({
        "filters": {
          "codUserInstance": cookies.get('user')?.instances_cliente[0].id
        }
      }).then((response) => {
        setFilaEspera(response);
      });
    }

    const intervalo = setInterval(() => {

      if (cookies.get('user') !== undefined) {
        chatProApi.getStatus().then((status: any) => {
          if (status !== undefined) {

            if (status.data?.response?.connected === true) {

              if (status.data?.response?.connected) {
                var phone = status.data.response.info['Wid'].split('@');
                chatProApi.getProfile({ 'number': phone[0] }).then((r) => {
                  if (r?.data?.response !== undefined) {
                    setImgPerfil(r?.data?.response['imgUrl']);
                  }
                  chatProApi.listChats().then((contatos: any) => {
                    if (contatos?.data?.response?.length > 0) {

                      setContatos(contatos.data?.response);
                      setSelectedContact(contatos.data.response[0]['jid'].split('@')[0]);
                      chatProApi.getProfile({ 'number': contatos.data?.response[0]['jid'].split('@')[0] }).then((r: any) => {
                        setNumber(status.data.response.info.Wid.split('@')[0]);
                        if (r.data?.response?.name !== undefined) {
                          setSelectedContactName(r?.data.response?.name);
                        }
                        if (r?.data?.response?.imgUrl !== undefined)
                          setSelectedContactImage(r?.data.response?.imgUrl);
                      })

                      if (cookies.get('user')?.roles[0]?.name === 'atendente') {
                        UserApi.showUser(cookies.get('user').instances_cliente[0].codUserCliente).then((user: any) => {
                          if (user.data.user_instance !== undefined || user.data.user_instance.length > 0)
                            setConversas(user.data.user_instance[0]?.user_instance_web_hook);

                        })
                      }
                      else {
                        if (cookies.get('user') !== undefined)
                          UserApi.showUser(cookies.get('user').id).then((user: any) => {

                            setConversas(user.data.user_instance[0]?.user_instance_web_hook);

                          })
                      }

                    }
                    else {

                    }
                  })
                })



              }
            }

          }
        })
      }


    }, 90 * 1000);

    return () => clearInterval(intervalo);
  }, []);

  const onEmojiSelectResponse = (emoji: any) => {
    setMensagemResponse(mensagemResponse + emoji);
  };


  const handleSelectNumber = (number: any) => {
    if (number['jid'] !== undefined || number !== undefined) {

      chatProApi.getProfile({ 'number': number['jid'] !== undefined ? number['jid'].split('@')[0] : number }).then((r: any) => {
        setNumber(number['jid'] !== undefined ? number['jid'].split('@')[0] : number);
        setSelectedContactName(r.data.response?.name);
        setSelectedContactImage(r.data.response?.imgUrl);
      })
      setSelectedContact(number['jid'] !== undefined ? number['jid'].split('@')[0] : number);
      setShowChat(true);
      const bottomScroll = document.getElementById("scrollableDiv");
      if (bottomScroll) {
        bottomScroll.scrollTop = bottomScroll.scrollHeight;
      }

    }


  }

  return (
    <>
      <div className="w-full h-auto">
        <div className="h-8 xml:h-28 bg-green-500" />

        <div className="flex flex-col bg-gray-50 sm:flex-row border border-grey rounded shadow-lg mb-10 m-4 h-auto">

          <div className="w-full bg-gray-50 border-md sm:border-md flex flex-col">

            <div className="py-2 bg-gray-50 px-4 bg-grey-lighter flex flex-col w-full">


              <div className="flex flex-wrap md:m-2">

                <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
                  <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>

                    {cookies.get('user') !== undefined && cookies.get('user').roles[0]?.name !== 'atendente' ?
                      <>  <div className="ml-2 text-xl text-center">Atendentes Online  <button onClick={() => {
                        UserApi.showUsersAtendentesAll().then((atendentes: any) => {
                          cookies.set('atendentes', atendentes.data.AllUsers, { path: '/', expires: moment().add(1, 'day').toDate() });
                        })
                      }}
                        className="px-2 py-1 text-xs font-semibold text-gray-500 hover:bg-gray-100 rounded-md bg-green-50">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path fill="currentColor" d="M12 4V1L8 5l4 4V6c3.31 0 6 2.69 6 6s-2.69 6-6 6-6-2.69-6-6h-2c0 4.42 3.58 8 8 8s8-3.58 8-8-3.58-8-8-8z" />
                        </svg>
                      </button></div>
                        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
                        <ul
                          className="mt-1 px-2 transition-max-height duration-300"
                        >
                          <div className="flex items-center justify-start my-4 space-x-4">

                          </div>

                          {

                            profiles.map((r: any, key: number) => {
                              return (
                                <li key={r['id']} className="py-3 sm:py-1">
                                  <div className="flex items-center space-x-4">
                                    <div className="flex-shrink-1">
                                      <span className={`absolute mb-10 h-3 w-3 rounded-full ring-2 ring-white ${r.online ? 'bg-green-500' : 'bg-red-500'}`}></span>
                                      <img className="inline-flex h-10 w-10 rounded-full object-cover ring-2 ring-white" src={r.img || ".Anoni.jpg"} alt={r.nome} />
                                    </div>
                                    <div className="flex-1 min-w-0">
                                      <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                        {r.nome}
                                      </p>
                                      <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                        {r.telefone}
                                      </p>
                                    </div>
                                    <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                        onClick={() => { console.log(r); handleSelectNumber(r.telefone); }}>
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                        </svg>

                                      </div>

                                    </div>
                                  </div>
                                </li>

                              );
                            })
                          }
                        </ul>
                      </> :
                      <>
                        <div className="flex items-center mb-2">
                          <FunnelIcon aria-hidden="true"
                            className="h-6 w-6 text-gray-400 hover:text-gray-500" />
                          <div className="ml-4 text-xl font-sans">Fila de Atendimento</div>
                        </div>
                        <div className="flow-root w-full">
                          <ul role="list" className="-mx-2 space-y-1">
                            <li>
                              <div className='text-center'>
                                <button
                                  type="button"
                                  className="flex w-full text-center gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                  aria-controls="menu-1-atendimento"
                                  aria-expanded="true"
                                  data-target="menu-1-atendimento"
                                  onClick={handleToggle}
                                >
                                  {infoInstance[0].info?.Pushname}
                                  <svg
                                    className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                    aria-hidden="true"
                                  >
                                    <path
                                      fillRule="evenodd"
                                      d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                      clipRule="evenodd"
                                    />
                                  </svg>
                                </button>
                                <ul
                                  className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                  id="menu-1-atendimento"
                                >
                                  {cookies.get('instancias') !== undefined && cookies.get('instancias')[0]?.categoria_atendente?.length > 0 &&
                                    cookies.get('instancias')[0]?.categoria_atendente.map((categoria: any, key: any) => {
                                      return (
                                        <li key={key}>
                                          <button
                                            type="button"
                                            className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                            aria-controls={`sub-menu-${key}-atendimento`}
                                            aria-expanded="false"
                                            data-target={`sub-menu-${key}-atendimento`}
                                            onClick={handleToggle}
                                          >
                                            {categoria.setor}
                                            <span className="transform -translate-y-2/2 -translate-x-2/2">
                                              <div className="block w-4 h-4 mt-2 bg-green-400 rounded-full flex items-center justify-center text-white text-center text-sm">
                                                {categoria.id !== undefined ? contatosPorCategoriaAtendente[categoria.id] : <></>}
                                              </div>
                                            </span>
                                            <svg
                                              className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                          <ul
                                            className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                            id={`sub-menu-${key}-atendimento`}
                                          >
                                            {filaEspera.map((fila: any) =>
                                              contatos.map((r: any) => {
                                                if (
                                                  r['jid']?.split('@')[0] === fila.telefoneCliente &&
                                                  categoria.id === fila.codCategoria
                                                ) {
                                                  return (
                                                    <li key={r['jid']} className="py-3 sm:py-1">
                                                      <div className="flex items-center space-x-4">
                                                        <div className="flex-shrink-0">
                                                          {r?.imgUrl !== undefined ?
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={r?.imgUrl || "./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />
                                                            :
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={"./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />}
                                                        </div>
                                                        <div className="flex-1 min-w-0">
                                                          <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                            {r?.name}
                                                          </p>
                                                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                            {r.unread} unanswered messages.
                                                          </p>
                                                          <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                            Last Message <br /> {formatLastMessageTime(r.lastMessageTime)}
                                                          </p>
                                                        </div>
                                                        <div
                                                          className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                                          onClick={() => handleSelectNumber(r)}
                                                        >
                                                          <svg
                                                            width="20px"
                                                            height="20px"
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                          >
                                                            <path
                                                              fillRule="evenodd"
                                                              clipRule="evenodd"
                                                              d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z"
                                                              fill="#1C274C"
                                                            />
                                                          </svg>
                                                        </div>
                                                      </div>
                                                    </li>
                                                  );
                                                }
                                                return null; // Add return null for the case when conditions are not met
                                              })
                                            )}
                                          </ul>
                                        </li>
                                      );
                                    })}
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </>}
                  </div>


                </div>
                <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1" >
                  <div className="d-block p-4 max-w bg-white rounded-lg border border-green-300 shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>

                    {/* <BoltIcon aria-hidden="true"
                        className="h-6 w-6 text-gray-400 hover:text-gray-500" /> */}

                    <div className="ml-2 text-xl text-center font-sans">Clientes</div>
                    <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 p-2 mr-0 scroll-m-2" style={{ maxHeight: '300px', overflow: 'auto' }}>
                      <div className='container overflow-y'>
                        {clientes.map((r: any, key: number) => {
                          return (
                            <li key={r['id']} className="py-3 sm:py-1">
                              <div className="flex items-center space-x-4">
                                <div className="flex-1 min-w-0">
                                  <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                    {r.nomeRazaoSocial}
                                  </p>
                                  <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                    {r.celular}
                                  </p>
                                </div>
                                <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white">
                                  <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                    onClick={() => { console.log(r); handleSelectNumber(r.celular); }}>
                                    <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                    </svg>

                                  </div>

                                </div>
                              </div>
                            </li>

                          );
                        })}

                      </div>
                    </ul>
                  </div>

                </div>

                <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">

                  <div className="d-block p-4 max-w bg-white border-green-300 rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full" style={{ maxHeight: '360px' }}>

                    <div className="ml-4 text-xl text-center font-sans">Últimas Conversas</div>
                    <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
                    <ul role="list" className="divide-y divide-gray-200 dark:divide-gray-700 p-2 mr-0 scroll-m-2" style={{ maxHeight: '300px', overflow: 'auto' }}>
                      <div className='container overflow-y'>
                        {contatos?.length === 0 ?
                          <li className="py-3 sm:py-1">Nenhuma Conversa Salva.</li>
                          :
                          contatos.map((r: any) => {
                            if (r['jid']?.split('@')[0] !== "0") {
                              return (
                                <>
                                  <li className="py-3 sm:py-1">
                                    <div className="flex items-center space-x-4 mr-4">
                                      <div className="flex-shrink-0">
                                        <img
                                          className="h-12 w-12 rounded-full"
                                          src={r?.imgUrl !== undefined ? r?.imgUrl : "./Anoni.jpg"}
                                          alt="Avatar"
                                        />
                                      </div>
                                      <div className="flex-1 min-w-0">
                                        <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                          {r?.name}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                          {r.unread === 0
                                            ? "Nenhuma mensagem não lida"
                                            : r.unread === 1
                                              ? "1 mensagem não lida"
                                              : `${r.unread} mensagens não lidas`}
                                        </p>
                                        <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                          Última mensagem <br /> {formatLastMessageTime(r.lastMessageTime)}
                                        </p>
                                      </div>
                                      <div className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                        onClick={() => { handleSelectNumber(r); }}>
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                          <path fill-rule="evenodd" clip-rule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                        </svg>

                                      </div>
                                    </div>
                                  </li>

                                </>);


                            }
                          })}
                      </div>
                    </ul>
                  </div>
                </div>
                <div className="w-full w-sm:w-1/1 md:w-1/2 lg:w-1/2 xl:w-1/4 p-1">
                  {cookies.get('user') !== undefined && cookies.get('user').roles[0]?.name === 'atendente' ?
                    <>

                    </>
                    :
                    <>
                      <div className="d-block border-green-300 p-4 max-w bg-white rounded-lg border shadow-md sm:p-4 dark:bg-gray-800 dark:border-gray-700 h-full overflow-auto" style={{ maxHeight: '360px' }}>
                        <div className="ml-4 text-xl text-center font-sans">Fila de Atendimento</div>
                        <hr className='w-[80%] justify-self-center h-[2px] bg-green-300 m-1' />
                        <div className="flow-root">
                          <ul role="list" className="-mx-2 space-y-1">
                            <li>
                              <div>
                                <ul
                                  className="mt-1 px-2 max-h-screen overflow-hidden transition-max-height duration-300 ease-in-out"
                                  id="menu-1"
                                >
                                  {cookies.get('instancias')[0]?.categoria_atendente?.length > 0 &&
                                    cookies.get('instancias')[0]?.categoria_atendente.map((categoria: any, key: any) => (
                                      <>
                                        <li key={key}>
                                          <button
                                            type="button"
                                            className="flex w-full items-center gap-x-3 rounded-md p-2 text-left text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50"
                                            aria-controls={`sub-menu-${key}`}
                                            aria-expanded="true"
                                            data-target={`sub-menu-${key}`}
                                            onClick={handleToggle}
                                          >
                                            {categoria.setor}
                                            <span className="transform -translate-y-2/2 -translate-x-2/2"> <div className="flex w-4 h-4 mt-2 bg-green-400 rounded-full items-center justify-center text-white text-center text-md"> {categoria.id !== undefined ? contatosPorCategoria[categoria.id] : <></>}</div></span>
                                            <svg
                                              className="ml-auto h-5 w-5 shrink-0 text-gray-400 transition-transform duration-300 menu-icon"
                                              viewBox="0 0 20 20"
                                              fill="currentColor"
                                              aria-hidden="true"
                                            >
                                              <path
                                                fillRule="evenodd"
                                                d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                                                clipRule="evenodd"
                                              />
                                            </svg>
                                          </button>
                                          <ul
                                            className="mt-1 px-2 max-h-0 overflow-hidden transition-max-height duration-300 ease-in-out"
                                            id={`sub-menu-${key}`}
                                          >
                                            {filaEspera.map((fila: any) =>
                                              contatos.map((r: any) => {
                                                if (r['jid']?.split('@')[0] === fila.telefoneCliente && categoria.id === fila.codCategoria) {

                                                  return (
                                                    <>
                                                      <li key={r['jid']} className="py-3 sm:py-1">
                                                        <div className="flex items-center space-x-4">
                                                          <div className="flex-shrink-0">
                                                            <img
                                                              className="h-12 w-12 rounded-full"
                                                              src={r?.imgUrl !== undefined ? r?.imgUrl : "./Anoni.jpg"}
                                                              alt="Avatar"
                                                            />
                                                          </div>
                                                          <div className="flex-1 min-w-0">
                                                            <p className="text-sm font-medium text-gray-900 truncate dark:text-white">
                                                              {r?.name}
                                                            </p>
                                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                              {r.unread} unanswered messages.
                                                            </p>
                                                            <p className="text-sm text-gray-500 truncate dark:text-gray-400">
                                                              Last Message <br /> {formatLastMessageTime(r.lastMessageTime)}
                                                            </p>
                                                          </div>
                                                          <div
                                                            className="inline-flex items-center text-base font-semibold text-gray-900 dark:text-white cursor-pointer"
                                                            onClick={() => handleSelectNumber(r)}
                                                          >
                                                            <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                              <path fillRule="evenodd" clipRule="evenodd" d="M12 2.75C6.89137 2.75 2.75 6.89137 2.75 12C2.75 13.4811 3.09753 14.8788 3.7148 16.1181C3.96254 16.6155 4.05794 17.2103 3.90163 17.7945L3.30602 20.0205C3.19663 20.4293 3.57066 20.8034 3.97949 20.694L6.20553 20.0984C6.78973 19.9421 7.38451 20.0375 7.88191 20.2852C9.12121 20.9025 10.5189 21.25 12 21.25C17.1086 21.25 21.25 17.1086 21.25 12C21.25 6.89137 17.1086 2.75 12 2.75ZM1.25 12C1.25 6.06294 6.06294 1.25 12 1.25C17.9371 1.25 22.75 6.06294 22.75 12C22.75 17.9371 17.9371 22.75 12 22.75C10.2817 22.75 8.65552 22.3463 7.21315 21.6279C6.99791 21.5207 6.77814 21.4979 6.59324 21.5474L4.3672 22.143C2.84337 22.5507 1.44927 21.1566 1.857 19.6328L2.4526 17.4068C2.50208 17.2219 2.47933 17.0021 2.37213 16.7869C1.65371 15.3445 1.25 13.7183 1.25 12ZM7.25 10.5C7.25 10.0858 7.58579 9.75 8 9.75H16C16.4142 9.75 16.75 10.0858 16.75 10.5C16.75 10.9142 16.4142 11.25 16 11.25H8C7.58579 11.25 7.25 10.9142 7.25 10.5ZM7.25 14C7.25 13.5858 7.58579 13.25 8 13.25H13.5C13.9142 13.25 14.25 13.5858 14.25 14C14.25 14.4142 13.9142 14.75 13.5 14.75H8C7.58579 14.75 7.25 14.4142 7.25 14Z" fill="#1C274C" />
                                                            </svg>
                                                          </div>
                                                        </div>
                                                      </li>
                                                    </>
                                                  );
                                                }
                                                return null; // Ensure to return null for unmatched conditions
                                              })
                                            )}
                                          </ul>
                                        </li>
                                      </>
                                    ))
                                  }
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>

                      </div>
                    </>}


                </div>
                <div className="w-full p-1">
                  <div className="p-4 bg-white border-green-300 rounded-lg border shadow-md dark:bg-gray-800 dark:border-gray-700">

                    {/* Header */}
                    <div className="flex flex-col sm:flex-row items-center mb-3">
                      <div className="ml-2 text-xl text-center font-sans">Pedidos</div>
                      {/* Filtros */}
                      <div className="w-full sm:w-auto sm:ml-4 flex flex-col sm:flex-row gap-2">
                        <select
                          value={filterStatus}
                          onChange={(e) => setFilterStatus(e.target.value)}
                          className="w-full sm:w-auto p-2 border rounded-lg bg-white text-gray-700 dark:bg-gray-900 dark:text-white"
                        >
                          <option value="">Todos os Status</option>
                          <option value="pendente">Aguardando Confirmação</option>
                          <option value="em_preparacao">Em Preparação</option>
                          <option value="saiu_para_entrega">Saiu para Entrega</option>
                          <option value="entregue">Entregue</option>
                        </select>

                        <input
                          type="date"
                          value={filterDate}
                          onChange={(e) => setFilterDate(e.target.value)}
                          className="w-full sm:w-auto p-2 border rounded-lg bg-white text-gray-700 dark:bg-gray-900 dark:text-white"
                        />

                        <button
                          onClick={() => fetchPedidos(1)}
                          className="w-full sm:w-auto px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-700 transition"
                        >
                          Filtrar
                        </button>
                      </div>
                    </div>
                    <hr className='w-full h-[2px] shadow-xl bg-green-300 m-1' />
                    {/* Loading */}
                    {loading && <p className="text-center text-gray-500">Carregando pedidos...</p>}

                    {/* Lista responsiva */}
                    {!loading && pedidos.length > 0 ? (
                      <>
                        {/* Desktop */}
                        <div className="hidden lg:block overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-300">
                            <thead className="bg-gray-100 sticky top-0">
                              <tr>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Nº</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Cliente</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Endereço</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Produtos</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Pagamento</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Produtos</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Frete</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Total</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Valor Troco</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Status</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Data/Hora</th>
                                <th className="whitespace-nowrap py-2 px-3 text-center text-sm font-semibold text-gray-900">Ações</th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {!loading && pedidos.map((p: any) => {
                                const cliente = perfisClientes[p.telefoneCliente]?.data?.response; // Busca o cliente no estado

                                return (
                                  <tr key={p.id}>
                                    <td className="px-3 py-2 text-gray-900 font-bold">#{p.id}</td>
                                    <td className="w-auto px-3 py-2 flex items-center gap-2 align-middle">
                                      <div className='flex mt-2'>
                                        <img src={cliente?.imgUrl || "./Anoni.jpg"} alt="" className="w-10 h-10 rounded-full mt-2" />
                                        <small className="w-36 mt-2 block ml-2">
                                          <div className="text-gray-900">{cliente?.pushname}</div>
                                          <div className="text-gray-500">{p.telefoneCliente}</div>
                                        </small>
                                      </div>
                                    </td>
                                    <td className="w-96 px-3 py-2 text-gray-500 align-top">
                                      <small className="w-96 text-gray-700 mt-2 text-xs">
                                        <div className="text-sm whitespace-nowrap w-72">
                                          {p.enderecoCliente ? "Entrega" : "Retirada na Loja"}
                                        </div>
                                        {p.enderecoCliente ?? p.enderecoLoja}
                                      </small>
                                    </td>

                                    <td className="w-64 px-3 py-2 text-gray-500">
                                      {p.produtos !== undefined && p.produtos?.length > 0 && p.produtos.map((produto: any) => (
                                        <div key={produto.nome}>
                                          <small className="whitespace-nowrap text-gray-700 mt-2 text-xs ">
                                            {produto.quantidade}x {produto.nome} - {formatCurrency(produto.preco * produto.quantidade, "BRL")}
                                          </small>
                                        </div>
                                      ))}
                                    </td>
                                    <td className="w-48 whitespace-nowrap px-3 py-2 text-gray-500">
                                      <small className="whitespace-nowrap text-gray-700 mt-2 text-xs text-center">
                                        {p.metodoPagamento === 'cartao' ? "Crédito/Débito" : p.metodoPagamento === 'dinheiro' ? "Dinheiro" : p.metodoPagamento}
                                      </small>
                                    </td>
                                    <td className="px-3 whitespace-nowrap py-2 text-gray-500">
                                      <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
                                        {formatCurrency(p.valorProdutos, "BRL")}
                                      </span>
                                    </td>
                                    <td className="px-3 whitespace-nowrap py-2 text-gray-500">
                                      <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
                                        {formatCurrency(p.valorFrete, "BRL")}
                                      </span>
                                    </td>
                                    <td className="px-3 whitespace-nowrap py-2 text-gray-500">
                                      <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
                                        {formatCurrency(p.valorTotal, "BRL")}
                                      </span>
                                    </td>
                                    <td className="px-3 whitespace-nowrap py-2 text-gray-500">
                                      <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
                                        {formatCurrency(p.troco, "BRL")}
                                      </span>
                                    </td>
                                    <td className="px-3 py-2 text-gray-500">
                                      <small className="block ml-2 text-center justify-center">
                                        <span className={`whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md ${p.status === "pendente" ? "text-yellow-700 bg-yellow-50" : p.status === "cancelado" ? "text-red-700 bg-red-50" : p.status === "entregue" ? "text-green-700 bg-green-50" : p.status === "em_preparacao" ? "text-yellow-700 bg-yellow-50" : p.status === "saiu_para_entrega" ? "text-yellow-700 bg-yellow-50" : "text-red-700 bg-red-50"}`}>
                                          {p.status === "pendente" ? "Aguardando Confirmação" : p.status === "entregue" ? "Entregue" : p.status === "em_preparacao" ? "Em Preparação" : p.status === "saiu_para_entrega" ? "Saiu para Entrega" : "Cancelado"}
                                        </span>
                                        <div className="text-gray-500 mt-1 w-auto font-small text-xs">{p.status === "cancelado" && p.motivoCancelamento !== undefined ? <> {p.motivoCancelamento} </> : <></>}</div>
                                      </small>
                                    </td>
                                    <td className="whitespace-nowrap px-3 py-2 text-gray-500">{new Date(p.created_at).toLocaleString("pt-BR")}</td>
                                    <td className="w-36 px-3 py-2 text-right">
                                      <span className='whitespace-nowrap px-2 py-1 text-xs font-medium rounded-md'>
                                        {p.status === "pendente" &&
                                          <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                            <DialogTrigger>
                                              <Button className="rounded-md transition">
                                                <CheckCircleIcon className="w-6 h-6 text-green-300" />
                                              </Button>
                                              <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                                <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                                  <form>
                                                    <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Aceitar Pedido</Heading>
                                                    <p className="text-gray-600 mt-4">Você tem certeza que deseja aceitar este pedido?</p>
                                                    <div className="mt-8 flex justify-end space-x-3">
                                                      <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                        Cancelar
                                                      </Button>
                                                      <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handleAceitarPedido(p)}>
                                                        Confirmar
                                                      </Button>
                                                    </div>
                                                  </form>
                                                </Dialog>
                                              </Modal>
                                            </DialogTrigger>
                                          </div>
                                        }

                                        {p.status === "saiu_para_entrega" &&
                                          <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                            <DialogTrigger>
                                              <Button className="rounded-md transition">
                                                <BanknotesIcon className="w-6 h-6 text-green-300" />
                                              </Button>
                                              <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                                <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                                  <form>
                                                    <Heading slot="title" className="leading-none mt-0 text-xl font-bold">O pedido foi entregue?</Heading>
                                                    <p className="text-gray-600 mt-4">Você tem certeza que deseja finalizar este pedido?</p>
                                                    <div className="mt-8 flex justify-end space-x-3">
                                                      <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                        Cancelar
                                                      </Button>
                                                      <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handleFinalizarPedido(p)}>
                                                        Confirmar
                                                      </Button>
                                                    </div>
                                                  </form>
                                                </Dialog>
                                              </Modal>
                                            </DialogTrigger>
                                          </div>
                                        }

                                        {p.status === "em_preparacao" &&
                                          <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                            <DialogTrigger>
                                              <Button className="rounded-md transition">
                                                <RocketLaunchIcon className="w-6 h-6 text-gray-700" />
                                              </Button>
                                              <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                                <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                                  <form>
                                                    <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Saindo Para Entrega?</Heading>
                                                    <p className="text-gray-600 mt-4">Você tem certeza que deseja alterar o status para "Saiu para Entrega" neste pedido?</p>
                                                    <div className="mt-8 flex justify-end space-x-3">
                                                      <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                        Cancelar
                                                      </Button>
                                                      <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handlePedidoSaiuParaEntrega(p)}>
                                                        Confirmar
                                                      </Button>
                                                    </div>
                                                  </form>
                                                </Dialog>
                                              </Modal>
                                            </DialogTrigger>
                                          </div>
                                        }

                                        <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer"
                                          onClick={() => {
                                            handleSelectNumber(cliente);
                                          }}
                                        >
                                          <ChatBubbleBottomCenterTextIcon className="w-6 h-6 text-gray-500" />
                                        </div>
                                        {(p.status === "pendente" || p.status === "saiu_para_entrega") &&
                                          <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                            {/* Fluxo para Cancelar Pedido */}
                                            <DialogTrigger>
                                              <Button className="rounded-md transition">
                                                <XCircleIcon className="w-6 h-6 text-red-300" />
                                              </Button>
                                              <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                                                <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                                  <form>
                                                    <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Cancelar Pedido</Heading>
                                                    <p className="text-gray-600 mt-4">Tem certeza que deseja cancelar este pedido? Essa ação não pode ser desfeita.</p>
                                                    <div>
                                                      <label htmlFor="marca" className="block text-md font-semibold text-gray-700 mb-1">
                                                        Motivo Cancelamento:
                                                      </label>
                                                      <input
                                                        id="marca"
                                                        type="text"
                                                        name="marca"
                                                        value={motivoCancelamento}
                                                        onChange={(e: any) => setMotivoCancelamento(e.target.value)}
                                                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                                      />
                                                    </div>
                                                    {/* Botões de Ação */}
                                                    <div className="mt-8 flex justify-end space-x-3">
                                                      <Button className="px-4 py-2 bg-gray-300 text-red-700 rounded-md hover:bg-gray-400 transition" slot="close">
                                                        Cancelar
                                                      </Button>
                                                      <Button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition" onPress={() => { { motivoCancelamento !== '' && handleCancelarPedido(p) } }} >
                                                        Confirmar Cancelamento
                                                      </Button>
                                                    </div>
                                                  </form>
                                                </Dialog>
                                              </Modal>
                                            </DialogTrigger>
                                          </div>}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                          <div className="flex items-center justify-center space-x-4 mt-4 mb-4">
                            <button
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                            >
                              Anterior
                            </button>

                            <span>
                              Página {currentPage} de {totalPages}
                            </span>

                            <button
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                            >
                              Próxima
                            </button>
                          </div>
                        </div>

                        {/* Mobile - Exibe os pedidos como cards */}
                        <div className="lg:hidden flex flex-col gap-4">
                          {pedidos.map((p: any) => {
                            const cliente = perfisClientes[p.telefoneCliente]?.data?.response;
                            return (
                              <div key={p.id} className="bg-gray-50 p-4 rounded-lg shadow-md">
                                <div className="flex justify-between items-center">
                                  <h2 className="text-lg font-semibold text-gray-900">Pedido #{p.id}</h2>
                                  <span className={`px-2 py-1 text-xs font-medium rounded-md ${p.status === "pendente" ? "text-yellow-700 bg-yellow-50" : p.status === "cancelado" ? "text-red-700 bg-red-50" : p.status === "entregue" ? "text-green-700 bg-green-50" : p.status === "em_preparacao" ? "text-yellow-700 bg-yellow-50" : p.status === "saiu_para_entrega" ? "text-yellow-700 bg-yellow-50" : "text-red-700 bg-red-50"}`}>
                                    {p.status === "pendente" ? "Aguardando Confirmação" : p.status === "entregue" ? "Entregue" : p.status === "em_preparacao" ? "Em Preparação" : p.status === "saiu_para_entrega" ? "Saiu para Entrega" : "Cancelado"}
                                  </span>
                                </div>
                                <p className="text-sm text-gray-500 mt-1">{new Date(p.created_at).toLocaleString("pt-BR")}</p>
                                <div className="flex items-center gap-2 mt-2">
                                  <img src={cliente?.imgUrl || "./Anoni.jpg"} alt="" className="w-10 h-10 rounded-full" />
                                  <div>
                                    <p className="text-gray-900">{cliente?.pushname}</p>
                                    <p className="text-gray-500">{p.telefoneCliente}</p>
                                  </div>
                                </div>
                                <small className="text-gray-700 mt-2 text-xs ">
                                  <strong>Entrega:</strong> {p.enderecoCliente ?? "Retirada na Loja"}
                                </small>
                                <small className="text-gray-700 mt-2 text-xs "><br />
                                  <strong>Produtos:</strong> {p.produtos.map((produto: any) => (
                                    <small key={produto.nome} className="text-xs block">{produto.quantidade}x {produto.nome} - {formatCurrency(produto.preco * produto.quantidade, "BRL")}</small>
                                  ))}
                                </small>
                                <div className='flex justify-center'>
                                  {p.status === "pendente" &&
                                    <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                      <DialogTrigger>
                                        <Button className="rounded-md transition">
                                          <CheckCircleIcon className="w-6 h-6 text-green-300" />
                                        </Button>
                                        <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                          <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                            <form>
                                              <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Pedido Aceito ?</Heading>
                                              <p className="text-gray-600 mt-4">Você tem certeza que deseja aceitar este pedido?</p>
                                              <div className="mt-8 flex justify-end space-x-3">
                                                <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                  Cancelar
                                                </Button>
                                                <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handleAceitarPedido(p)}>
                                                  Confirmar
                                                </Button>
                                              </div>
                                            </form>
                                          </Dialog>
                                        </Modal>
                                      </DialogTrigger>
                                    </div>
                                  }

                                  {p.status === "saiu_para_entrega" &&
                                    <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                      <DialogTrigger>
                                        <Button className="rounded-md transition">
                                          <BanknotesIcon className="w-6 h-6 text-green-300" />
                                        </Button>
                                        <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                          <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                            <form>
                                              <Heading slot="title" className="leading-none mt-0 text-xl font-bold">O pedido foi entregue?</Heading>
                                              <p className="text-gray-600 mt-4">Você tem certeza que deseja finalizar este pedido?</p>
                                              <div className="mt-8 flex justify-end space-x-3">
                                                <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                  Cancelar
                                                </Button>
                                                <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handleFinalizarPedido(p)}>
                                                  Confirmar
                                                </Button>
                                              </div>
                                            </form>
                                          </Dialog>
                                        </Modal>
                                      </DialogTrigger>
                                    </div>
                                  }

                                  {p.status === "em_preparacao" &&
                                    <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                      <DialogTrigger>
                                        <Button className="rounded-md transition">
                                          <RocketLaunchIcon className="w-6 h-6 text-gray-700" />
                                        </Button>
                                        <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                                          <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                            <form>
                                              <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Pedido Saiu Para Entrega?</Heading>
                                              <p className="text-gray-600 mt-4">Você tem certeza que deseja alterar o pedido para saindo para entrega neste pedido?</p>
                                              <div className="mt-8 flex justify-end space-x-3">
                                                <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                                  Cancelar
                                                </Button>
                                                <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handlePedidoSaiuParaEntrega(p)}>
                                                  Confirmar
                                                </Button>
                                              </div>
                                            </form>
                                          </Dialog>
                                        </Modal>
                                      </DialogTrigger>
                                    </div>
                                  }

                                  <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer"
                                    onClick={() => {
                                      handleSelectNumber(cliente);
                                    }}
                                  >
                                    <ChatBubbleBottomCenterTextIcon className="w-6 h-6 text-gray-500" />
                                  </div>

                                  {(p.status === "pendente" || p.status === "saiu_para_entrega") &&
                                    <div className="inline-flex px-1 py-2 items-center text-base font-semibold cursor-pointer">
                                      {/* Fluxo para Cancelar Pedido */}
                                      <DialogTrigger>
                                        <Button className="rounded-md transition">
                                          <XCircleIcon className="w-6 h-6 text-red-300" />
                                        </Button>
                                        <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm">
                                          <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                                            <form>
                                              <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Cancelar Pedido</Heading>
                                              <p className="text-gray-600 mt-4">Tem certeza que deseja cancelar este pedido? Essa ação não pode ser desfeita.</p>
                                              <div>
                                                <label htmlFor="marca" className="block text-md font-semibold text-gray-700 mb-1">
                                                  Motivo Cancelamento:
                                                </label>
                                                <input
                                                  id="marca"
                                                  type="text"
                                                  name="marca"
                                                  value={motivoCancelamento}
                                                  onChange={(e: any) => setMotivoCancelamento(e.target.value)}
                                                  className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                                                />
                                              </div>

                                              {/* Botões de Ação */}
                                              <div className="mt-8 flex justify-end space-x-3">
                                                <Button className="px-4 py-2 bg-gray-300 text-red-700 rounded-md hover:bg-gray-400 transition" slot="close">
                                                  Cancelar
                                                </Button>
                                                <Button className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600 transition" onPress={() => handleCancelarPedido(p)} >
                                                  Confirmar Cancelamento
                                                </Button>
                                              </div>
                                            </form>
                                          </Dialog>
                                        </Modal>
                                      </DialogTrigger>
                                    </div>}
                                </div>
                              </div>
                            );
                          })}
                          <div className="flex items-center justify-center space-x-4 mt-4">
                            <button
                              onClick={handlePrevPage}
                              disabled={currentPage === 1}
                              className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                            >
                              Anterior
                            </button>

                            <span>
                              Página {currentPage} de {totalPages}
                            </span>

                            <button
                              onClick={handleNextPage}
                              disabled={currentPage === totalPages}
                              className="px-3 py-2 bg-gray-200 text-gray-700 rounded disabled:opacity-50"
                            >
                              Próxima
                            </button>
                          </div>
                        </div>
                      </>
                    ) : (
                      !loading && <p className="text-center text-gray-500">Nenhum pedido encontrado.</p>
                    )}

                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>

      </div>

      <>
        <ChatComponent
          showChat={showChat}
          setShowChat={setShowChat}
          SelectedContactImage={SelectedContactImage}
          SelectedContactName={SelectedContactName}
          SelectedContact={SelectedContact + ""}
          Conversas={Conversas}
          onEmojiSelectResponse={onEmojiSelectResponse}
          mensagemResponseOriginal={mensagemResponseOriginal}
          mensagemResponse={mensagemResponse}
          handleKeyDown={handleKeyDown}
          handleChange={handleChange}
          wrapText={wrapText}
          formatLastMessageTime={formatLastMessageTime}
          renderConversation={renderConversation}
        />
      </>
    </>
  );

}

export default Home;