import React, { useState, useRef, useEffect } from "react";
import { MapContainer, TileLayer, Marker, Circle, useMapEvents, useMap } from "react-leaflet";
import L from "leaflet";
import StoreApi from "../../services/StoreApi";
import { FaCrosshairs } from 'react-icons/fa6';

interface SearchInputProps {
  onCodUserInstance: number;
  label: string;
  inputValue: string;
  numeroInput: string | number;
  complementoInput: string;
  setInputValue: (value: string) => void;
  suggestions: string[];
  isAddressSelected : boolean;
  onSelectSuggestion: (suggestion: string) => void;
  onNumeroChange: (numero: string) => void;
  onComplementoChange: (complemento: string) => void;
  onSearchClick: () => void;
  setMapDisabled: (disabled: boolean) => void;
}
const SearchInput: React.FC<SearchInputProps> = ({
  onCodUserInstance,
  label,
  numeroInput,
  complementoInput,
  inputValue,
  setInputValue,
  suggestions,
  isAddressSelected,
  onSelectSuggestion,
  setMapDisabled,
  onNumeroChange,
  onComplementoChange,
}) => {
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [numero, setNumero] = useState<string | number>(numeroInput ?? "");
  const [complemento, setComplemento] = useState(complementoInput ?? "");
  const [codUserInstance, setCodUserInstance] = useState<number>(onCodUserInstance);

  useEffect(() => {
    if (inputValue) {
      setIsSuggestionsVisible(true);
      setMapDisabled(true);
    } else {
      setIsSuggestionsVisible(false);
      setMapDisabled(false);
    }
  }, [inputValue]);

  const handleInputChange = (e: any) => {
    setIsSuggestionsVisible(false);
    setInputValue(e.target.value);
    setHighlightedIndex(-1);
  };

  const handleSuggestionClick = (suggestion: string) => {
    setInputValue(suggestion);
    onSelectSuggestion(suggestion);
    setIsSuggestionsVisible(false);
    setMapDisabled(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : prevIndex
      );
    } else if (e.key === 'ArrowUp') {
      setHighlightedIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : prevIndex
      );
    } else if (e.key === 'Enter' && highlightedIndex >= 0) {
      e.preventDefault();
      handleSuggestionClick(suggestions[highlightedIndex]);
    } else if (e.key === 'Escape') {
      setMapDisabled(false);
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      setIsSuggestionsVisible(false);
      setMapDisabled(false);
    }
  };

  useEffect(() => {

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleNumeroChange = (e: any) => {
    setNumero(e.target.value);
    onNumeroChange(e.target.value)
  };

  const handleComplementoChange = (e: any) => {
    setComplemento(e.target.value);
    onComplementoChange(e.target.value)
  };

  return (
    <div className="mb-2 relative w-full" ref={wrapperRef} >
      <div className="flex space-x-4 w-full">
        <div className="flex-1">
          <label className="block text-md font-semibold text-gray-700 mb-1">{label}:</label>
          <input
            ref={inputRef}
            type="text"
            value={inputValue}
            onChange={(e) => { handleInputChange(e); }}
            onKeyDown={(e) => { handleKeyDown(e); }}
            placeholder={`Digite o endereço ${label.toLowerCase()}`}
            className={`w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${isAddressSelected === false && 'border-red-500 focus:ring-red-500'}`}
          />
          {isSuggestionsVisible && suggestions.length > 0 && (
            <ul className="border border-gray-300 rounded bg-white max-h-40 overflow-auto w-full mt-1 absolute">
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => { handleSuggestionClick(suggestion); setIsSuggestionsVisible(false); }}
                  className={`p-2 cursor-pointer hover:bg-gray-200 transition-colors duration-200 ${highlightedIndex === index ? 'bg-gray-200' : ''}`}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="flex flex-col w-1/4">
          <label className="block text-md font-semibold text-gray-700 mb-1">Número:</label>
          <input
            id="numero"
            type="number"
            value={numero}
            onChange={handleNumeroChange}
            className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
          />
        </div>
      </div>
      <div className="flex flex-col">
        <label className="block text-md font-semibold text-gray-700 mb-1">Complemento:</label>
        <input
          type="text"
          value={complemento}
          onChange={handleComplementoChange}
          placeholder="Digite o complemento"
          className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
        />
      </div>
    </div>
  );
};

const FlyToLocation: React.FC<{ coordinates: [number, number] }> = ({ coordinates }) => {
  const map = useMap();
  useEffect(() => {
    if (coordinates) {
      map.flyTo(coordinates, 13, { duration: 1.5 });
    }
  }, [coordinates, map]);
  return null;
};

interface GpsAddressPickerProps {
  onCodUserInstance: number;
  initialAddress: string;
  initialNumero: string | number;
  initialComplemento: string;
  onAddressChange: (address: string) => void;
  onNumeroChange: (numero: string | number) => void;
  onComplementoChange: (complemento: string) => void;
  onCoordinatesChange: (coordinates: [number, number]) => void;
  onValidationChange: (isValid: boolean) => void;
  radius: number;
  isStore?: boolean;
}

const GpsAddressPicker: React.FC<GpsAddressPickerProps> = ({ onCodUserInstance, initialAddress, initialNumero, initialComplemento, onAddressChange, onNumeroChange, onComplementoChange, onCoordinatesChange, onValidationChange, radius, isStore }) => {
  const [coordinates, setCoordinates] = useState<[number, number]>([0, 0]);
  const [numero, setNumero] = useState<string | number>(initialNumero);
  const [complemento, setComplemento] = useState<string>(initialComplemento);
  const [codUserInstance, setCodUserInstance] = useState<number>(onCodUserInstance);
  const [inputValue, setInputValue] = useState<string>(initialAddress);
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [countryCode, setCountryCode] = useState<string | null>(null);
  const [mapDisabled, setMapDisabled] = useState<boolean>(false);
  const debounceTimeout = useRef<NodeJS.Timeout | null>(null);
  const [isAddressSelected, setIsAddressSelected] = useState(false);

  const icon = new L.Icon({
    iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
    iconSize: [25, 41],
    iconAnchor: [12, 41],
  });

  const formatAddressFromFields = (address: any): string => {
    const { road, suburb, city, state, postcode, country } = address;


    return [
      road,
      suburb,
      city,
      state,
      postcode,
      country
    ]
      .filter(Boolean)
      .join(", ");
  };

  useEffect(() => {
    onAddressChange(inputValue);
  }, [inputValue]);

  useEffect(() => {

    const fetchInitialData = async () => {
      try {
        const response = await StoreApi.getStoreEmpresa(codUserInstance);

        const savedLatitude = response.data.latitude;
        const savedLongitude = response.data.longitude;

        if (savedLatitude && savedLongitude) {
          setCoordinates([Number(savedLatitude), Number(savedLongitude)]);
        }
        else if (initialAddress) {
          await fetchCoordinatesByAddress(initialAddress);
        }

      } catch (err) {
        console.error("Erro ao buscar dados do store", err);
        setError("Não foi possível buscar os dados iniciais da loja.");
      }
    };

    console.log(codUserInstance)
    const fetchCountryCode = async () => {
      try {
        const response = await fetch("https://ipapi.co/json/");
        if (!response.ok) throw new Error("Erro ao buscar informações de localização");
        const data = await response.json();
        setCountryCode(data.country_code);
      } catch {
        setError("Não foi possível determinar o país do usuário.");
      }
    };

    fetchInitialData();
    fetchCountryCode();
  }, []);

  useEffect(() => {
    setNumero(numero);
    onNumeroChange(numero);
  }, [numero]);

  useEffect(() => {
    onValidationChange(isAddressSelected); // <- Informa ao componente pai se o endereço é válido
  }, [isAddressSelected]);

  useEffect(() => {
    onComplementoChange(complemento);
  }, [complemento]);

  useEffect(() => {
    onCoordinatesChange(coordinates);
  }, [coordinates]);

  const handleInputValueChange = (query: string) => {
    setIsAddressSelected(false);
    if (debounceTimeout.current) {
      clearTimeout(debounceTimeout.current);
    }
    setError(null);
    setInputValue(query);
    setSuggestions([]);
    debounceTimeout.current = setTimeout(() => {
      fetchAddressSuggestions(query);
    }, 500);
  };

  const handleSearchClick = () => {
    if (!isAddressSelected) {
      setError("Por favor, selecione um endereço no mapa.");
      onValidationChange(false);
      return;
    }

    fetchCoordinatesByAddress(inputValue);
  };

  const fetchAddressSuggestions = async (query: string) => {
    if (!query) {
      setSuggestions([]);
      return;
    }

    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${query}&addressdetails=1${countryCode ? `&countrycodes=${countryCode}` : ""}`
      );
      if (!response.ok) throw new Error("Erro ao buscar sugestões");
      const data = await response.json();
      const results = data.map((item: any) => formatAddressFromFields(item.address));
      setSuggestions(results);
      return;
    } catch {
      setError("Erro ao buscar sugestões de endereço.");
    }
  };

  const fetchCoordinatesByAddress = async (address: string) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/search?format=json&q=${address}&limit=1`
      );
      if (!response.ok) throw new Error("Erro ao buscar coordenadas");
      setSuggestions([]);
      const [result] = await response.json();
      if (result) {

        const { lat, lon } = result;
        const newCoordinates: [number, number] = [
          parseFloat(lat),
          parseFloat(lon),
        ];
        setCoordinates(newCoordinates);
        setIsAddressSelected(true);
        onValidationChange(true);
      }
    } catch {
      setError("Erro ao buscar coordenadas para o endereço.");
    }
  };

  const MapClickHandler = () => {
    useMapEvents({
      click: (e) => {
        // Pega o HTML element alvo do clique
        const target = e.originalEvent.target as HTMLElement;

        // Se o clique ocorreu em (ou dentro de) qualquer elemento
        // que tenha a classe .btn-mapa-center, não faça nada
        if (target.closest(".btn-mapa-center")) {
          return; // ignora o clique
        }
        if (!mapDisabled) {
          const { lat, lng } = e.latlng;
          setCoordinates([lat, lng]);
          fetchAddressByCoordinates(lat, lng);
          setSuggestions([]);
          onCoordinatesChange([lat, lng])
          setIsAddressSelected(true);
          onValidationChange(true);
        }
      },
    });
    return null;
  };

  const fetchAddressByCoordinates = async (lat: number, lon: number) => {
    try {
      const response = await fetch(
        `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lon}`
      );
      if (!response.ok) throw new Error("Erro ao buscar endereço a partir das coordenadas");
      const data = await response.json();
      if (data && data.display_name) {
        setInputValue(formatAddressFromFields(data.address));
      }
    } catch {
      setError("Erro ao buscar endereço a partir das coordenadas.");
    }
  };
  return (
    <div className="w-full max-w-3x3">
      <div className="w-full h-72">
        <MapContainer
          center={coordinates}
          zoom={13}
          scrollWheelZoom={true}
          style={{ height: "100%", width: "100%", zIndex: 0 }}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {coordinates[0] !== 0 && coordinates[1] !== 0 && <Marker position={coordinates} icon={icon} />}
          {/* Adicionando o círculo para mostrar o raio de cobertura */}
          {coordinates[0] !== 0 && coordinates[1] !== 0 && <Circle
            center={coordinates}
            radius={radius * 1000} // Converter para metros (por exemplo, 5 km será 5000 metros)
            color="blue"
            fillColor="rgba(0, 0, 255, 0.2)"
            fillOpacity={0.4}
          />}
          <MapClickHandler />
          <FlyToLocation coordinates={coordinates} />
          <ReturnButton coordinates={coordinates} />
        </MapContainer>
      </div>
      {error && <p className="text-red-500 mt-1 mb-2">{error}</p>}
      <SearchInput
        onCodUserInstance={codUserInstance}
        label="Endereço"
        inputValue={inputValue}
        setInputValue={handleInputValueChange}
        suggestions={suggestions}
        numeroInput={numero}
        complementoInput={complemento}
        onNumeroChange={(numero) => setNumero(numero)}
        onComplementoChange={(complemento) => setComplemento(complemento)}
        isAddressSelected={isAddressSelected}
        onSelectSuggestion={(suggestion) => {
          setInputValue(suggestion);
          fetchCoordinatesByAddress(suggestion);
          setSuggestions([]);
        }}
        onSearchClick={handleSearchClick}
        setMapDisabled={setMapDisabled}
      />
    </div>
  );
};

interface ReturnButtonProps {
  coordinates: [number, number];
}

export const ReturnButton: React.FC<ReturnButtonProps> = ({ coordinates }) => {
  const map = useMap();
  return (
    <div
      className="leaflet-bottom leaflet-right btn-mapa-center mb-6 mr-2"
      style={{
        pointerEvents: "none", // faz com que a div não capture cliques
      }}
    >
      <button
        onClick={() => {
          map.flyTo(coordinates, 13, { duration: 1 });
        }}
        aria-disabled="false"
        style={{
          pointerEvents: "auto", // o botão volta a capturar os cliques
          height: "40px",
          width: "40px",
          borderRadius: "50%",
          backgroundColor: "#fff",
          border: "1px solid #ccc",
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <FaCrosshairs size={18} color="#333" />
      </button>
    </div>
  );
};

export default GpsAddressPicker;
