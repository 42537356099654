import api from "./Api";
import { Subject } from 'rxjs';
import Cookies from 'universal-cookie';
import moment from 'moment';
import UserService from './UserApi';
import { finished } from "stream";

const subject = new Subject();
const cookies = new Cookies();
//voltou ao normal
const login = (telefone: string, password: string) => {
    return api.post("/login", {
        telefone,
        password,
    }).then((response: any) => {
        if (response.data.token) {
            cookies.set('user', JSON.stringify(response.data.user), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('clientes', JSON.stringify(response.data.clientes), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('instancias', JSON.stringify(response.data.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('atendentes', JSON.stringify(response.data.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
            cookies.set('token', JSON.stringify(response.data.token), { path: '/', expires: moment().add(1, 'day').toDate() });
            observable.setToken(response.data.token)
        }
        else {
            console.log(response);
        }
        return response.data;
    });
};

const logout = () => {


    api.post("/logout", {
    }).then((response: any) => {
        console.log(response);

        cookies.remove('user');
        cookies.remove('token');
        cookies.remove('imgUser');
        cookies.remove('clientes');
        cookies.remove('atendentes');
        cookies.remove('instancias');
        observable.clearToken()
        return true;
    });

};

const getToken = () => {
    let user = cookies.get('token');
    if (user !== undefined) {
        return user;
    }
    return null;
};

const register = async (nome: string, dataNascimento: string, telefone: string, password: string, pais: string, cpf: any, cnpj: any): Promise<any> => {

    const response = await api.post("/register", { nome, dataNascimento, telefone, password, pais, cpf, cnpj });

    if (!response.data.error) {
        cookies.set('user', JSON.stringify(response.data.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
        cookies.set('token', JSON.stringify(response.data.original.token), { path: '/', expires: moment().add(1, 'day').toDate() });
        observable.setToken(response.data.original.token)

        return response.data.original;
    }
    else {
        return response;
    }


};

const deleteAtendente = async (id: any): Promise<any> => {

    const response = await api.delete('/user/'+id);

    return response;
    
};

const registerAtendente = async (nome: string, dataNascimento: string, telefone: string, email: string, pais: string, cpf: any, cnpj: any, tipoUsuario: any): Promise<any> => {

    if (cpf !== undefined) {
        const response = await api.post("/register/atendente", { nome, dataNascimento, telefone, email, pais, cpf, cnpj, tipoUsuario });
        console.log(response.data);
        cookies.set('atendentes', JSON.stringify(response.data['AllUsers']), { path: '/', expires: moment().add(1, 'day').toDate() });
        return response.data;
    }
    else {
        const response = await api.post("/register/atendente", { nome, dataNascimento, email, telefone, pais, cnpj, tipoUsuario });
        console.log(response.data);
        cookies.set('atendentes', JSON.stringify(response.data['AllUsers']), { path: '/', expires: moment().add(1, 'day').toDate() });
        return response.data;
    }

};



const callApiUrl = (url: string, body: any) => {
    return api.get(url, { params: body }).then((response: any) => {
        return response;
    });
};


const observable = {
    setToken: (token: any) => subject.next(token),
    clearToken: () => subject.next(null),
    onToken: () => subject.asObservable()
};




export default {
    login,
    logout,
    getToken,
    callApiUrl,
    register,
    registerAtendente,
    deleteAtendente,
    observable

};
