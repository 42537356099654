import React, { useState } from 'react';
import StoreApi from '../../../services/StoreApi';

export const ProductForm = (data : any) => {
    const [formData, setFormData] = useState({
        codigoDeBarras: data.data.store_estoque.codigoDeBarras,
        tipoDeProduto: data.data.store_estoque.tipoDeProduto,
        precoDeCusto: data.data.store_estoque.precoDeCusto,
        precoVendaVarejo: data.data.store_estoque.precoVendaVarejo,
        precoVendaAtacado: data.data.store_estoque.precoVendaAtacado,
        quantidadeMinimaAtacado: data.data.store_estoque.quantidadeMinimaAtacado,
        unidade: data.data.store_estoque.unidade,
        movimentaEstoque: data.data.store_estoque.movimentaEstoque,
        estoqueMinimo: data.data.store_estoque.estoqueMinimo,
        quantidadeEmEstoque: data.data.store_estoque.quantidadeEmEstoque,
        marca: data.data.store_estoque.marca,
        modelo: data.data.store_estoque.modelo,
        codigoBalanca: data.data.store_estoque.codigoBalanca,
        ncm: data.data.store_estoque.ncm,
        origem: data.data.store_estoque.origem,
        cest: data.data.store_estoque.cest,
        alturaCm: data.data.store_estoque.alturaCm,
        larguraCm: data.data.store_estoque.larguraCm,
        profundidadeCm: data.data.store_estoque.profundidadeCm,
        pesoKg: data.data.store_estoque.pesoKg,
        garantia: data.data.store_estoque.garantia,
        itensInclusos: data.data.store_estoque.itensInclusos,
        especificacoes: data.data.store_estoque.especificacoes,
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? (e.target as HTMLInputElement).checked : value,
        });

        if(type === "checkbox")
        {
            if(formData.movimentaEstoque === 0)
            {
                setFormData({...formData, [name]: type === 'checkbox' ? 1 : value});
            }else{
                setFormData({...formData, [name]: type === 'checkbox' ? 0 : value});
            }
        }

    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        console.log(data)
        console.log(formData);

        StoreApi.updateStoreEstoqueProduto(data.data.store_estoque.codProduto, formData).then((r : any) => {
            window.location.reload();
        })
        // Lógica para enviar os dados para o backend
    };

    return (
        <div className="w-full px-2 overflow-auto md:overflow-hidden h-[calc(100vh-50px)] md:h-auto">
            <section id="editor1" className="p-2 bg-gray-100 rounded-lg grid grid-cols-1 gap-2 md:grid-cols-4 lg:grid-cols-4">

                {/* Código de Barras */}
                <div>
                    <label htmlFor="codigoDeBarras" className="block text-md font-semibold text-gray-700 mb-1">
                        Código de Barras:
                    </label>
                    <input
                        id="codigoDeBarras"
                        type="text"
                        name="codigoDeBarras"
                        value={formData.codigoDeBarras}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Preço de Custo */}
                <div>
                    <label htmlFor="precoDeCusto" className="block text-md font-semibold text-gray-700 mb-1">
                        Preço de Custo:
                    </label>
                    <input
                        id="precoDeCusto"
                        type="number"
                        step="0.01"
                        name="precoDeCusto"
                        value={formData.precoDeCusto}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Preço de Venda no Varejo */}
                <div>
                    <label htmlFor="precoVendaVarejo" className="block text-md font-semibold text-gray-700 mb-1">
                        Preço de Venda no Varejo:
                    </label>
                    <input
                        id="precoVendaVarejo"
                        type="number"
                        step="0.01"
                        name="precoVendaVarejo"
                        value={formData.precoVendaVarejo}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Preço de Venda no Atacado */}
                <div>
                    <label htmlFor="precoVendaAtacado" className="block text-md font-semibold text-gray-700 mb-1">
                        Preço de Venda no Atacado:
                    </label>
                    <input
                        id="precoVendaAtacado"
                        type="number"
                        step="0.01"
                        name="precoVendaAtacado"
                        value={formData.precoVendaAtacado}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Quantidade Mínima para Atacado */}
                <div>
                    <label htmlFor="quantidadeMinimaAtacado" className="block text-md font-semibold text-gray-700 mb-1">
                        Quantidade Mínima Atacado:
                    </label>
                    <input
                        id="quantidadeMinimaAtacado"
                        type="number"
                        name="quantidadeMinimaAtacado"
                        value={formData.quantidadeMinimaAtacado}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Estoque Mínimo */}
                <div>
                    <label htmlFor="estoqueMinimo" className="block text-md font-semibold text-gray-700 mb-1">
                        Estoque Mínimo:
                    </label>
                    <input
                        id="estoqueMinimo"
                        type="number"
                        name="estoqueMinimo"
                        value={formData.estoqueMinimo}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Quantidade em Estoque */}
                <div>
                    <label htmlFor="quantidadeEmEstoque" className="block text-md font-semibold text-gray-700 mb-1">
                        Quantidade em Estoque:
                    </label>
                    <input
                        id="quantidadeEmEstoque"
                        type="number"
                        name="quantidadeEmEstoque"
                        value={formData.quantidadeEmEstoque}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>



                {/* Tipo de Produto */}
                <div>
                    <label htmlFor="tipoDeProduto" className="block text-md font-semibold text-gray-700 mb-1">
                        Tipo de Produto:
                    </label>
                    <input
                        id="tipoDeProduto"
                        type="text"
                        name="tipoDeProduto"
                        value={formData.tipoDeProduto}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Unidade */}
                <div>
                    <label htmlFor="unidade" className="block text-md font-semibold text-gray-700 mb-1">
                        Unidade:
                    </label>
                    <input
                        id="unidade"
                        type="text"
                        name="unidade"
                        value={formData.unidade}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Marca */}
                <div>
                    <label htmlFor="marca" className="block text-md font-semibold text-gray-700 mb-1">
                        Marca:
                    </label>
                    <input
                        id="marca"
                        type="text"
                        name="marca"
                        value={formData.marca}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Modelo */}
                <div>
                    <label htmlFor="modelo" className="block text-md font-semibold text-gray-700 mb-1">
                        Modelo:
                    </label>
                    <input
                        id="modelo"
                        type="text"
                        name="modelo"
                        value={formData.modelo}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Código da Balança */}
                <div>
                    <label htmlFor="codigoBalanca" className="block text-md font-semibold text-gray-700 mb-1">
                        Código da Balança:
                    </label>
                    <input
                        id="codigoBalanca"
                        type="text"
                        name="codigoBalanca"
                        value={formData.codigoBalanca}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* NCM */}
                <div>
                    <label htmlFor="ncm" className="block text-md font-semibold text-gray-700 mb-1">
                        NCM:
                    </label>
                    <input
                        id="ncm"
                        type="text"
                        name="ncm"
                        value={formData.ncm}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Origem */}
                <div>
                    <label htmlFor="origem" className="block text-md font-semibold text-gray-700 mb-1">
                        Origem:
                    </label>
                    <input
                        id="origem"
                        type="text"
                        name="origem"
                        value={formData.origem}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* CEST */}
                <div>
                    <label htmlFor="cest" className="block text-md font-semibold text-gray-700 mb-1">
                        CEST:
                    </label>
                    <input
                        id="cest"
                        type="text"
                        name="cest"
                        value={formData.cest}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Altura (cm) */}
                <div>
                    <label htmlFor="alturaCm" className="block text-md font-semibold text-gray-700 mb-1">
                        Altura (cm):
                    </label>
                    <input
                        id="alturaCm"
                        type="number"
                        name="alturaCm"
                        value={formData.alturaCm}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Largura (cm) */}
                <div>
                    <label htmlFor="larguraCm" className="block text-md font-semibold text-gray-700 mb-1">
                        Largura (cm):
                    </label>
                    <input
                        id="larguraCm"
                        type="number"
                        name="larguraCm"
                        value={formData.larguraCm}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Profundidade (cm) */}
                <div>
                    <label htmlFor="profundidadeCm" className="block text-md font-semibold text-gray-700 mb-1">
                        Profundidade (cm):
                    </label>
                    <input
                        id="profundidadeCm"
                        type="number"
                        name="profundidadeCm"
                        value={formData.profundidadeCm}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Peso (kg) */}
                <div>
                    <label htmlFor="pesoKg" className="block text-md font-semibold text-gray-700 mb-1">
                        Peso (kg):
                    </label>
                    <input
                        id="pesoKg"
                        type="number"
                        step="0.001"
                        name="pesoKg"
                        value={formData.pesoKg}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Garantia */}
                <div>
                    <label htmlFor="garantia" className="block text-md font-semibold text-gray-700 mb-1">
                        Garantia:
                    </label>
                    <input
                        id="garantia"
                        type="text"
                        name="garantia"
                        value={formData.garantia}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Itens Inclusos */}
                <div className="col-span-1">
                    <label htmlFor="itensInclusos" className="block text-md font-semibold text-gray-700 mb-1">
                        Itens Inclusos:
                    </label>
                    <textarea
                        id="itensInclusos"
                        name="itensInclusos"
                        value={formData.itensInclusos}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                {/* Especificações */}
                <div className="col-span-1">
                    <label htmlFor="especificacoes" className="block text-md font-semibold text-gray-700 mb-1">
                        Especificações:
                    </label>
                    <textarea
                        id="especificacoes"
                        name="especificacoes"
                        value={formData.especificacoes}
                        onChange={handleChange}
                        className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    />
                </div>

                <div>
                    {/* Movimenta Estoque */}
                    <label htmlFor="movimentaEstoque" className="block text-md font-semibold text-gray-700 mb-1">
                        Movimenta Estoque:
                    </label>
                    <div className="relative inline-flex col-6 select-none ml-1 pl-4">

                        <br />
                        <div className="relative inline-block w-16 select-none">

                            <small className='text-center whitespace-nowrap'>Não Movimentar / Movimentar</small>
                            {formData.movimentaEstoque === 1 ?
                                <>
                                    <input
                                        type="checkbox"
                                        name="movimentaEstoque"
                                        id="storeMovimentaEstoque"
                                        value={formData.movimentaEstoque}
                                        checked
                                        onChange={handleChange}
                                        className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                    />
                                    <label
                                        htmlFor="storeMovimentaEstoque"
                                        className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                    >
                                    </label>
                                </>
                                :
                                <>
                                    <input
                                        type="checkbox"
                                        name="movimentaEstoque"
                                        id="storeMovimentaEstoque"
                                        value={formData.movimentaEstoque}
                                        onChange={handleChange}
                                        className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                    />
                                    <label
                                        htmlFor="storeMovimentaEstoque"
                                        className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                    ></label>

                                </>
                            }

                        </div>
                    </div>
                </div>

                {/* Botão de Salvar */}
                <div className="md:col-span-2 lg:col-span-3 flex gap-4">
                    <button
                        onClick={handleSubmit}
                        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                    >
                        Salvar
                    </button>
                </div>
            </section>
        </div>
    );
};

export default ProductForm;