import React, { useEffect, useState } from 'react';
import haversine from 'haversine-distance';

interface FreightCalculatorProps {
  storeCoordinates: [number, number];
  clientCoordinates: [number, number] | null; // Permitir coordenadas nulas
  radius: number; // Raio de cobertura em km
  ratePerKm: number; // Taxa por km para o frete
  onFreteChange: (freightCost: { cost: number | null; message: string }) => void;
}

const FreightCalculator: React.FC<FreightCalculatorProps> = ({
  storeCoordinates,
  clientCoordinates,
  radius,
  ratePerKm,
  onFreteChange,
}) => {
  const [distance, setDistance] = useState<number | null>(null);
  const [freightCost, setFreightCost] = useState<number | null>(null);

  useEffect(() => {
    if (storeCoordinates && clientCoordinates) {
      const calculatedDistance = haversine(
        { latitude: storeCoordinates[0], longitude: storeCoordinates[1] },
        { latitude: clientCoordinates[0], longitude: clientCoordinates[1] }
      );

      const distanceInKm = calculatedDistance / 1000;
      setDistance(distanceInKm);

      if (distanceInKm <= radius) {
        const calculatedFreightCost = Math.ceil(distanceInKm * ratePerKm);
        setFreightCost(calculatedFreightCost);

        onFreteChange({
          cost: calculatedFreightCost,
          message: 'Dentro da área de cobertura',
        });
      } else {
        setFreightCost(null);

        onFreteChange({
          cost: null,
          message: 'Fora da área de cobertura de frete',
        });
      }
    } else {
      setDistance(null);
      setFreightCost(null);
      onFreteChange({
        cost: null,
        message: 'Aguardando coordenadas do cliente',
      });
    }
  }, [storeCoordinates, clientCoordinates, radius, ratePerKm]);

  return (
    <div>
      {distance !== null ? (
        <div>
          {freightCost !== null ? (
            <small>Valor do frete: R$ {freightCost.toFixed(2)}</small>
          ) : (
            <small className="text-md font-medium text-red-500">Fora da área de cobertura de frete</small>
          )}
        </div>
      ) : (
        <small>Calculando frete...</small>
      )}
    </div>
  );
};

export default FreightCalculator;
