import { ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import React, { useRef, useState } from "react";
import { motion } from "framer-motion";
import { IconUpload } from "@tabler/icons-react";
import { useDropzone } from "react-dropzone";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

const mainVariant = {
  initial: { x: 0, y: 0 },
  animate: { x: 20, y: -20, opacity: 0.9 },
};

export const FileUpload = ({ onChange }: { onChange?: (files: File[]) => void }) => {
  const [files, setFiles] = useState<File[]>([]);
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (newFiles: File[]) => {
    if (newFiles.length === 0) return;

    setFiles((prevFiles) => {
      const updatedFiles = [...prevFiles, ...newFiles].filter(
        (file, index, self) => index === self.findIndex((f) => f.name === file.name && f.size === file.size)
      );

      if (onChange) {
        onChange(updatedFiles); // Chamando `onChange` no momento certo
      }

      return updatedFiles;
    });
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    noClick: true,
    onDrop: (acceptedFiles) => {
      handleFileChange(acceptedFiles);
    },
    onDropRejected: (error) => {
      console.error("Erro ao soltar arquivo:", error);
    },
  });

  return (
    <div className="w-full" {...getRootProps()}>
      <motion.div
        onClick={handleClick}
        whileHover="animate"
        className="p-2 group/file block rounded-lg cursor-pointer w-full relative overflow-hidden"
      >
        <input
          {...getInputProps()}
          id="File-upload-handle"
          type="file"
          className="hidden"
          ref={(input) => {
            fileInputRef.current = input;
          }}
          onChange={(e) => {
            if (!e.target.files || e.target.files.length === 0) {
              console.warn("Nenhum arquivo selecionado.");
              return;
            }
            const selectedFiles = Array.from(e.target.files);
            handleFileChange(selectedFiles);
          }}
        />
        <div className="flex flex-col items-center justify-center">
          <div className="relative w-full mt-2 max-w-xl mx-auto py-4">
            {files.length === 0 &&
              files.map((file, idx) => (
                <motion.div
                  key={file.name + idx}
                  className="relative overflow-hidden z-40 bg-white dark:bg-neutral-900 flex flex-col items-start justify-start md:h-24 p-4 mt-4 w-full mx-auto rounded-md shadow-md"
                >
                  <div className="flex justify-between w-full items-center gap-4">
                    <motion.p className="text-base text-neutral-700  dark:text-neutral-300 truncate max-w-xs">
                      {file.name}
                    </motion.p>
                    <motion.p className="rounded-lg px-2 py-1 w-fit flex-shrink-0 text-sm text-neutral-600 dark:bg-neutral-800 dark:text-white shadow-md">
                      {(file.size / (1024 * 1024)).toFixed(2)} MB
                    </motion.p>
                  </div>
                </motion.div>
              ))}

            {!files.length && (
              <motion.div
                layoutId="File-upload"
                variants={mainVariant}
                transition={{ type: "spring", stiffness: 300, damping: 20 }}
                className="relative z-40 bg-white dark:bg-neutral-900 flex items-center justify-center h-16 w-full max-w-[4rem] mx-auto rounded-md shadow-md"
              >
                {isDragActive ? (
                  <motion.p className="text-neutral-600 flex flex-col items-center text-center">
                    Solte aqui
                    <IconUpload className="h-4 w-4 text-neutral-600 dark:text-neutral-400" />
                  </motion.p>
                ) : (
                  <IconUpload className="h-4 w-4 text-neutral-600 dark:text-neutral-300" />
                )}
              </motion.div>
            )}
          </div>
        </div>
      </motion.div>
    </div>
  );
};
