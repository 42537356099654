
import React, { useEffect, useState } from 'react';
import './../../../App.css';
import { useLocation } from 'react-router-dom';
import chatConfigApi from '../../../services/ChatConfigApi';
import TrashIcon from '../../../icons/TrashIcon';
import EmojiPicker from '../../../components/Utils/EmojiPicker';
import PlusIcon from '../../../icons/PlusIcon';
import Modal from '../../../components/Modal/Modal';
import { CheckCircleIcon } from '@heroicons/react/24/solid';
import Cookies from 'universal-cookie';
import moment from 'moment';
import { Store } from './Store';
import StoreApi from '../../../services/StoreApi';
import { Button, Dialog, DialogTrigger } from 'react-aria-components';
import { ChevronDownIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';

function BotConfig(...props: any) {

  const cookies = new Cookies();
  var [ShowSteps, setShowSteps] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
  const [steps, setSteps] = useState([] as any);
  var [showModalStore, setShowModalStore] = useState(0);
  var [instancia, setInstancia] = useState(0);
  var [option1, setOption1] = useState("");
  var [option2, setOption2] = useState("");
  var [option3, setOption3] = useState("");
  var [option4, setOption4] = useState("");
  var [option5, setOption5] = useState("");
  var [option6, setOption6] = useState("");
  var [option7, setOption7] = useState("");
  var [option8, setOption8] = useState("");
  var [option9, setOption9] = useState("");
  var [option10, setOption10] = useState("");
  const [isOpen, setIsOpen] = useState(true);

  var [redirecionarSequenciaCampo1, setRedirecionarSequenciaCampo1] = useState(null);
  var [redirecionarSequenciaCampo2, setRedirecionarSequenciaCampo2] = useState(null);
  var [redirecionarSequenciaCampo3, setRedirecionarSequenciaCampo3] = useState(null);
  var [redirecionarSequenciaCampo4, setRedirecionarSequenciaCampo4] = useState(null);
  var [redirecionarSequenciaCampo5, setRedirecionarSequenciaCampo5] = useState(null);
  var [redirecionarSequenciaCampo6, setRedirecionarSequenciaCampo6] = useState(null);
  var [redirecionarSequenciaCampo7, setRedirecionarSequenciaCampo7] = useState(null);
  var [redirecionarSequenciaCampo8, setRedirecionarSequenciaCampo8] = useState(null);
  var [redirecionarSequenciaCampo9, setRedirecionarSequenciaCampo9] = useState(null);
  var [redirecionarSequenciaCampo10, setRedirecionarSequenciaCampo10] = useState(null);

  const [editing, setEditing] = useState<'response' | 'inicio' | 'fim'>('response');
  var [mensagemsInicio, setMensagemInicio] = useState("");
  var [mensagemsFim, setMensagemFim] = useState("");
  var [mensagemErro, setMensagemErro] = useState("");
  const [nomeDepartamento, setNomeDepartamento] = useState("");
  const [dataHoraInicio, setDataHoraInicio] = useState("");
  const [dataHoraFim, setDataHoraFim] = useState("");
  const [titulos, setTitulos] = useState("");
  const [url, setUrl] = useState("");
  const [showOption, setShowOption] = useState(false);
  const [showAtendimento, setShowAtendimento] = useState(false);
  const [showStore, setShowStore] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [urlSave, setUrlSave] = useState(false);
  const [storeAtivo, setStoreAtivo] = useState(0);
  const [stepsSelected, setStepsSelected] = useState([] as any);
  const [departamentoSelected, setDepartamentoSelected] = useState({} as any);
  const [departamentos, setDepartamentos] = useState({} as any);
  var [countOptions, setCountOptions] = useState(0);
  var formDataCreate = {} as any;
  var formData = {} as any;
  var [storeAtual, setStoreAtual] = useState({} as any);

  const handlePersonalizar = async () => {
    await carregamentoStore(props[0]['idInstance']);
    window.location.href = storeAtual.url;
  };

  const handleRemoveStep = (data: any) => {

    chatConfigApi.deleteBotConfig(data.id).then((r) => {
      chatConfigApi.listBotConfigs({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setSteps(response);
        handleClickSelectedStep([]);
      });
    })

  }

  const validateUrl = (input: string): boolean => {
    // Regex para validar caracteres permitidos: letras minúsculas, números, hífen e barra
    const validPattern = /^[a-z0-9\-\/]*$/;
    return validPattern.test(input);
  };


  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase(); // Converte para minúsculas
    if (validateUrl(inputValue)) {
      setUrl(inputValue); // Atualiza apenas se o valor for válido
    }
  };

  const handleUpdateDepartamento = () => {

    const updateCategoriaAtendente = {
      'setor': nomeDepartamento,
      'horaInicio': dataHoraInicio,
      'horaFim': dataHoraFim,
    }

    chatConfigApi.updateCategoriaAtendente(
      departamentoSelected['id'], updateCategoriaAtendente
    ).then((r) => {
      console.log(r)
      setDepartamentoSelected({});
      setNomeDepartamento('');
      setDataHoraFim('');
      setDataHoraInicio('');
      cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setDepartamentos(response);
      });
    });
  }

  const carregamentoStore = async (id: string) => {
    return StoreApi.getStoreEmpresa(id).then((response: any) => {
      console.log(response.data);
      setUrl(response.data.url);
      setUrlSave(response.data.url !== undefined);
      setStoreAtivo(response.data.ativo);
      setStoreAtual(response.data);
    });
  };


  const handleAddDepartamento = () => {

    chatConfigApi.createCategoriaAtendente({
      'setor': nomeDepartamento,
      'horaInicio': dataHoraInicio,
      'horaFim': dataHoraFim,
      "codUserInstance": instancia
    }).then((r) => {
      console.log(r)
      cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });

      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setDepartamentos(response);
      });
    });
  }

  const handleRemoveOption = () => {

    if (countOptions > 1) {
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      if (countOptions - 1 === 0) {
        setShowOption(false);
        setOption1("");
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      else if (countOptions - 1 === 1) {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      else if (countOptions - 1 === 2) {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      else if (countOptions - 1 === 3) {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      else if (countOptions - 1 === 4) {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      else if (countOptions - 1 === 5) {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      else if (countOptions - 1 === 6) {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      else if (countOptions - 1 === 7) {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      else if (countOptions - 1 === 8) {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      else if (countOptions - 1 === 9) {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }
      setCountOptions(countOptions - 1);
    }

  }

  const handleAtendimento = () => {
    carregamentoStore(props[0]['idInstance']);
    if (showAtendimento) {
      setShowAtendimento(false);
    }
    else {
      setShowAtendimento(true);
      setShowOption(false);
      setShowStore(false);
    }
  }

  const handleStore = () => {
    if (showStore) {
      setShowStore(false);
    }
    else {
      setShowStore(true);
      setShowAtendimento(false);
      setShowOption(false);
    }
  }

  const handleCheckBoxAtendimento = () => {
    if (showOption === true) {
      setShowOption(false);
      setCountOptions(0);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);
    }
    else {
      setShowOption(true);
      setShowAtendimento(false);
      setShowStore(false);
      setCountOptions(1);
    }
  }

  const handleClickOption1 = (data: any) => {
    setOption1(data.titulo);
    setRedirecionarSequenciaCampo1(data.id);
  }

  const handleClickOption2 = (data: any) => {
    setOption2(data.titulo);
    setRedirecionarSequenciaCampo2(data.id);
  }

  const handleClickOption3 = (data: any) => {

    setOption3(data.titulo);
    setRedirecionarSequenciaCampo3(data.id);
  }

  const handleClickOption4 = (data: any) => {

    setOption4(data.titulo);
    setRedirecionarSequenciaCampo4(data.id);
  }

  const handleClickOption5 = (data: any) => {

    setOption5(data.titulo);
    setRedirecionarSequenciaCampo5(data.id);
  }

  const handleClickOption6 = (data: any) => {

    setOption6(data.titulo);
    setRedirecionarSequenciaCampo6(data.id);
  }

  const handleClickOption7 = (data: any) => {

    setOption7(data.titulo);
    setRedirecionarSequenciaCampo7(data.id);
  }

  const handleClickOption8 = (data: any) => {

    setOption8(data.titulo);
    setRedirecionarSequenciaCampo8(data.id);
  }

  const handleClickOption9 = (data: any) => {

    setOption9(data.titulo);
    setRedirecionarSequenciaCampo9(data.id);
  }

  const handleClickOption10 = (data: any) => {

    setOption10(data.titulo);
    setRedirecionarSequenciaCampo10(data.id);
  }


  const handleCountOption = () => {

    if (countOptions < 10) {
      if (countOptions === 1) {
        if (option1 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 2) {
        if (option1 !== "" && option2 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 3) {
        if (option1 !== "" && option2 !== "" && option3 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 4) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 5) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 6) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 7) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 8) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 9) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
      if (countOptions === 10) {
        if (option1 !== "" && option2 !== "" && option3 !== "" && option4 !== "" && option5 !== "" && option6 !== "" && option7 !== "" && option8 !== "" && option9 !== "" && option10 !== "") {
          setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); setCountOptions(countOptions + 1);
        }
      }
    }
  }

  const handleRemoveDepartament = (data: any) => {

    chatConfigApi.deleteCategoriaAtendente(data.id).then((r: any) => {
      console.log(r);
      cookies.set('user', JSON.stringify(r.original.user), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('instancias', JSON.stringify(r.original.instances), { path: '/', expires: moment().add(1, 'day').toDate() });
      cookies.set('atendentes', JSON.stringify(r.original.atendentes), { path: '/', expires: moment().add(1, 'day').toDate() });
      chatConfigApi.listBotConfigs({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setSteps(response);
        handleClickSelectedStep([]);
      });
      chatConfigApi.listCategoriaAtendente({
        "filters": {
          "codUserInstance": instancia
        }
      }).then((response) => {
        setDepartamentos(response);
        setDepartamentoSelected({});
        setNomeDepartamento('');
        setDataHoraInicio('');
        setDataHoraFim('');
      });
    })
  }

  const handleClickSelectedStep = (data: any) => {
    carregamentoStore(props[0]['idInstance']);
    if (data.length === 0) {

      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setShowAtendimento(false);
      setShowStore(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

    }
    else {
      setMensagemInicio("");
      setMensagemFim("");
      setMensagemErro("");
      setTitulos("");
      setShowOption(false);
      setShowAlert(false);
      setCountOptions(0);
      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
      setOption1("");
      setOption2("");
      setOption3("");
      setOption4("");
      setOption5("");
      setOption6("");
      setOption7("");
      setOption8("");
      setOption9("");
      setOption10("");
      setRedirecionarSequenciaCampo1(null);
      setRedirecionarSequenciaCampo2(null);
      setRedirecionarSequenciaCampo3(null);
      setRedirecionarSequenciaCampo4(null);
      setRedirecionarSequenciaCampo5(null);
      setRedirecionarSequenciaCampo6(null);
      setRedirecionarSequenciaCampo7(null);
      setRedirecionarSequenciaCampo8(null);
      setRedirecionarSequenciaCampo9(null);
      setRedirecionarSequenciaCampo10(null);

      setMensagemInicio(data.mensagemInicio === null ? "" : data.mensagemInicio);
      setMensagemFim(data.mensagemFim === null ? "" : data.mensagemFim);
      setMensagemErro(data.mensagemErro === null ? "" : data.mensagemErro);
      setShowOption(data.opcao === 0 ? false : true);
      setShowStore(data.store === 0 ? false : true);
      setShowAtendimento(data.filaAtendimento === 0 ? false : true)
      setTitulos(data.titulo);
      setCountOptions(0);

      setNomeDepartamento('');
      setDataHoraInicio('');
      setDataHoraFim('');

      var count = 0;
      if (data.campo1 !== null) {
        setOption1(data.campo1);
        count++;
        setRedirecionarSequenciaCampo1(data.redirecionarSequenciaCampo1);
      }
      else {
        setOption1("");
        setRedirecionarSequenciaCampo1(null);
      }
      if (data.campo2 !== null) {
        setOption2(data.campo2);
        count++;
        setRedirecionarSequenciaCampo2(data.redirecionarSequenciaCampo2);
      }
      else {
        setOption2("");
        setRedirecionarSequenciaCampo2(null);
      }
      if (data.campo3 !== null) {
        setOption3(data.campo3);
        count++;
        setRedirecionarSequenciaCampo3(data.redirecionarSequenciaCampo3);
      }
      else {
        setOption3("");
        setRedirecionarSequenciaCampo3(null);
      }
      if (data.campo4 !== null) {
        setOption4(data.campo4);
        count++;
        setRedirecionarSequenciaCampo4(data.redirecionarSequenciaCampo4);
      }
      else {
        setOption4("");
        setRedirecionarSequenciaCampo4(null);
      }
      if (data.campo5 !== null) {
        setOption5(data.campo5);
        count++;
        setRedirecionarSequenciaCampo5(data.redirecionarSequenciaCampo5);
      }
      else {
        setOption5("");
        setRedirecionarSequenciaCampo5(null);
      }
      if (data.campo6 !== null) {
        setOption6(data.campo6);
        count++;
        setRedirecionarSequenciaCampo6(data.redirecionarSequenciaCampo6);
      }
      else {
        setOption6("");
        setRedirecionarSequenciaCampo6(null);
      }
      if (data.campo7 !== null) {
        setOption7(data.campo7);
        count++;
        setRedirecionarSequenciaCampo7(data.redirecionarSequenciaCampo7);
      }
      else {
        setOption7("");
        setRedirecionarSequenciaCampo7(null);
      }
      if (data.campo8 !== null) {
        setOption8(data.campo8);
        count++;
        setRedirecionarSequenciaCampo8(data.redirecionarSequenciaCampo8);
      }
      else {
        setOption8("");
        setRedirecionarSequenciaCampo8(null);
      }
      if (data.campo9 !== null) {
        setOption9(data.campo9);
        count++;
        setRedirecionarSequenciaCampo9(data.redirecionarSequenciaCampo9);
      }
      else {
        setOption9("");
        setRedirecionarSequenciaCampo9(null);
      }
      if (data.campo10 !== null) {
        setOption1(data.campo10);
        count++;
        setRedirecionarSequenciaCampo10(data.redirecionarSequenciaCampo10);
      }
      else {
        setOption10("");
        setRedirecionarSequenciaCampo10(null);
      }

      setCountOptions(count);
    }

    setStepsSelected(data);
  }

  const handleChangeInicio = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemInicio(wrappedText);

  }

  const handleChangeFim = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const originalText = event.target.value;
    const wrappedText = wrapText(originalText, 32);
    setMensagemFim(wrappedText);

  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();

      if (editing === 'inicio') {
        setMensagemInicio(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      } else if (editing === 'fim') {
        setMensagemFim(prevMensagem => {
          const newMessage = prevMensagem + '\n';
          return wrapText(newMessage, 32);
        });
      }
    }
  };

  const onEmojiSelectInicio = (emoji: any) => {
    setMensagemInicio(mensagemsInicio + emoji);
  };

  const onEmojiSelectFim = (emoji: any) => {
    setMensagemFim(mensagemsFim + emoji);
  };
  const onEmojiSelectErro = (emoji: any) => {
    setMensagemErro(mensagemErro + emoji);
  };


  const wrapText = (text: string, maxLineLength: number): string => {
    let wrappedText = '';
    let currentLineLength = 0;

    for (let i = 0; i < text.length; i++) {
      if (currentLineLength >= maxLineLength && text[i] !== '\n') {
        wrappedText += '\n';
        currentLineLength = 0;
      }
      wrappedText += text[i];
      currentLineLength++;

      if (text[i] === '\n') {
        currentLineLength = 0;
      }
    }

    return wrappedText;
  };


  const handleSave = () => {

    console.log(storeAtivo);
    if (titulos === "") return false;
    if (url === "" && showStore) return false;
    if (stepsSelected.length !== 0) {
      const updateConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        filaAtendimento: showAtendimento,
        opcao: showOption,
        store: showStore,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro
      };


      chatConfigApi.updateBotConfig(stepsSelected.id, updateConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          console.log(response);
          setSteps(response);
          if (showStore) {
            StoreApi.getStoreEmpresa(instancia).then((store: any) => {

              console.log(store)
              if (
                store.data &&
                typeof store.data === "object" &&
                !Array.isArray(store.data) &&
                Object.keys(store.data).length > 0
              ) {
                formData = {
                  codUserInstance: instancia,
                  url: url,
                  nome: store.data.nome || '',
                  descricao: store.data.descricao || "",
                  imagem: store.data.imagem || '',
                  endereco: store.data.endereco || '',
                  numero: store.data.numero,
                  complemento: store.data.complemento || '',
                  telefone: cookies.get('instancias')[0]['info']['response']['info']['Wid'].split('@')[0] + "",
                  corFundo: store.data.corFundo || "#FFFFFF",
                  cor1: store.data.cor1 || "#000000",
                  cor2: store.data.cor2 || "#FF5733",
                  coordenadas: store.data.coordenadas || "0,0",
                  moeda: store.data.moeda || 'BRL',
                  raioCobertura: store.data.raioCobertura || 0,
                  valorRaio: store.data.valorRaio || 0,
                  tipoVenda: store.data.tipoVenda,
                  ativo: storeAtivo,
                };
                StoreApi.updateStoreEmpresa(instancia, null, formData).then((updateStore) => {
                  console.log(updateStore);
                });
              } else {

                formDataCreate = {
                  codUserInstance: instancia,
                  url: url,
                  nome: '',
                  descricao: "",
                  imagem: '',
                  endereco: '',
                  complemento: '',
                  telefone: cookies.get('instancias')[0]['info']['response']['info']['Wid'].split('@')[0] + "",
                  corFundo: "#FFFFFF",
                  cor1: "#000000",
                  cor2: "#FF5733",
                  coordenadas: "0,0",
                  moeda: store.data.moeda || 'BRL',
                  raioCobertura: 0,
                  valorRaio: 0,
                  tipoVenda: 'varejo',
                  ativo: storeAtivo,
                };
                StoreApi.createStoreEmpresa(formDataCreate).then((createStore) => {
                  console.log(createStore);
                });
              }
            })
          }
        });
      });
    }
    else {
      const botConfigData = {
        titulo: titulos,
        codUserInstance: instancia,
        enviarDocumento: false,
        filaAtendimento: showAtendimento,
        opcao: showOption,
        store: showStore,
        campo1: option1,
        campo2: option2,
        campo3: option3,
        campo4: option4,
        campo5: option5,
        campo6: option6,
        campo7: option7,
        campo8: option8,
        campo9: option9,
        campo10: option10,
        redirecionarSequenciaCampo1: redirecionarSequenciaCampo1,
        redirecionarSequenciaCampo2: redirecionarSequenciaCampo2,
        redirecionarSequenciaCampo3: redirecionarSequenciaCampo3,
        redirecionarSequenciaCampo4: redirecionarSequenciaCampo4,
        redirecionarSequenciaCampo5: redirecionarSequenciaCampo5,
        redirecionarSequenciaCampo6: redirecionarSequenciaCampo6,
        redirecionarSequenciaCampo7: redirecionarSequenciaCampo7,
        redirecionarSequenciaCampo8: redirecionarSequenciaCampo8,
        redirecionarSequenciaCampo9: redirecionarSequenciaCampo9,
        redirecionarSequenciaCampo10: redirecionarSequenciaCampo10,
        mensagemInicio: mensagemsInicio,
        mensagemFim: mensagemsFim,
        mensagemErro: mensagemErro,
      };

      chatConfigApi.createBotConfig(botConfigData).then((r) => {
        chatConfigApi.listBotConfigs({
          "filters": {
            "codUserInstance": instancia
          }
        }).then((response) => {
          setSteps(response);
          if (showStore) {
            StoreApi.getStoreEmpresa(instancia).then((store: any) => {
              if (
                store.data &&
                typeof store.data === "object" &&
                !Array.isArray(store.data) &&
                Object.keys(store.data).length > 0
              ) {
                formData = {
                  codUserInstance: instancia,
                  url: url,
                  nome: store.data.nome || '',
                  descricao: store.data.descricao || "",
                  imagem: store.data.imagem || '',
                  endereco: store.data.endereco || '',
                  numero: store.data.numero,
                  complemento: store.data.complemento || '',
                  telefone: cookies.get('instancias')[0]['info']['response']['info']['Wid'].split('@')[0] + "",
                  corFundo: store.data.corFundo || "#FFFFFF",
                  cor1: store.data.cor1 || "#000000",
                  cor2: store.data.cor2 || "#FF5733",
                  coordenadas: store.data.coordenadas || "0,0",
                  moeda: store.data.moeda || 'BRL',
                  raioCobertura: store.data.raioCobertura || 0,
                  valorRaio: store.data.valorRaio || 0,
                  tipoVenda: store.data.tipoVenda,
                  ativo: storeAtivo,
                };

                StoreApi.updateStoreEmpresa(instancia, null, formData).then((updateStore) => {

                  setStepsSelected([])
                  setShowStore(false);
                  setShowAtendimento(false);
                  setShowOption(false);
                  setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
                  setTitulos('');
                  setMensagemInicio("");
                  setMensagemErro("");
                  setMensagemFim("");
                });
              } else {
                formDataCreate = {
                  codUserInstance: instancia,
                  url: url,
                  nome: '',
                  descricao: "",
                  imagem: '',
                  endereco: '',
                  complemento: '',
                  telefone: cookies.get('instancias')[0]['info']['response']['info']['Wid'].split('@')[0] + "",
                  corFundo: "#FFFFFF",
                  cor1: "#000000",
                  cor2: "#FF5733",
                  coordenadas: "0,0",
                  moeda: store.data.moeda || 'BRL',
                  raioCobertura: 0,
                  valorRaio: 0,
                  tipoVenda: 'varejo',
                  ativo: storeAtivo,
                };

                StoreApi.createStoreEmpresa(formDataCreate).then((createStore) => {

                  setStepsSelected([])
                  setShowStore(false);
                  setShowAtendimento(false);
                  setShowOption(false);
                  setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]);
                  setTitulos('');
                  setMensagemInicio("");
                  setMensagemErro("");
                  setMensagemFim("");
                });
              }
            })
          }
        });
      });
    }

  };

  const renderSteps = (steps: any, handleClickSelectedStep: any) => {
    const renderedSteps = [] as JSX.Element[];
    const processedIds = new Set<number>();

    const processStep = (stepId: number, path: string) => {
      if (processedIds.has(stepId)) return;
      const step = steps.find((s: any) => s.id === stepId);
      if (!step) return;

      processedIds.add(stepId);

      const nextSteps = Array.from({ length: 10 }, (_, i) => {
        const nextStepId = step[`redirecionarSequenciaCampo${i + 1}`];
        if (nextStepId) {
          const nextStep = steps.find((s: any) => s.id === nextStepId);
          if (nextStep) {
            const currentPath = `${path !== '' ? path + '.' + (i + 1) : (i + 1)}`;
            return (
              <div className='text-sm' key={nextStep.id} onClick={() => { handleClickSelectedStep(nextStep) }}>
                <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                  {`${currentPath} - ${nextStep.titulo}`}
                </label>
                <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "220px" }}>
                  <p className="text-xs mt-1 mb-2">{nextStep.mensagemInicio}</p>
                  {nextStep.opcao === 1 && Array.from({ length: 10 }, (_, j) => {
                    const campo = nextStep[`campo${j + 1}`];
                    return campo && <p className="text-xs mt-2 mb-2" key={j + 1}>{`${j + 1}`} - {campo}</p>;
                  })}
                  {nextStep.store === 1 && url ?
                    <p className="text-xs mt-1 mb-2 ">https://chatbr.xyz/{url}</p> : <></>}
                  {nextStep.filaAtendimento === 1 && departamentos.length > 0 && departamentos.map((r: any, key: any) => {
                    return <p className="text-xs mt-2 mb-2" key={r.id}>{`${key + 1}`} - {r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</p>;
                  })}
                  <p className="text-xs mt-1 mb-2">{nextStep.mensagemFim}</p>
                  <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                </div>
                {nextStep.opcao === 1 && (
                  <div>
                    <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                      Retorno Opção Inválida
                    </label>
                    <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "220px" }}>
                      <div className="w-full justify-start">
                        <div className="rounded" style={{ backgroundColor: "#E2F7CB" }}>
                          <p className="text-xs">{nextStep.mensagemErro}</p>
                          <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
            );
          }
        }
        return null;
      }).filter((item: JSX.Element | null) => item !== null);

      if (nextSteps.length > 0) {
        renderedSteps.push(
          <li key={stepId} className={`flex-shrink-0 flex gap-x-2 cursor-pointer p-4 ${step.opcao === 1 ? 'border-dotted border-4' : ''}`}>
            {nextSteps}
          </li>
        );

        if (step.opcao === 1) {
          Array.from({ length: 10 }, (_, i) => {
            const nextStepId = step[`redirecionarSequenciaCampo${i + 1}`];
            if (nextStepId) processStep(nextStepId, `${path !== '' ? path + '.' + (i + 1) : (i + 1)}`);
          });
        }
        if (step.store === 1 && url) {
          <p className="text-sm mt-1 mb-2 ">https://chatbr.xyz/{url}</p>
        }

      }
    };

    if (steps.length > 0) {
      processStep(steps[0].id, '');
    }

    return (
      <ul role="list" className="flex flex-row gap-4 p-4">
        {renderedSteps}
      </ul>
    );
  };

  const location = useLocation();
 useEffect(() => {
  const idInstance = props[0]['idInstance'];
  setInstancia(idInstance);

  carregamentoStore(idInstance);

  chatConfigApi.listBotConfigs({
    filters: { codUserInstance: idInstance }
  }).then(response => {
    setSteps(response);

    chatConfigApi.listCategoriaAtendente({
      filters: { "codUserInstance": idInstance }
    }).then(response => {
    console.log(response.data);
      if (Array.isArray(response.data)) {
        setDepartamentos(response.data);
      } else {
        setDepartamentos([]);
        console.error('Resposta inesperada da API:', response);
      }
    }).catch(error => {
      console.error('Erro na API de departamentos:', error);
      setDepartamentos([]);
    });

  });

}, []);
  return (
    <>
      <Modal
        show={showAlert}
        size={"small"}
        fullscreen={false}
        title={
          <>
            <div className='text-center text-dark'>

            </div>
          </>
        }
        list={
          <>
            <div className='text-center text-dark'>
              <div className='text-center text-dark'>
                Antes de adicionar a nova etapa, você deseja salvar as alterações?<br />
                <button
                  onClick={() => { handleSave(); handleClickSelectedStep([]); setShowAlert(false); }}
                  className="mt-6 rounded-lg bg-green-500 px-4 py-2 text-center text-base font-semibold text-white outline-none ring-green-500 ring-offset-2 transition hover:bg-green-400 focus:ring-2 md:w-32"
                >
                  Sim
                </button>
                <button
                  onClick={() => { handleClickSelectedStep([]); setShowAlert(false); }}
                  className="mt-6 ml-4 rounded-lg bg-blue-500 px-4 py-2 text-center text-base font-semibold text-white outline-none ring-blue-500 ring-offset-2 transition hover:bg-blue-400 focus:ring-2 md:w-32"
                >
                  Não
                </button>
                <button
                  onClick={() => setShowAlert(false)}
                  className="mt-6 ml-4 rounded-lg bg-yellow-500 px-4 py-2 text-center text-base font-semibold text-white outline-none ring-yellow-500 ring-offset-2 transition hover:bg-yellow-400 focus:ring-2 md:w-32"
                >
                  Cancelar
                </button>
              </div>
              <span className="visually-hidden"></span>

            </div>
          </>
        }
        footer={
          <>
            <br />
          </>
        }
      />
      <div className="w-full overflow-auto h-[calc(100vh-90px)] xl:h-auto" style={{ background: "#dad3cc" }} >
        <div className="m-1 p-1 overflow-x-auto overflow-y-auto">
          {/* Cabeçalho do Accordion */}
          <div
            className="flex justify-center bg-white rounded-lg border items-center cursor-pointer"
            onClick={() => setIsOpen(!isOpen)}
          >
            <label htmlFor="search" className="flex text-center justify-center font-medium text-gray-700 p-2 cursor-pointer">
              Fluxo
            </label>
            <span className={`transform transition-transform duration-200 ${isOpen ? "rotate-180" : "rotate-0"}`}>
            <ChevronDownIcon className='h-5 w-5 ml-1' />
            </span>
          </div>

          {/* Conteúdo do Accordion */}
          <div
            className={`overflow-hidden transition-all duration-300  ${isOpen ? "max-h-[500px]" : "max-h-0"
              }`}
          >
            <div className="w-full flex overflow-x-auto bg-white rounded-lg border">
            <ul className="flex flex-row overflow-x-auto scrollbar-thin scrollbar-thumb-blue-400 scrollbar-track-blue-200 space-x-4">
                {steps[0] !== undefined && (
                  <li key={steps[0].id} className="flex gap-x-2 p-2 ml-1 border-dotted border-4 mt-4" onClick={() => { handleClickSelectedStep(steps[0]) }}>
                    <div className='text-sm mt-2'>
                      <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                        {steps[0].titulo} {'(Primeiro Contato)'}
                      </label>
                      <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "220px" }}>
                        <p className="text-xs mt-1 mb-2">{steps[0].mensagemInicio}</p>
                        {Array.from({ length: 10 }, (_, i) => {
                          const campo = steps[0][`campo${i + 1}`];
                          return campo && <p className="text-xs mt-2 mb-2" key={i + 1}>{i + 1} - {campo}</p>;
                        })}
                        <p className="text-xs mt-1 mb-2">{steps[0].mensagemFim}</p>
                        <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                      </div>
                      {steps[0].opcao === 1 && (
                        <div>
                          <label htmlFor="search" className="block text-center font-small text-gray-700 cursor-pointer">
                            Retorno Opção Inválida
                          </label>
                          <div className="rounded px-1.5 mt-1.5" style={{ backgroundColor: "#E2F7CB", width: "220px" }}>
                            <div className="w-full justify-start">
                              <div className="rounded" style={{ backgroundColor: "#E2F7CB" }}>
                                <p className="text-xs">{steps[0].mensagemErro}</p>
                                <p className="text-right text-xs text-grey-dark mt-1">xx:xx pm</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </li>
                )}
                {renderSteps(steps, handleClickSelectedStep)}
              </ul>
            </div>
          </div>
        </div>

          <div className="grid grid-cols-1 gap-2 md:grid-cols-2 xl:grid-cols-4 m-2" >
            <div className="m-auto p-2 col-3 bg-white rounded-lg w-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
              <label htmlFor="search" className="block text-center font-medium text-gray-700">
                Rotinas
              </label>
              <div className="max-w-lg mx-auto">
                <ul className="mt-1 max-h-100 overflow-y border border-green-300 rounded-md">
                  <li className={`cursor-pointer list-group-item text-center pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-gray-800 ${stepsSelected?.length === 0 ? "bg-green-100" : "bg-white"}`} onClick={() => { handleClickSelectedStep([]); }}>
                    {stepsSelected?.length === 0 ?
                      <svg style={{ color: "#16a34a" }} className="h-6 w-6" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" fill="current"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path fill="#000000" fill-rule="evenodd" d="M10 3a7 7 0 100 14 7 7 0 000-14zm-9 7a9 9 0 1118 0 9 9 0 01-18 0zm14 .069a1 1 0 01-1 1h-2.931V14a1 1 0 11-2 0v-2.931H6a1 1 0 110-2h3.069V6a1 1 0 112 0v3.069H14a1 1 0 011 1z"></path> </g></svg>
                      :
                      <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                    }
                    Adicionar Nova Rotina
                    <div className="px-2 ml-4 transition ease-in duration-200 text-center text-base" />
                  </li>
                  {steps !== undefined && steps.length > 0 ?
                    steps.map((r: any, key: number) => {
                      return (<>
                        <li className={`cursor-pointer list-group-item pl-3 pr-4 py-2 border-b border-green-200 w-full flex items-center hover:bg-green-100 justify-between text-gray-800 ${r.id === stepsSelected.id ? "bg-green-100" : "bg-white"}`} onClick={() => { setShowAlert(true); handleClickSelectedStep(r); }}>
                          {stepsSelected.id === r.id ? (
                            <CheckCircleIcon aria-hidden="true" className="h-6 w-6 text-green-600" />
                          ) : (
                            <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
                          )}
                          <p>{r.titulo} {key === 0 && '(Primeiro Contato)'}</p>
                          <button
                            type="submit" onClick={() => { handleRemoveStep(r) }}
                            className="px-2 ml-1 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                          >
                            <TrashIcon />
                          </button>
                        </li>
                      </>)
                    })
                    : <></>}
                </ul>
              </div>
            </div>


            <div className="m-auto p-2 col-3 bg-white rounded-lg w-full xsm:h-full sm:h-full md:h-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
              <label htmlFor="Title" className="block text-center font-medium text-gray-700">Opções</label>
              <div className="max-w-lg mx-auto">

                {!showAtendimento && !showStore && <>

                  <label className="flex items-center space-x-3 cursor-pointer">
                    <input
                      type="checkbox"
                      name="checked-demo"
                      className="opacity-0 absolute h-6 w-6"
                      onClick={() => { handleCheckBoxAtendimento() }}
                    />
                    <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                      {showOption && (
                        <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                          <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                        </svg>

                      )}
                    </div>
                    <span className="font-normal text-gray-700 dark:text-white">Rotinas Automáticas</span>
                  </label>
                  <div className="max-w-lg mx-auto">

                    <div>
                      <div className="flex">
                        <div className="w-64">
                          {countOptions >= 1 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[0] === 0) {
                                    setShowSteps([1, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option1 === "" ? "Selecione a rotina 1" : option1} </span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[0] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 1</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption1(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div> : <></>}
                          {countOptions >= 2 ?

                            <>
                              <div className="relative mt-1">
                                <button
                                  type="button"
                                  className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                  onClick={() => {
                                    if (ShowSteps[1] === 0) {
                                      setShowSteps([0, 1, 0, 0, 0, 0, 0, 0, 0, 0])
                                    }
                                    else {
                                      setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                    }
                                  }
                                  }
                                >
                                  <span className="flex items-center">
                                    <span className="block ml-3 truncate">{option2 === "" ? "Selecione a rotina 2" : option2}</span>
                                  </span>

                                </button>
                                <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[1] === 1 ? "absolute" : "hidden"}`}>
                                  <ul
                                    role="listbox"
                                    aria-labelledby="listbox-label"
                                    aria-activedescendant="listbox-item-3"
                                    className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"

                                  >
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">Selecione a rotina 2</span>
                                      </div>

                                    </li>
                                    {steps !== undefined && steps.length > 0 ?
                                      steps.map((r: any) => {
                                        return (<>
                                          <li
                                            id="listbox-item-0"
                                            role="option"
                                            className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                            onClick={() => { handleClickOption2(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                          >
                                            <div className="flex items-center">
                                              <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                            </div>

                                          </li></>)
                                      })
                                      : <></>}
                                    <li
                                      id="listbox-item-0"
                                      role="option"
                                      className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                      onClick={() => { setShowAlert(true); }}
                                    >
                                      <div className="flex items-center">
                                        <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                      </div>

                                    </li>
                                  </ul>
                                </div>
                              </div></>
                            : <></>}
                          {countOptions >= 3 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[2] === 0) {
                                    setShowSteps([0, 0, 1, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option3 === "" ? "Selecione a rotina 3" : option3}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[2] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 3</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption3(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 4 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[3] === 0) {
                                    setShowSteps([0, 0, 0, 1, 0, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option4 === "" ? "Selecione a rotina 4" : option4}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[3] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base max-h-32 rounded-md ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 4</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption4(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 5 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[4] === 0) {
                                    setShowSteps([0, 0, 0, 0, 1, 0, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option5 === "" ? "Selecione a rotina 5" : option5}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[4] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 5</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption5(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 6 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[5] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 1, 0, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option6 === "" ? "Selecione a rotina 6" : option6}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[5] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 6</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption6(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 7 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[6] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 1, 0, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option7 === "" ? "Selecione a rotina 7" : option7}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[6] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 7</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption7(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 8 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[7] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 1, 0, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                } >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option8 === "" ? "Selecione a rotina 8" : option8}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[7] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 8</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption8(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 9 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[8] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 1, 0])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option9 === "" ? "Selecione a rotina 9" : option9}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[8] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 9</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption9(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                          {countOptions >= 10 ?
                            <div className="relative mt-1">
                              <button
                                type="button"
                                className="relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm"
                                onClick={() => {
                                  if (ShowSteps[9] === 0) {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 1])
                                  }
                                  else {
                                    setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
                                  }
                                }
                                }
                              >
                                <span className="flex items-center">
                                  <span className="block ml-3 truncate">{option10 === "" ? "Selecione a rotina 10" : option10}</span>
                                </span>

                              </button>
                              <div className={`z-10 w-full mt-1 bg-white rounded-md ${ShowSteps[9] === 1 ? "absolute" : "hidden"}`}>
                                <ul
                                  role="listbox"
                                  aria-labelledby="listbox-label"
                                  aria-activedescendant="listbox-item-3"
                                  className="py-1 overflow-auto text-base rounded-md max-h-32 ring-1 ring-green ring-opacity-5 focus:outline-none sm:text-sm"
                                >
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Selecione a rotina 10</span>
                                    </div>

                                  </li>
                                  {steps !== undefined && steps.length > 0 ?
                                    steps.map((r: any) => {
                                      return (<>
                                        <li
                                          id="listbox-item-0"
                                          role="option"
                                          className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                          onClick={() => { handleClickOption10(r); setShowSteps([0, 0, 0, 0, 0, 0, 0, 0, 0, 0]); }}
                                        >
                                          <div className="flex items-center">
                                            <span className="block ml-3 font-normal truncate">{r.titulo}</span>
                                          </div>

                                        </li></>)
                                    })
                                    : <></>}
                                  <li
                                    id="listbox-item-0"
                                    role="option"
                                    className={`relative py-2 pl-3 text-gray-900 cursor-default select-none hover:bg-green-500 hover:text-white pr-9 `}
                                    onClick={() => { setShowAlert(true); }}
                                  >
                                    <div className="flex items-center">
                                      <span className="block ml-3 font-normal truncate">Adicionar Nova Rotina</span>
                                    </div>

                                  </li>
                                </ul>
                              </div>
                            </div>
                            : <></>}
                        </div>
                        {countOptions > 0 ?
                          <button
                            type="submit" onClick={() => { handleCountOption() }}
                            className="px-2 ml-1 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                          >
                            <PlusIcon />
                          </button> : <></>}
                        {countOptions > 1 ?
                          <button
                            type="submit" onClick={() => { handleRemoveOption() }}
                            className="px-2 ml-1 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                          >
                            <TrashIcon />
                          </button> : <></>}
                      </div>
                    </div>
                    {countOptions >= 1 ?
                      <div className="">
                        <label htmlFor="Title" className="block text-center font-medium text-gray-700">Retorno Opção Inválida</label>
                        <div className="max-w-lg mx-auto">
                          <div className=" relative ">
                            <div>
                              <textarea id="text-area"
                                className='relative w-full py-3 pl-3 pr-10 text-left bg-white rounded-md border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                                value={mensagemErro} onChange={(e) => setMensagemErro(e.target.value)} />

                              <EmojiPicker onEmojiSelect={onEmojiSelectErro} />

                            </div>
                          </div>
                        </div>

                      </div>
                      : <></>}
                  </div>
                </>}
                {!showOption && !showStore && <>
                  <label className="flex items-center space-x-3 cursor-pointer mt-2">
                    <input
                      type="checkbox"
                      name="checked-demo"
                      className="opacity-0 absolute h-6 w-6"
                      onClick={() => { handleAtendimento() }}
                    />
                    <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                      {showAtendimento && (
                        <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                          <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                        </svg>

                      )}
                    </div>
                    <span className="font-normal text-gray-700 dark:text-white">Setores De Atendimento</span>
                  </label>
                  <div className="w-full mt-2 block">


                    {showAtendimento && <>
                      <label htmlFor="Title" className="block text-center font-medium text-gray-700">Setor</label>
                      <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-2 text-gray-700 whitespace-nowrap">
                        Selecionar/Adicionar Setor
                      </label>
                      <div className='m-auto p-2 col-3 bg-white rounded-lg w-full '>
                        <div className="flex flex-row mr-1">

                          <select
                            id="countries_disabled"
                            value={departamentoSelected.id !== undefined ? departamentoSelected.id : ''}
                            onChange={
                              (e) => {
                                var key = parseInt(e.target.value);
                                console.log(key);
                                var departamentoSelecionado = departamentos.find((d: any) => d.id === key);
                                console.log(departamentoSelecionado);
                                if (departamentoSelecionado !== undefined) {
                                  setDepartamentoSelected(departamentoSelecionado);
                                  if (departamentoSelecionado['setor'] !== undefined) {
                                    setNomeDepartamento(departamentoSelecionado['setor'])
                                  }
                                  if (departamentoSelecionado['horaInicio'] !== undefined) {
                                    setDataHoraInicio(departamentoSelecionado['horaInicio'].slice(0, -3))
                                  }
                                  if (departamentoSelecionado['horaFim'] !== undefined) {
                                    setDataHoraFim(departamentoSelecionado['horaFim'].slice(0, -3))
                                  }
                                }
                                else {
                                  setDepartamentoSelected({});
                                  setNomeDepartamento('');
                                  setDataHoraInicio('');
                                  setDataHoraFim('');
                                }
                              }}
                            className="bg-gray-50 border mr-4 border-green-300 rounded-lg py-2 px-4 text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-full"
                          >
                            <option className='text-gray-400' value="">Adicionar Novo Setor</option>
                            {departamentos.map((r: any) => {
                              return (
                                <>
                                  <option value={r.id}>{r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</option>
                                </>)
                            }
                            )}
                          </select>
                          {departamentoSelected['setor'] !== undefined &&
                            <button
                              type="submit" onClick={() => { handleRemoveDepartament(departamentoSelected) }}
                              className="px-2 h-23 w-22 hover:bg-red-400 focus:ring-red-500 focus:ring-offset-red-200 text-dark transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2 rounded-lg "
                            >
                              <TrashIcon />
                            </button>
                          }
                        </div>
                        <div className="max-w-lg mx-auto">

                          <label htmlFor="datetime-start" className="font-normal text-sm mt-2 text-gray-700 whitespace-nowrap">
                            Nome
                          </label>
                          <div className="relative ">

                            <input
                              type="text"
                              id="Title"
                              value={nomeDepartamento}
                              className="rounded-md flex w-full border border-green-300 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                              onChange={(e) => setNomeDepartamento(e.target.value)}
                            />
                          </div>
                          <div className="flex space-x-2 ml-4">
                            <div className="flex flex-col mr-1">
                              <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                                Horário Inicio
                              </label>
                              <input
                                id="datetime-start"
                                type="time"
                                value={dataHoraInicio}
                                className="bg-gray-50 border text-sm text-center  border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                                onChange={(e) => setDataHoraInicio(e.target.value)}
                                placeholder="Select date and time"
                              />
                            </div>

                            <div className="flex flex-col">
                              <label htmlFor="datetime-finish" className="font-normal text-sm mt-2 ml-4 text-gray-700 whitespace-nowrap">
                                Horário Fim
                              </label>
                              <input
                                id="datetime-finish"
                                type="time"
                                value={dataHoraFim}
                                onChange={(e) => setDataHoraFim(e.target.value)}
                                className="bg-gray-50 border text-center text-sm border-green-300 rounded-lg xsm:ml-4 py-2 xsm:px-1 sm:px-4 md:px-4 lg:px-4 text-gray-700 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent w-[122px]"
                                placeholder="Select date and time"
                              />
                            </div>
                          </div>
                          <button
                            type="submit"
                            className="py-2 px-4 mt-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                            onClick={() => { departamentoSelected['setor'] !== undefined ? handleUpdateDepartamento() : handleAddDepartamento() }}
                          >
                            Departament {departamentoSelected['setor'] !== undefined ? 'Update' : 'Insert'}
                          </button>


                        </div>
                      </div>
                    </>}
                  </div>

                </>}
                {!showOption && !showAtendimento && <>
                  <label className="flex items-center space-x-3 cursor-pointer mt-2">
                    <input
                      type="checkbox"
                      name="checked-demo"
                      className="opacity-0 absolute h-6 w-6"
                      onClick={() => { handleStore() }}
                    />
                    <div className="bg-white border border-green-300 rounded-md h-6 w-6 flex items-center justify-center">
                      {showStore && (
                        <svg className="fill-current text-green-500" width="22" height="22" viewBox="0 0 20 20">
                          <path d="M7.629 15.179L3.822 11.372l1.415-1.414 2.392 2.391 5.656-5.657 1.414 1.415z" />
                        </svg>

                      )}
                    </div>
                    <span className="font-normal text-gray-700 dark:text-white">Cardápio Digital</span>
                  </label>

                  {showStore && url !== "" && url !== undefined && urlSave && (
                    <div className="max-w-lg mx-auto row text-center justify-center">
                      <label htmlFor="Title" className="block text-center font-medium text-gray-700">
                        Personalizar Cardápio
                      </label>
                      <div className="flex justify-center cursor-pointer">
                        <svg xmlns="http://www.w3.org/2000/svg" onClick={() => { handlePersonalizar(); }} viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500 justify-center align-middle">
                          <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                        </svg>
                      </div>
                    </div>
                  )}

                  {showStore && (
                    <div className="max-w-lg mx-auto row">
                      <label htmlFor="Title" className="block font-medium text-gray-700 text-center justify-center">
                        Acesso ao Cardápio
                      </label>
                      <label htmlFor="datetime-start" className="font-normal text-sm mt-2 ml-2 text-gray-700 whitespace-nowrap">
                        Acesso ao Cardápio
                      </label>
                      {/* Input para a URL */}
                      <div className="flex w-full">
                        <input
                          type="text"
                          value={url}
                          onChange={handleUrlChange}
                          placeholder="Digite a URL..."
                          className="rounded-md flex border border-green-300 py-2 px-2 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                        />
                        {url !== "" && url !== undefined && urlSave &&
                          <div className="relative inline-flex col-4 select-none ml-1 pl-4">
                            <div className="relative inline-block w-10 select-none">

                              <small className='text-center whitespace-nowrap'>Fechado/Aberto</small>
                              {storeAtivo === 1 ?
                                <>
                                  <input
                                    type="checkbox"
                                    name="toggle"
                                    id="storeAtivo"
                                    title="Fechar Cardapio Virtual"
                                    checked
                                    onClick={() => { setStoreAtivo(0); }}
                                    className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                  />
                                  <label
                                    htmlFor="storeAtivo"
                                    className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                  >
                                  </label>
                                </>
                                :
                                <>
                                  <input
                                    type="checkbox"
                                    name="toggle"
                                    title="Abrir Cardapio Virtual"
                                    id="storeAtivo"
                                    onClick={() => { setStoreAtivo(1); }}
                                    className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                  />
                                  <label
                                    htmlFor="storeAtivo"
                                    className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                  ></label>

                                </>
                              }

                            </div>
                          </div>}
                      </div>

                    </div>

                  )}
                </>}
              </div>
            </div>

            <div className="m-auto p-2 col-3 bg-white rounded-lg w-full" style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
              <label htmlFor="Title" className="block text-center font-medium text-gray-700">Nome Rotina</label>
              <div className="max-w-lg mx-auto">
                <div className="relative ">
                  <input
                    type="text"
                    id="Title"
                    value={titulos}
                    className="rounded-md flex-1 border border-green-300 w-full py-1 px-4 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                    onChange={(e) => setTitulos(e.target.value)}
                  />
                </div>
              </div>
              <label htmlFor="Title" className="block text-center font-medium text-gray-700 py-1">Texto Inicial</label>
              <div className="max-w-lg mx-auto">

                <textarea id="text-area" className='relative w-full py-1 pl-3 pr-10 text-left bg-white rounded border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                  value={mensagemsInicio}
                  onFocus={() => setEditing('inicio')}
                  //  onKeyDown={handleKeyDown}
                  onChange={handleChangeInicio}
                />

                <EmojiPicker onEmojiSelect={onEmojiSelectInicio} />
              </div>
              <label htmlFor="Title" className="block text-center font-medium text-gray-700">Texto Final</label>
              <div className=" max-w-lg mx-auto">
                <textarea id="text-area" className='relative w-full py-1 pl-3 pr-10 text-left bg-white rounded border border-green-300 cursor-default focus:outline-none focus:ring-1 focus:ring-green-500 focus:border-green-500 sm:text-sm'
                  value={mensagemsFim}
                  onFocus={() => setEditing('fim')}
                  // onKeyDown={handleKeyDown}
                  onChange={handleChangeFim} />
                <EmojiPicker onEmojiSelect={onEmojiSelectFim} />
              </div>

            </div>


            <div className="m-auto p-2 col-3 bg-white rounded-lg w-full " style={{ maxHeight: "380px", height: "380px", overflow: "auto" }}>
              <div className="w-full justify-start " >
                <label htmlFor="search" className="block text-center font-medium text-gray-700">
                  Retorno
                </label>
                <div
                  className="rounded px-3 mt-3 m-auto p-2 col-3 bg-white rounded-lg w-full "
                  style={{ backgroundColor: "#E2F7CB", width: "250px" }}
                >
                  <p className="text-sm mt-1 mb-2 ">{mensagemsInicio}</p>

                  {showAtendimento && departamentos.length > 0 && departamentos.map((r: any, key: any) => {
                    return <p className="text-xs mt-2 mb-2" key={r.id}>{`${key + 1}`} - {r.setor} ({r.horaInicio.slice(0, -3)} - {r.horaFim.slice(0, -3)})</p>;
                  })}
                  {option1 !== "" ? <p className="text-sm mt-2 mb-2">1 - {option1}</p> : <></>}
                  {option2 !== "" ? <p className="text-sm mt-2 mb-2">2 - {option2}</p> : <></>}
                  {option3 !== "" ? <p className="text-sm mt-2 mb-2">3 - {option3}</p> : <></>}
                  {option4 !== "" ? <p className="text-sm mt-2 mb-2">4 - {option4}</p> : <></>}
                  {option5 !== "" ? <p className="text-sm mt-2 mb-2">5 - {option5}</p> : <></>}
                  {option6 !== "" ? <p className="text-sm mt-2 mb-2">6 - {option6}</p> : <></>}
                  {option7 !== "" ? <p className="text-sm mt-2 mb-2">7 - {option7}</p> : <></>}
                  {option8 !== "" ? <p className="text-sm mt-2 mb-2">8 - {option8}</p> : <></>}
                  {option9 !== "" ? <p className="text-sm mt-2 mb-2">9 - {option9}</p> : <></>}
                  {option10 !== "" ? <p className="text-sm mt-2 mb-2">10 - {option10}</p> : <></>}

                  {url !== "" && url !== undefined && showStore ? <p className="text-sm mt-1 mb-2 ">https://chatbr.xyz/{url}</p> : ''}
                  <p className="text-sm mt-1 mb-2 ">{mensagemsFim}</p>
                  <p className="text-right text-xs text-grey-dark mt-1">
                    xx:xx pm
                  </p>
                </div>

                {countOptions >= 1 ?
                  <div className="w-full justify-start mt-2">
                    <label htmlFor="search" className="block text-center font-medium text-gray-700">
                      Retorno Opção Inválida
                    </label>
                    <div
                      className="px-3 m-auto col-3 bg-white rounded-lg w-ful"
                      style={{ backgroundColor: "#E2F7CB", width: "250px" }}
                    >
                      <div className="w-full justify-start">
                        <p className="text-sm">{mensagemErro}</p>
                        <p className="text-right text-xs text-grey-dark mt-1">
                          xx:xx pm
                        </p>
                      </div>
                    </div>

                  </div> : <></>}
              </div>

            </div>

          </div> <button
            type="submit"
            className="py-2 px-4 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
            onClick={() => { handleSave() }}
          >
            Salvar
          </button>
      </div>
    </>
  );


}
export default BotConfig;
