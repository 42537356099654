import { useCallback, useEffect, useId, useRef, useState } from 'react'
import { DiamondIcon } from '../../../components/DiamondIcon'
import clsx from 'clsx'
import { Tab, TabGroup, TabList, TabPanel, TabPanels } from '@headlessui/react'
import {
  ShoppingBagIcon, ChevronDownIcon, ChevronUpIcon
} from '@heroicons/react/24/outline'
import { Bounce, Slide, ToastContainer, toast } from "react-toastify";

import L from "leaflet";
import GpsAddressPicker from '../../../components/Inputs/GpsAddressPicker'
import FreightCalculator from '../../../components/Inputs/FreightCalculator'
import Cookies from 'universal-cookie';
import StoreApi from '../../../services/StoreApi'
import { Carrinho } from './Carrinho'
import DraggableImage from '../../../components/Inputs/DraggableImage'
import { Button, Dialog, DialogTrigger, Heading, Modal } from 'react-aria-components'
import BotConfig from './BotConfig'
import { ChevronLeftIcon } from '@heroicons/react/24/solid'
import ProductForm from './ProductForm'
import { empty } from 'rxjs'
import { FileUpload } from '../../../components/components/ui/File-upload' 

const cookies = new Cookies();


function ImageClipPaths({ id, ...props }: React.ComponentPropsWithoutRef<'svg'> & { id: string }) {
  return (
    <svg aria-hidden="true" width={0} height={0} {...props}>
      <defs>
        <clipPath id={`${id}-0`} clipPathUnits="objectBoundingBox">
          <path d="M0,0 h0.729 v0.129 h0.121 l-0.016,0.032 C0.815,0.198,0.843,0.243,0.885,0.243 H1 v0.757 H0.271 v-0.086 l-0.121,0.057 v-0.214 c0,-0.032,-0.026,-0.057,-0.057,-0.057 H0 V0" />
        </clipPath>
        <clipPath id={`${id}-1`} clipPathUnits="objectBoundingBox">
          <path d="M1,1 H0.271 v-0.129 H0.15 l0.016,-0.032 C0.185,0.802,0.157,0.757,0.115,0.757 H0 V0 h0.729 v0.086 l0.121,-0.057 v0.214 c0,0.032,0.026,0.057,0.057,0.057 h0.093 v0.7" />
        </clipPath>
        <clipPath id={`${id}-2`} clipPathUnits="objectBoundingBox">
          <path d="M1,0 H0.271 v0.129 H0.15 l0.016,0.032 C0.185,0.198,0.157,0.243,0.115,0.243 H0 v0.757 h0.729 v-0.086 l0.121,0.057 v-0.214 c0,-0.032,0.026,-0.057,0.057,-0.057 h0.093 V0" />
        </clipPath>
      </defs>
    </svg>
  )
}

interface StoreProps {
  parametros: {
    id: number;
    codUserInstance: number;
    url: string;
    nome: string;
    descricao: string;
    imagem: string | null;
    corFundo: string;
    cor1: string;
    cor2: string;
    endereco: string | null;
    numero: number | null;
    complemento: string | null;
    coordenadas: [number, number];
    moeda: string;
    raioCobertura: number;
    valorRaio: number;
    tipoVenda: any;
    isModal:any;
    ativo: number;
    created_at: string;
    updated_at: string;
    categorias: any[];
  };
}

interface Categoria {
  id: number;
  codStoreEmpresa: Number;
  nome: string;
  descricao: string;
  produtos: any;
}

interface Produto {
  id: number;
  nome: string;
  quantidade: number;
  preco: number;
}

export function Store({ parametros }: StoreProps) {
  let id = useId()
  let [parametro, setParametro] = useState(parametros);
  let [empresa, setEmpresa] = useState(parametro.nome)
  let [descricaoEmpresa, setDescricaoEmpresa] = useState(parametro.descricao)
  let [tabOrientation, setTabOrientation] = useState('horizontal')
  let [colorBackground, setColorBackground] = useState<string>(parametro.corFundo)
  let [colorPrimary, setColorPrimary] = useState<string>(parametro.cor1)
  let [colorSecondary, setColorSecondary] = useState<string>(parametro.cor2)
  const [tipoVenda, setTipoVenda] = useState(parametro.tipoVenda);

  let [selectedCategory, setSelectedCategory] = useState<string>('')
  let [newCategory, setNewCategory] = useState<string>('')
  let [newCategoryDescription, setNewCategoryDescription] = useState<string>('')
  let [selectedProduto, setSelectedProduto] = useState<string>('')
  let [newProduto, setNewProduto] = useState<string>('')
  let [newProdutoDescription, setNewProdutoDescription] = useState<string>('')
  /* endereço entrega */
  const [numero, setNumero] = useState<string | number>('');
  const [categoriaAtual, setCategoriaAtual] = useState({} as any);
  const [produtoAtual, setProdutoAtual] = useState({} as any);
  const [complemento, setComplemento] = useState<string>('');
  const [addressEmpresa, setAddressEmpresa] = useState<string>(parametro.endereco ? parametro.endereco : '');
  const [numeroEmpresa, setNumeroEmpresa] = useState<string | number>(parametro.numero ? parametro.numero : '');
  const [complementoEmpresa, setComplementoEmpresa] = useState<string>(parametro.complemento ? parametro.complemento : '');
  const [radius, setRadius] = useState<number>(parametro.raioCobertura ? parametro.raioCobertura : 0);
  const [valorRadius, setValorRadius] = useState<number>(parametro.valorRaio ? parametro.valorRaio : 0);
  const [file, setFile] = useState<File | null>(null);
  const [fileProduto, setFileProduto] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(parametro.imagem ? `https://api.chatbr.xyz/uploads/${parametro.imagem.split('.')[0]}/${parametro.imagem}` : null);
  const [currency, setCurrency] = useState(parametro.moeda ? parametro.moeda : 'BRL');
  const [isAddressValid, setIsAddressValid] = useState(false);
  const [valorProduto, setValorProduto] = useState(0);
  const [cardapio, setCardapio] = useState(parametro.categorias || null);
  const [listaCarrinho, setListaCarrinho] = useState([] as any);

  const [storeAtivoCategoria, setStoreAtivoCategoria] = useState(0);
  const [storeAtivoProduto, setStoreAtivoProduto] = useState(0);

  const [storeCoordinates, setStoreCoordinates] = useState<[number, number]>(parametro.coordenadas);
  const [viewMode, setViewMode] = useState('grid'); // 'grid' ou 'carousel'

  
  useEffect(() => {
    const handleResize = () => {
      setViewMode(window.innerWidth <= 768 ? 'carousel' : 'grid');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCarrinhoChange = useCallback((novoCarrinho: Produto[]) => {
    setListaCarrinho(novoCarrinho);
  }, []);

  const handleAddProdutoCarrinho = (prod: any) => {
    setListaCarrinho((prevLista: any) => {
      const produtoExistente = prevLista.find((item: any) => item.id === prod.id);

      // Verificar se o estoque deve ser controlado e se há estoque suficiente
      const estoqueDisponivel = prod.store_estoque.movimentaEstoque === 1
        ? prod.store_estoque.quantidadeEmEstoque
        : Infinity;

      if (produtoExistente) {
        const novaQuantidade = produtoExistente.quantidade + 1;

        if (novaQuantidade > estoqueDisponivel) {
          toast.error(`Desculpe... Estoque insuficiente!`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          });
          return prevLista;
        }

        let novoPreco = produtoExistente.preco;

        if (parametros.tipoVenda === 'todos') {
          novoPreco = novaQuantidade >= prod.store_estoque.quantidadeMinimaAtacado
            ? Number(prod.store_estoque.precoVendaAtacado)
            : Number(prod.store_estoque.precoVendaVarejo);
        } else if (parametros.tipoVenda === 'atacado') {
          novoPreco = Number(prod.store_estoque.precoVendaAtacado);
        } else if (parametros.tipoVenda === 'varejo') {
          novoPreco = Number(prod.store_estoque.precoVendaVarejo);
        }

        return prevLista.map((item: any) =>
          item.id === prod.id
            ? { ...item, quantidade: novaQuantidade, preco: novoPreco, store_estoque: item.store_estoque }
            : item
        );
      }

      // Se for um novo produto, validar a quantidade inicial
      const quantidadeInicial = parametros.tipoVenda === 'atacado'
        ? prod.store_estoque.quantidadeMinimaAtacado
        : 1;

      if (quantidadeInicial > estoqueDisponivel) {
        toast.error(`Desculpe... Estoque insuficiente!`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return prevLista;
      }

      return [
        ...prevLista,
        {
          id: prod.id,
          nome: prod.nome,
          quantidade: quantidadeInicial,
          preco: parametros.tipoVenda === 'atacado'
            ? Number(prod.store_estoque.precoVendaAtacado)
            : Number(prod.store_estoque.precoVendaVarejo),
          imagem: prod.imagem,
          store_estoque: prod.store_estoque
        }
      ];
    });
  };


  const carregamentoStore = (id: any) => {

    StoreApi.getStoreEmpresa(id).then((response: any) => {

      if(parametro.isModal === undefined)
        response.data.isModal = false;
      else
        response.data.isModal = parametro.isModal;

      console.log(response.data)
      
      setParametro(response.data);
      setCardapio(response.data.categorias);
      handleNumeroEmpresaChange(response.data.numero)
      handleComplementoEmpresaChange(response.data.complemento);
    });
  }

  const handleComplementoChange = (newComplemento: string) => {
    setComplemento(newComplemento);
  };

  const handleAddressEmpresaChange = (newAddress: any) => {
    setAddressEmpresa(newAddress);
  };

  const currencies = [
    { code: "USD", name: "Dólar Americano", symbol: "$" },
    { code: "EUR", name: "Euro", symbol: "€" },
    { code: "BRL", name: "Real Brasileiro", symbol: "R$" },
    { code: "JPY", name: "Iene Japonês", symbol: "¥" },
    { code: "GBP", name: "Libra Esterlina", symbol: "£" },
    { code: "AUD", name: "Dólar Australiano", symbol: "A$" },
    { code: "CAD", name: "Dólar Canadense", symbol: "C$" },
    { code: "CHF", name: "Franco Suíço", symbol: "CHF" }
  ];

  const formatCurrency = (val: number, sign: string) => {
    const c = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(val);

    const z = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: sign,
    }).format(0);

    if (c !== z) {
      return c;
    }
    else {
      return z;
    }
  };

  type BackgroundStyle = {
    size: string;
    position: string;
    repeat: string;
    filter: string;
    borderRadius: string;
    boxShadow: string;
  };

  const [backgroundStyle, setBackgroundStyle] = useState<BackgroundStyle>({
    size: "cover",
    position: "center",
    repeat: "no-repeat",
    filter: "none",
    borderRadius: "0px",
    boxShadow: "none",
  });

  const handleImageChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Evento disparado:", event); // Verifique se este log aparece
    const selectedFile = event.target.files?.[0];

    console.log("Arquivo selecionado:", selectedFile);

    if (!selectedFile) {
      console.warn("Nenhum arquivo foi selecionado.");
      return;
    }

    setFile(selectedFile);

    const reader = new FileReader();
    reader.onload = () => {
      if (selectedFile) {
        setImageUrl(reader.result as string);
      }
      else {
        if (imageUrl)
          setImageUrl(`https://api.chatbr.xyz/uploads/${imageUrl.split('.')[0]}/${imageUrl}` + reader.result as string);

      }
    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o arquivo:", error);
    };

    reader.readAsDataURL(selectedFile);
  };

  const handleImageProdutoChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log("Evento disparado:", event); // Verifique se este log aparece
    const selectedFile = event.target.files?.[0];

    console.log("Arquivo selecionado:", selectedFile);

    if (!selectedFile) {
      console.warn("Nenhum arquivo foi selecionado.");
      return;
    }

    setFileProduto(selectedFile);

    const reader = new FileReader();
    reader.onload = () => {

    };
    reader.onerror = (error) => {
      console.error("Erro ao ler o arquivo:", error);
    };

    reader.readAsDataURL(selectedFile);

  };

  const handleSubmit = async () => {

    if (isAddressValid) {

      const bodyData = {
        codUserInstance: parametro.codUserInstance,
        url: parametro.url,
        nome: empresa || '',
        descricao: descricaoEmpresa || '',
        endereco: addressEmpresa || parametro.endereco,
        numero: numeroEmpresa || numero || parametro.numero,
        complemento: complementoEmpresa || complemento || parametro.complemento,
        corFundo: colorBackground || parametro.corFundo,
        cor1: colorPrimary || parametro.cor1,
        cor2: colorSecondary || parametro.cor2,
        coordenadas: storeCoordinates.join(',') || parametro.coordenadas,
        moeda: currency || parametro.moeda,
        raioCobertura: radius || parametro.raioCobertura,
        valorRaio: valorRadius || parametro.valorRaio,
        tipoVenda: tipoVenda,
        ativo: parametro.ativo,
      };

      try {
        StoreApi.updateStoreEmpresa(parametro.codUserInstance, file, bodyData).then((updateStore) => {
          console.log(updateStore);
          window.location.href = parametro.url;
        });
      } catch (error) {
        console.error("Erro ao atualizar a empresa:", error);
      }

    }
  };

  useEffect(() => {
    let lgMediaQuery = window.matchMedia('(min-width: 1024px)')

    function onMediaQueryChange({ matches }: { matches: boolean }) {
      setTabOrientation(matches ? 'vertical' : 'horizontal')
    }

    onMediaQueryChange(lgMediaQuery)
    lgMediaQuery.addEventListener('change', onMediaQueryChange)

    return () => {
      lgMediaQuery.removeEventListener('change', onMediaQueryChange)
    }
  }, [])

  const [upload, setUpload] = useState({} as any);

  useEffect(() => {
    if (upload && selectedCategory !== '') {
      StoreApi.importarStoreEstoqueProduto(upload, selectedCategory).then((r: any) => {
        if (r.status === 'sucesso')
          window.location.href = parametros.url;
        console.log(r);
      });
    }
  }, [upload]); // Dispara apenas quando `upload` mudar

  const handleFileUpload = (files: File[]) => {
    setUpload(files[0]); // Atualiza o estado primeiro

  };


  useEffect(() => {
    carregamentoStore(parametros.codUserInstance)
    if (selectedCategory) {
      const category = cardapio.find((item: Categoria) => item.id === Number(selectedCategory))
      if (category) {
        setNewCategory(category.nome)
        setNewCategoryDescription(category.descricao)
        setStoreAtivoCategoria(category.ativo);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      }
    } else {
      setNewCategory('')
      setNewCategoryDescription('')
      setStoreAtivoCategoria(0);
      setNewProduto('')
      setNewProdutoDescription('')
      setValorProduto(0);
      setFileProduto(null);
      setSelectedProduto('');
      setStoreAtivoProduto(0);
    }
  }, [selectedCategory])

  useEffect(() => {
    carregamentoStore(parametro.codUserInstance)
    if (selectedProduto) {

      const category = cardapio.find((item: any) => item.id === Number(selectedCategory))?.produtos
      const produto = category?.find((item: any) => item.id === Number(selectedProduto))
      if (produto) {
        setNewProduto(produto.nome)
        setNewProdutoDescription(produto.descricao)
        setValorProduto(parametros.tipoVenda === 'varejo' ? Number(produto.store_estoque.precoVendaVarejo) : Number(produto.store_estoque.precoVendaAtacado))
        setStoreAtivoProduto(produto.ativo);
      }
    } else {
      setNewProduto('')
      setNewProdutoDescription('')
      setValorProduto(0);
      setFileProduto(null);
      setSelectedProduto('');
      setStoreAtivoProduto(0);
    }
  }, [selectedProduto])

  function handleDeleteCategory(selectedCategory: any) {
    try {
      StoreApi.deleteStoreCategoria(selectedCategory).then((createCategoriaStore) => {
        console.log("Categoria atualizada com sucesso:", createCategoriaStore);
        carregamentoStore(parametro.codUserInstance);
        setNewCategory("");
        setNewCategoryDescription("");
        setSelectedCategory("");
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      });
    } catch (error) {
      console.error("Erro ao atualizar a categoria:", error);
    }

  }

  async function handleAddOrEditCategory() {

    const category = cardapio.find((item: any) => item.id === Number(selectedCategory));
    if (selectedCategory !== '') {
      var bodyData = {
        codStoreEmpresa: parametro.id,
        nome: newCategory || '',
        descricao: newCategoryDescription || '',
        ativo: storeAtivoCategoria || category.ativo,
      };

      try {
        const createCategoriaStore = await StoreApi.updateStoreCategoria(selectedCategory, bodyData);
        console.log("Categoria atualizada com sucesso:", createCategoriaStore);
        carregamentoStore(parametro.codUserInstance);
        // Resetar os campos
        setNewCategory('');
        setNewCategoryDescription('');
        setSelectedCategory('');
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao atualizar a categoria:", error);
      }
    } else {
      const bodyData = {
        codStoreEmpresa: parametro.id,
        nome: newCategory || '',
        descricao: newCategoryDescription || '',
        ativo: storeAtivoCategoria,
      };

      try {
        const createCategoriaStore = await StoreApi.createStoreCategoria(bodyData);
        console.log("Categoria criada com sucesso:", createCategoriaStore);
        carregamentoStore(parametro.codUserInstance);
        // Resetar os campos
        setNewCategory('');
        setNewCategoryDescription('');
        setSelectedCategory('');
        setStoreAtivoCategoria(0);
        setNewProduto('')
        setNewProdutoDescription('')
        setValorProduto(0);
        setFileProduto(null);
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao criar a categoria:", error);
      }
    }
  }

  async function handleAddOrEditProduto() {
    const category = cardapio.find((item: any) => item.id === Number(selectedCategory));
    const produto = category?.produtos.find((item: any) => item.id === Number(selectedProduto));

    if (selectedProduto !== '') {
      console.log(category);

      // Dados para editar produto existente
      const bodyDataUpdate = {
        codStoreCategoria: category.id,
        nome: newProduto || produto.nome,
        descricao: newProdutoDescription || produto.descricao,
        valor: valorProduto || produto.valor,
        ativo: storeAtivoProduto,
      };

      try {
        const resultado = await StoreApi.updateStoreProduto(produto.id, fileProduto, bodyDataUpdate);
        console.log("Produto atualizado com sucesso:", resultado);
        carregamentoStore(parametro.codUserInstance);

        // Resetar os campos após a atualização
        setNewProduto('');
        setNewProdutoDescription('');
        setValorProduto(0);
        setFileProduto(null); // Se você está usando um arquivo, pode resetá-lo
        setSelectedProduto('');
        setStoreAtivoProduto(0);
      } catch (error) {
        console.error("Erro ao atualizar o produto:", error);
      }
    } else {
      // Dados para criar um novo produto
      const bodyDataCreate = {
        codStoreCategoria: category.id,
        nome: newProduto || "",
        descricao: newProdutoDescription || "",
        valor: valorProduto || 0,
        ativo: storeAtivoProduto,
      };

      try {
        // Criando o produto na loja
        const createProdutoStore = await StoreApi.createStoreProduto(bodyDataCreate, fileProduto);
        console.log("Produto criado com sucesso:", createProdutoStore);
        carregamentoStore(parametro.codUserInstance);

        window.location.href = parametro.url;
      } catch (error) {
        console.error("Erro ao criar o produto:", error);
      }
    }
  }

  function handleDeleteProduto() {
    try {
      StoreApi.deleteStoreProduto(selectedProduto).then((deleteProdutoStore) => {
        console.log("Produto deletado com sucesso:", deleteProdutoStore);
        carregamentoStore(parametro.codUserInstance);
        setNewProduto("");
        setNewProdutoDescription("");
        setValorProduto(0);
        setStoreAtivoProduto(0);
        setFileProduto(null);
        setSelectedProduto("");
      });
    } catch (error) {
      console.error("Erro ao atualizar a categoria:", error);
    }
  }

  const handleStoreCoordinatesChange = (coordinates: [number, number]) => {
    setStoreCoordinates(coordinates);
  };

  const handleNumeroEmpresaChange = (newNumero: string | number) => {
    setNumeroEmpresa(newNumero);
  };

  const handleComplementoEmpresaChange = (newComplemento: string) => {
    setComplementoEmpresa(newComplemento);
  };

  const atualizaCarrinho = {
    carrinho: listaCarrinho, // listaCarrinho deve ser do tipo Produto[]
    parametros: parametro
  };

  return (
    <div style={{ backgroundColor: colorBackground }}>
      {parametro.ativo === 0 ?
        <div className="bg-yellow-500 text-white text-center py-2">
          A loja está fechada no momento.
        </div>
        : <></>}
      {cookies.get('user') !== undefined && (cookies.get('user').permissions[0]?.name === "Plano Teste" || cookies.get('user').permissions[0]?.name === "Plano Basic") ? (

        <div className="w-full px-2 py-2 md:px-10 ">
          <section
            id="editor1"
            className="p-6 bg-gray-100 rounded-lg grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 mt-10"
          >
            <div>
              <label htmlFor="empresa-name" className="block text-md font-semibold text-gray-700 mb-1">
                Nome da Empresa:
              </label>
              <input
                id="empresa-name"
                type="text"
                value={empresa}
                onChange={(e) => setEmpresa(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="descricao-empresa" className="block text-md font-semibold text-gray-700 mb-1">
                Descrição da Empresa:
              </label>
              <input
                id="descricao-empresa"
                value={descricaoEmpresa}
                onChange={(e) => setDescricaoEmpresa(e.target.value)}
                className="w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div>
              <label htmlFor="imagem-capa" className="block text-md font-semibold text-gray-700 mb-1">
                Imagem de Capa:
              </label>
              <input type="file" accept="image/*" onChange={handleImageChange} className="w-full file:mr-2 file:py-2 file:px-4 file:border-0 file:bg-blue-50 file:text-blue-700 rounded-lg" />
            </div>

            <div className="md:col-span-2 lg:col-span-3">
              <label htmlFor="endereco-empresa" className="block text-md font-semibold text-gray-700 mb-1">
                Endereço da Empresa:
              </label>
              <GpsAddressPicker
                onCodUserInstance={parametro.codUserInstance}
                initialAddress={parametro.endereco || ''}
                initialNumero={numeroEmpresa}
                initialComplemento={complementoEmpresa}
                onAddressChange={handleAddressEmpresaChange}
                onNumeroChange={handleNumeroEmpresaChange}
                onComplementoChange={handleComplementoEmpresaChange}
                onCoordinatesChange={handleStoreCoordinatesChange}
                onValidationChange={setIsAddressValid}
                radius={radius}
                isStore={true}
              />

            </div>
            <div>
              <label htmlFor="secondary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Limite do raio de cobertura para frete :
              </label>
              <input
                type="number"
                id="radius"
                value={radius}
                onChange={(e) => setRadius(Number(e.target.value))}
                className="border rounded px-3 py-1 mb-4 w-full"
                min="0"
              />

            </div>
            <div>
              <div>
                <label htmlFor="valor-produto" className="block text-md font-semibold text-gray-700 mb-1">
                  Valor por raio de cobertura para frete:
                </label>
                <div className="flex items-center gap-2">
                  <input
                    type="number"
                    value={valorRadius}
                    onChange={(e) => setValorRadius(parseFloat(e.target.value) || 0)}
                    className="rounded-md p-1 border border-gray-300"
                    min={0}
                    placeholder="Enter amount"
                  />  <p className="text-md font-semibold mt-1">
                    {formatCurrency(valorRadius, currency)}
                  </p>
                </div>

              </div>
            </div>
            <div>
              <label className="block text-md font-semibold text-gray-700 mb-1">
                Tipo de venda:
              </label>
              <div className="flex space-x-2">
                <button
                  className={`px-4 py-2 rounded-lg border ${tipoVenda === "varejo"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-gray-700 border-gray-300"
                    } hover:bg-blue-600 hover:text-white transition`}
                  onClick={(e: any) => { e.preventDefault(); setTipoVenda("varejo"); }}
                >
                  Varejo
                </button>
                <button
                  className={`px-4 py-2 rounded-lg border ${tipoVenda === "atacado"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-gray-700 border-gray-300"
                    } hover:bg-blue-600 hover:text-white transition`}
                  onClick={(e: any) => { e.preventDefault(); setTipoVenda("atacado"); }}
                >
                  Atacado
                </button>
                <button
                  className={`px-4 py-2 rounded-lg border ${tipoVenda === "todos"
                    ? "bg-blue-500 text-white border-blue-500"
                    : "bg-white text-gray-700 border-gray-300"
                    } hover:bg-blue-600 hover:text-white transition`}
                  onClick={(e: any) => { e.preventDefault(); setTipoVenda("todos"); }}
                >
                  Varejo e Atacado
                </button>
              </div>
            </div>
            <div>
              <label htmlFor="color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Escolha a Cor de Fundo:
              </label>
              <input
                id="color-picker"
                type="color"
                value={colorBackground}
                onChange={(e) => setColorBackground(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div>
              <label htmlFor="primary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Cor Primária do Texto:
              </label>
              <input
                id="primary-color-picker"
                type="color"
                value={colorPrimary}
                onChange={(e) => setColorPrimary(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div>
              <label htmlFor="secondary-color-picker" className="block text-md font-semibold text-gray-700 mb-1">
                Cor Secundária do Texto:
              </label>
              <input
                id="secondary-color-picker"
                type="color"
                value={colorSecondary}
                onChange={(e) => setColorSecondary(e.target.value)}
                className="w-full h-10 p-1 border border-gray-300 rounded-lg cursor-pointer"
              />
            </div>

            <div className="md:col-span-2 lg:col-span-3 flex gap-4">
              <button
                onClick={handleSubmit}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                Salvar
              </button>
            </div>
            <div>
              <label htmlFor="select-category" className="block text-md font-semibold text-gray-700 mb-1">
                Selecione uma Categoria:
              </label>
              <select
                id="select-category"
                value={selectedCategory}
                onChange={(e) => { setSelectedCategory(e.target.value); setCategoriaAtual(cardapio.find((item: Categoria) => item.id === Number(e.target.value))) }}
                className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
              >
                <option value="">Adicionar nova categoria</option>
                {cardapio !== null && cardapio.map((categoria: any) => (
                  <option key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="new-category" className="block text-md font-semibold text-gray-700 mb-1">
                Nome da Categoria:
              </label>
              <input
                id="new-category"
                type="text"
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
                required
                className={`w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${newCategory.trim() === "" && 'border-red-500 focus:ring-red-500'} `}
              />
            </div>

            <div>
              <label htmlFor="new-category-description" className="block text-md font-semibold text-gray-700 mb-1">
                Descrição da Categoria:
              </label>
              <input
                id="new-category-description"
                value={newCategoryDescription}
                onChange={(e) => setNewCategoryDescription(e.target.value)}
                className={`w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${newCategoryDescription.trim() === "" && 'border-red-500 focus:ring-red-500'} `}
              />
            </div>
            <div className="relative inline-flex col-6 select-none ml-1 pl-4">
              <div className="relative inline-block w-16 select-none">

                <small className='text-center whitespace-nowrap'>Indisponivel / Disponivel</small>
                {storeAtivoCategoria === 1 ?
                  <>
                    <input
                      type="checkbox"
                      name="toggle"
                      id="storeCategoriaAtivo"
                      checked
                      onClick={() => { setStoreAtivoCategoria(0); }}
                      className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="storeCategoriaAtivo"
                      className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                    >
                    </label>
                  </>
                  :
                  <>
                    <input
                      type="checkbox"
                      name="toggle"
                      id="storeCategoriaAtivo"
                      onClick={() => { setStoreAtivoCategoria(1); }}
                      className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                    />
                    <label
                      htmlFor="storeCategoriaAtivo"
                      className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                    ></label>

                  </>
                }

              </div>
            </div>

            <div className="md:col-span-2 lg:col-span-3 flex gap-4">
              <button type="submit"
                onClick={handleAddOrEditCategory}
                className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                {selectedCategory ? 'Editar Categoria' : 'Adicionar Categoria'}
              </button>
              {selectedCategory ?
                <button
                  type="button"
                  onClick={() => { handleDeleteCategory(selectedCategory) }}
                  className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:outline-none"
                >
                  Remover Categoria
                </button> :
                <></>}
            </div>
            {selectedCategory && (
              <>
                <div>
                  <label htmlFor="select-produto" className="block text-md font-medium text-gray-700 mb-1">
                    Selecione um Produto:
                  </label>
                  <select
                    id="select-produto"
                    value={selectedProduto}
                    onChange={(e) => { setSelectedProduto(e.target.value); setProdutoAtual(categoriaAtual.produtos.find((item: Produto) => item.id === Number(e.target.value))); setFileProduto(null); }}
                    className="w-full p-1 border border-gray-300 rounded-md focus:ring focus:ring-blue-300"
                  >
                    <option value="">Adicionar novo produto</option>
                    {cardapio !== null && cardapio.map((categoria: any) =>
                      categoria.produtos &&
                      categoria.produtos
                        .filter((produto: any) => produto.codStoreCategoria === Number(selectedCategory))
                        .map((produto: any) => (
                          <option key={produto.id} value={produto.id}>
                            {produto.nome}
                          </option>
                        ))
                    )}
                  </select>
                </div>

                <div>
                  <label htmlFor="new-produto" className="block text-md font-medium text-gray-700 mb-1">
                    Nome do Produto:
                  </label>
                  <input
                    id="new-produto"
                    type="text"
                    value={newProduto}
                    onChange={(e) => setNewProduto(e.target.value)}
                    className={`w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${newProduto.trim() === "" && 'border-red-500 focus:ring-red-500'} `}
                  />
                </div>

                <div>
                  <label htmlFor="new-produto-description" className="block text-md font-medium text-gray-700 mb-1">
                    Descrição do Produto:
                  </label>
                  <input
                    id="new-produto-description"
                    value={newProdutoDescription}
                    onChange={(e) => setNewProdutoDescription(e.target.value)}
                    className={`w-full p-1 border border-gray-300 rounded-lg shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none ${newProdutoDescription.trim() === "" && 'border-red-500 focus:ring-red-500'} `}
                  />
                </div>

                <div>
                  <label htmlFor="imagem-produto" className="block text-md font-semibold text-gray-700 mb-1">
                    Imagem do Produto:
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={handleImageProdutoChange}
                    className="w-full file:mr-4 file:py-2 file:px-4 file:border-0 file:bg-blue-50 file:text-blue-700 rounded-lg"
                  />
                </div>

                {selectedProduto &&

                  <>
                    <div>
                      <label htmlFor="valor-produto" className="block text-md font-semibold text-gray-700 mb-1">
                        Informações Produto:
                      </label>
                      <div className="flex items-center gap-2">
                        <DialogTrigger>
                          <Button className="flex items-center px-2 py-1 text-xs white font-semibold text-gray-500 hover:bg-gray-100 rounded-md transition cursor-pointer">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true" className="h-5 w-5 text-green-gray-500">
                              <path fill-rule="evenodd" d="M11.078 2.25c-.917 0-1.699.663-1.85 1.567L9.05 4.889c-.02.12-.115.26-.297.348a7.493 7.493 0 00-.986.57c-.166.115-.334.126-.45.083L6.3 5.508a1.875 1.875 0 00-2.282.819l-.922 1.597a1.875 1.875 0 00.432 2.385l.84.692c.095.078.17.229.154.43a7.598 7.598 0 000 1.139c.015.2-.059.352-.153.43l-.841.692a1.875 1.875 0 00-.432 2.385l.922 1.597a1.875 1.875 0 002.282.818l1.019-.382c.115-.043.283-.031.45.082.312.214.641.405.985.57.182.088.277.228.297.35l.178 1.071c.151.904.933 1.567 1.85 1.567h1.844c.916 0 1.699-.663 1.85-1.567l.178-1.072c.02-.12.114-.26.297-.349.344-.165.673-.356.985-.57.167-.114.335-.125.45-.082l1.02.382a1.875 1.875 0 002.28-.819l.923-1.597a1.875 1.875 0 00-.432-2.385l-.84-.692c-.095-.078-.17-.229-.154-.43a7.614 7.614 0 000-1.139c-.016-.2.059-.352.153-.43l.84-.692c.708-.582.891-1.59.433-2.385l-.922-1.597a1.875 1.875 0 00-2.282-.818l-1.02.382c-.114.043-.282.031-.449-.083a7.49 7.49 0 00-.985-.57c-.183-.087-.277-.227-.297-.348l-.179-1.072a1.875 1.875 0 00-1.85-1.567h-1.843zM12 15.75a3.75 3.75 0 100-7.5 3.75 3.75 0 000 7.5z" clipRule="evenodd" />
                            </svg>
                          </Button>
                          <Modal className="fixed inset-0 z-20 flex items-center w-full h-full justify-center bg-black/50 backdrop-blur-sm border-none" style={{ "zIndex": "99999" }}>
                            <Dialog className="container shadow-xl bg-gray-100 border mb-10 h-auto border-gray-200 rounded-lg lg:w-[100%] md:w-[100%] sm:w-[100%] w-[100%] transform transition-all animate-fadeIn z-10">
                              <Button className="rounded-lg bg-gray-100 hover:text-g text-gray-100 h-full w-full hover:bg-gray-200 cursor-pointer" slot='close'><h1 className='flex text-lg text-gray-400'><ChevronLeftIcon className="h-6 w-6 mt-3 text-gray-400" /><p className='mt-3'>Voltar</p> </h1></Button>
                              <label htmlFor="valor-produto" className="block text-md text-center font-semibold text-gray-700 mb-1">
                                Produto {produtoAtual.nome}
                              </label>
                              <ProductForm data={produtoAtual} />
                            </Dialog>
                          </Modal>
                        </DialogTrigger>

                      </div>

                    </div>

                  </>
                }
                <div className="relative inline-flex col-6 select-none ml-1 pl-4">
                  <div className="relative inline-block w-16 select-none">

                    <small className='text-center whitespace-nowrap'>Indisponivel / Disponivel</small>
                    {storeAtivoProduto === 1 ?
                      <>
                        <input
                          type="checkbox"
                          name="toggle"
                          id="storeProdutoAtivo"
                          checked
                          onClick={() => { setStoreAtivoProduto(0); }}
                          className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                        />
                        <label
                          htmlFor="storeProdutoAtivo"
                          className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                        >
                        </label>
                      </>
                      :
                      <>
                        <input
                          type="checkbox"
                          name="toggle"
                          id="storeProdutoAtivo"
                          onClick={() => { setStoreAtivoProduto(1); }}
                          className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-8 h-8 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                        />
                        <label
                          htmlFor="storeProdutoAtivo"
                          className="block h-8 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                        ></label>

                      </>
                    }

                  </div>

                </div>
                {categoriaAtual.produtos.length === 0 &&
                  <div>
                    <label htmlFor="garantia" className="block text-md font-semibold text-gray-700 mb-1">
                      Importar Produtos:
                    </label>
                    {/* Botão de Upload de CSV */}
                    <div className="w-full max-w-4xl mx-auto min-h-96 border border-dashed border-neutral-200 dark:border-neutral-800 rounded-lg">
                      <FileUpload onChange={handleFileUpload} />
                    </div>
                  </div>}

                <div className="md:col-span-2 lg:col-span-3 flex gap-4 mt-2">
                  <button
                    type="button"
                    onClick={handleAddOrEditProduto}
                    className="mt-2 bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:outline-none"
                  >
                    {selectedProduto !== '' ? 'Editar Produto' : 'Adicionar Produto'}
                  </button>
                  {selectedProduto !== '' ?
                    <DialogTrigger>
                      <Button className="mt-2 bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600 focus:ring-2 focus:ring-red-500 focus:outline-none transition">
                        Remover Produto
                      </Button>
                      <Modal className="fixed inset-0 z-20 flex items-center justify-center bg-black/50 backdrop-blur-sm z-[9999]">
                        <Dialog className="relative shadow-xl bg-white border border-gray-200 px-6 py-4 rounded-lg w-full max-w-md transform transition-all animate-fadeIn">
                          <form>
                            <Heading slot="title" className="leading-none mt-0 text-xl font-bold">Excluir Produto</Heading>
                            <p className="text-gray-600 mt-4">Você tem certeza que deseja remover este produto?</p>
                            <div className="mt-8 flex justify-end space-x-3">
                              <Button className="px-4 py-2 bg-red-300 text-red-700 rounded-md hover:bg-red-400 transition" slot="close">
                                Cancelar
                              </Button>
                              <Button className="px-4 py-2 bg-green-500 text-white rounded-md hover:bg-green-600 transition" onPress={() => handleDeleteProduto()}>
                                Confirmar
                              </Button>
                            </div>
                          </form>
                        </Dialog>
                      </Modal>
                    </DialogTrigger>

                    :
                    <></>}
                </div>
              </>
            )}
          </section>
        </div>

      )
        :
        (
          <></>
        )}


      <section id="produtos" aria-labelledby="produtos-title" style={{ backgroundColor: colorBackground }} className="py-2">
        <ImageClipPaths id={id} />
        <div>
          {imageUrl &&

            <DraggableImage imageUrl={imageUrl} backgroundStyle={backgroundStyle} />
          }
          <div
            className="w-full text-center lg:mt-6">
            <h2
              className="font-display justify-center block text-3xl sm:text-4xl lg:text-5xl font-semibold mt-1"
              style={{ color: colorPrimary }}
            >
              {empresa}
            </h2>
            <p className="mt-2 text-md justify-center sm:text-xl mb-2" style={{ color: colorSecondary }}>
              {descricaoEmpresa}
            </p></div>
          <TabGroup className="mt-1 sm:mt-1 lg:mt-1 grid gap-4 grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-5 m-4" vertical={tabOrientation === 'vertical'}>

            <div className="relative justify-center flex overflow-x-auto pb-4 sm:mx-0 sm:block sm:overflow-visible sm:pb-0">

              <TabList className=" grid-cols-1 lg:grid-cols lg:grid-cols-1 lg:text-left sm:grid-cols-1 sm:text-center">
                {({ selectedIndex }) => (
                  <>
                    {cardapio !== null && cardapio.map((categorias: any, categoriasIndex) => (
                      <div key={categorias.id} className="relative lg:pl-8">
                        <div className="relative text-center">
                          <div className={clsx(
                            'font-mono text-xl',
                            categoriasIndex === selectedIndex ? 'text-blue-600' : 'text-slate-500'
                          )}
                          >
                            <Tab className="focus-visible:outline-none font-semibold">
                              <span className="absolute inset-0" />

                              <span className="mt-3 block text-xl font-semibold" style={{ color: colorPrimary }}>
                                {categorias.nome}
                              </span>
                            </Tab>
                          </div>
                          <span className="block text-md font-semibold" style={{ color: colorSecondary }}>{categorias.descricao}
                          </span>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </TabList>
            </div>
            <TabPanels className="lg:col-span-4">
              {cardapio !== null && cardapio.map((p: any) => (
                <TabPanel
                  key={p.id}
                  className="grid grid-cols-1 gap-2 sm:grid-cols-1 lg:grid-cols-3"
                  unmount={false}
                >
                  {p.produtos.map((prod: any, i: any) => (
                    <>
                      {prod.ativo ?
                        <div className="p-2">
                          <div className="group relative h-48 items-center justify-center overflow-hidden rounded-xl">
                            <img
                              className="absolute inset-0 h-full w-full object-cover transition-transform duration-500 group-hover:scale-105 group-hover:brightness-110"
                              src={prod.imagem ? 'https://api.chatbr.xyz/uploads/produto/' + prod.imagem : ''}
                              alt={prod.nome}
                            />
                            {parametro.ativo === 1 &&
                              <button
                                className="absolute bottom-4 right-4 w-14 h-14 flex items-center justify-center text-white bg-green-600 rounded-full hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
                                aria-label="Adicionar mais produtos" onClick={() => handleAddProdutoCarrinho(prod)}
                              >
                                <span className="text-3xl font-bold text-center items-center justify-center mb-1">+</span>
                              </button>
                            }
                          </div>
                          <div className="text-center">
                            <h3 className="text-xl font-bold" style={{ color: colorPrimary }}>{prod.nome}</h3>
                            <p className="text-base" style={{ color: colorSecondary }}>{prod.descricao}</p>
                            {parametros.tipoVenda === 'varejo' &&
                              <small className="block text-base" style={{ color: colorPrimary }}>Valor Varejo: {formatCurrency(Number(prod.store_estoque.precoVendaVarejo), currency)}</small>}
                            {parametros.tipoVenda === 'atacado' &&
                              <>
                                <small className="block text-base" style={{ color: colorPrimary }}>Valor Atacado: {formatCurrency(Number(prod.store_estoque.precoVendaAtacado), currency)}</small>
                                <small className="block text-xs" style={{ color: colorPrimary }}>* valor atacado a partir de {prod.store_estoque.quantidadeMinimaAtacado} unidades.</small>
                              </>
                            }
                            {parametros.tipoVenda === 'todos' &&
                              <>{console.log(prod)}
                                <small className="block text-base" style={{ color: colorPrimary }}>Valor Varejo: {formatCurrency(Number(prod.store_estoque.precoVendaVarejo), currency)}</small>
                                <small className="block text-base" style={{ color: colorPrimary }}>Valor Atacado: {formatCurrency(Number(prod.store_estoque.precoVendaAtacado), currency)}</small>
                                <small className="block text-xs" style={{ color: colorPrimary }}>* valor atacado a partir de {prod.store_estoque.quantidadeMinimaAtacado} unidades.</small>
                              </>
                            }
                          </div>
                        </div> : <></>}</>))}
                </TabPanel>
              ))}
            </TabPanels>

            {(parametro.endereco !== "" || parametro.endereco !== null) && addressEmpresa !== "" && parametro.ativo === 1 ? <> <Carrinho parametros={atualizaCarrinho} onCarrinhoChange={handleCarrinhoChange} /></> : <></>}
          </TabGroup>


        </div>
      </section>
    
    </div >


  )
}