import React, { useEffect, useRef, useState, ChangeEvent } from 'react';

import Cookies from 'universal-cookie';
import BotConfig from './BotConfig';
import StoreApi from '../../../services/StoreApi';
import { BuildingOffice2Icon, ChatBubbleBottomCenterTextIcon, ChevronLeftIcon, UserGroupIcon } from '@heroicons/react/24/solid';
import { IconBellQuestion, IconBrandTeams, IconBrandWhatsapp, IconBuildingBridge, IconBuildingCommunity, IconBuildingMonument, IconBuildingStore, IconBuildingWarehouse, IconDeviceIpadHorizontal, IconPackage, IconPlaneDeparture, IconRobot, IconShoppingCart, IconShoppingCartShare, IconUser, IconUsers } from '@tabler/icons-react';
import { Button, Dialog, DialogTrigger, Modal } from 'react-aria-components';
import AuthWhatsApp from './AuthWhatsApp';
import { Store } from './Store';
import Departamentos from './Departamentos';
import Clientes from './Clientes';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

export default function PainelConfig() {

  const [store, setStore] = useState([] as any);
  const cookies = new Cookies();
  const [url, setUrl] = useState("");
  const [urlSave, setUrlSave] = useState(false);
  const [skeletonLoja, setSkeletonLoja] = useState(true);
  const [storeAtivo, setStoreAtivo] = useState(0);

  useEffect(() => {
    setSkeletonLoja(true);
    if (cookies.get('instancias')[0].id !== undefined)
      carregamentoStore(cookies.get('instancias')[0].id);
    setSkeletonLoja(false);
  }, []);

  const actions = [

    {
      title: "Painel do WhatsApp",
      description: "Sincronize sua conta do WhatsApp para gerenciar conversas diretamente pela plataforma, desative ou ative o atendimento automatizado ao número vinculado e caso precisar, desvincule sua conta do WhatsApp.",
      icon: IconBrandWhatsapp,
      iconForeground: "text-green-700",
      iconBackground: "bg-green-50",
      index: 'whatsApp'
    },
    {
      title: "Painel Atendimento Automatizado",
      description: "Implemente fluxos automáticos de mensagens para agilizar o atendimento ao cliente e otimizar os processos. Você tem como opção menus personalizados, filas de atendimento por departamento e cardápio digital.",
      icon: IconRobot,
      iconForeground: "text-sky-700",
      iconBackground: "bg-sky-50",
      index: 'automatizado'
    },
    {
      title: "Painel Loja Virtual",
      description: "Simplifique a gestão da sua loja e aumente suas vendas, gerencie suas vendas de maneira simples e intuitiva com a Loja Virtual. Organize seu estoque, calcule fretes automaticamente por raio de entrega e ofereça opções de retirada no local ou delivery para seus clientes.",
      icon: IconShoppingCart,
      iconForeground: "text-yellow-700",
      iconBackground: "bg-yellow-50",
      index: store.url !== undefined ? 'loja' : 'criarLoja'
    },
    {
      title: "Painel Atendentes e Departamentos",
      description: "Adicione e exclua contas, gerencie seus atendentes alocando-os em seus respectivos departamentos. Adicione e exclua departamentos, gerencie seus departamentos e suas filas de espera, para garantir um atendimento rápido, humanizado e eficiente.",
      icon: IconBuildingCommunity,
      iconForeground: "text-purple-700",
      iconBackground: "bg-purple-50",
      index: 'atendentes'
    },
    {
      title: "Painel Clientes",
      description: "Adicione e exclua clientes, gerencie seus clientes, atualize informações de cadastro e acompanhe o histórico de interações e pedidos",
      icon: IconUser,
      iconForeground: "text-orange-700",
      iconBackground: "bg-orange-50",
      index: 'clientes'
    },
    {
      title: "Painel do Plano",
      description: "Personalize os planos disponíveis, ajuste os recursos e defina limites de uso de acordo com as necessidades do seu negócio.",
      icon: IconPackage,
      iconForeground: "text-indigo-700",
      iconBackground: "bg-indigo-50",
      index: 'plano'
    },
  ];
  const carregamentoStore = async (id: string) => {

    return StoreApi.getStoreEmpresa(id).then((response: any) => {
      console.log(response);
      if (response.data !== '') {
        response.data.isModal = true;
        setStore(response.data);
        setUrlSave(response.data.url)
      }
    })

  };

  const validateUrl = (input: string): boolean => {
    // Regex para validar caracteres permitidos: letras minúsculas, números, hífen e barra
    const validPattern = /^[a-z0-9\-\/]*$/;
    return validPattern.test(input);
  };


  const handleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.toLowerCase(); // Converte para minúsculas
    if (validateUrl(inputValue)) {
      setUrl(inputValue); // Atualiza apenas se o valor for válido
    }
  };


  const handleSave = () => {

    var formDataCreate = {
      codUserInstance: cookies.get('instancias')[0].id,
      url: url,
      nome: '',
      descricao: "",
      imagem: '',
      endereco: '',
      complemento: '',
      telefone: cookies.get('instancias')[0]['info']['response']['info']['Wid'].split('@')[0] + "",
      corFundo: "#FFFFFF",
      cor1: "#000000",
      cor2: "#FF5733",
      coordenadas: "0,0",
      moeda: 'BRL',
      raioCobertura: 0,
      valorRaio: 0,
      tipoVenda: 'varejo',
      ativo: storeAtivo,
    };

    StoreApi.createStoreEmpresa(formDataCreate).then((createStore) => {
      window.location.href = '/painel';
    });
  }


  return (
    <>
      <div className="w-full h-auto">
        <div className="h-8 xml:h-28 bg-green-500" />

        <div className="flex flex-col bg-gray-50 sm:flex-row border border-grey rounded shadow-lg mb-10 m-4 h-auto">

          <div className="w-full bg-gray-50 border-md sm:border-md flex flex-col">

            <div className="py-2 bg-gray-50 px-4 bg-grey-lighter flex flex-col w-full">


              <div className="flex flex-wrap md:m-2">
                <div className="w-full divide-y divide-gray-200 overflow-visible rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                  {actions.map((action, actionIdx) => {
                    // Definindo a origem da transformação e a sombra
                    const transformOrigin = actionIdx % 2 === 0 ? 'origin-left' : 'origin-right';
                    const shadowDirection =
                      actionIdx % 2 === 0
                        ? ' shadow-[4px_4px_20px_rgba(0,0,0,0.1)]' // Sombra do lado direito
                        : ' shadow-[-4px_4px_20px_rgba(0,0,0,0.1)]'; // Sombra do lado esquerdo

                    return (
                      <DialogTrigger>
                        <Button onPress={() => {
                          if (action.index === 'loja' && store.url) {
                            window.location.href = `/${store.url}`;
                          }
                        }}>
                          <div
                            key={action.title}
                            className={classNames(
                              actionIdx === 0 ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none' : '',
                              actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
                              actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg ' : '',
                              actionIdx === actions.length - 1 ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none' : '',
                              `${transformOrigin} ${shadowDirection} group relative min-h-[100%] border-green-300 border-2 bg-white p-6 focus-within:ring-2 focus-within:ring-inset transition-transform transform hover:scale-105 hover:-translate-y-1 duration-300 ease-in-out z-0 hover:z-10`
                            )}
                          >
                            <div>
                              <span
                                className={classNames(
                                  action.iconBackground,
                                  action.iconForeground,
                                  'inline-flex rounded-lg p-3 ring-4 ring-white transition-all duration-300'
                                )}
                              >
                                <action.icon aria-hidden="true" className="size-6" />
                              </span>
                            </div>

                            <div className="mt-8">
                              <h3 className="text-base font-semibold text-gray-900">
                                <span aria-hidden="true" className="absolute inset-0" />
                                {action.title}
                              </h3>
                              <p className="mt-2 text-xs text-gray-500">
                                {action.description}
                              </p>
                            </div>

                            <span
                              aria-hidden="true"
                              className="pointer-events-none absolute right-6 top-6 text-gray-300 group-hover:text-indigo-500 transition-colors duration-300"
                            >
                              <svg fill="currentColor" viewBox="0 0 24 24" className="size-6">
                                <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                              </svg>
                            </span>
                          </div>
                        </Button>

                        <Modal className="fixed inset-0 z-50 flex items-center justify-center w-full pb-10 bg-black/50 backdrop-blur-sm">
                          <Dialog
                            id="scroll-container" className={`container rounded-md shadow-xl bg-white mt-10 ${action.index === 'atendentes' && 'overflow-auto h-[calc(100vh-50px)] my-auto'} ${action.index === 'whatsApp' || action.index === 'criarLoja' || action.index === 'atendentes' ? 'lg:w-auto lg:h-auto' : action.index === 'clientes' ? 'overflow-y-none h-[calc(90vh-60px)] xl:h-[calc(75vh-60px)] lg:h-[calc(75vh-60px)] md:h-[calc(90vh-60px)] sm:h-[calc(90vh-60px)] xs:h-auto' : 'lg:w-[90%]'} md:w-[100%] sm:w-[100%] w-full transform transition-all animate-fadeIn overflow-y-scroll `}
                          >
                            {/* Botão de Voltar fixo */}
                            <div className="sticky top-0 bg-gray-100 z-30">
                              <Button className="rounded-lg bg-gray-100 hover:text-g text-gray-100 h-full w-full hover:bg-gray-200 cursor-pointer" slot={'close'}>
                                <h1 className='flex text-lg text-gray-400'>
                                  <ChevronLeftIcon className="h-6 w-6 mt-3 text-xl text-gray-400" />
                                  <h1 className='mt-2.5'>Voltar</h1>
                                </h1>
                              </Button>
                            </div>

                            {/* Conteúdo da Modal */}
                            {action.index === 'automatizado' ? (
                              <BotConfig idInstance={cookies.get('instancias')[0].id} />
                            ) : action.index === 'whatsApp' ? (
                              <AuthWhatsApp />
                            ) /* : action.index === 'loja' ? (<Store parametros={store} />
                            )  */: action.index === 'criarLoja' ? (
                              <section className="bg-white dark:bg-gray-900 flex">
                                <div className="flex-col items-center px-4 py-4 mx-auto md:inline-flex lg:inline-flex xl:inline-flex">
                                  <IconBuildingStore className='w-20 h-20' />
                                  <h2 className="text-center text-2xl font-semibold tracking-tight text-gray-800 xl:text-3xl dark:text-white">
                                    Escolha o nome da rota para <br />acessar sua Loja Virtual
                                  </h2>
                                  {/* Input para a URL */}
                                  <div className="flex w-full justify-center items-center">
                                    <input
                                      type="text"
                                      value={url}
                                      onChange={handleUrlChange}
                                      placeholder="Digite a URL..."
                                      className="rounded-md flex border border-green-300 py-2 px-2 bg-white text-gray-700 placeholder-gray-400 text-base focus:outline-none focus:ring-2 focus:ring-green-600 focus:border-transparent"
                                    />
                                    {url !== "" && url !== undefined && urlSave &&
                                      <div className="relative inline-flex col-4 select-none ml-1 pl-4 items-center">
                                        <div className="relative inline-block w-10 select-none">

                                          <small className='text-center whitespace-nowrap'>Fechado/Aberto</small>
                                          {storeAtivo === 1 ?
                                            <>
                                              <input
                                                type="checkbox"
                                                name="toggle"
                                                id="storeAtivo"
                                                title="Fechar Cardapio Virtual"
                                                checked
                                                onClick={() => { setStoreAtivo(0); }}
                                                className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                              />
                                              <label
                                                htmlFor="storeAtivo"
                                                className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                              >
                                              </label>
                                            </>
                                            :
                                            <>
                                              <input
                                                type="checkbox"
                                                name="toggle"
                                                title="Abrir Cardapio Virtual"
                                                id="storeAtivo"
                                                onClick={() => { setStoreAtivo(1); }}
                                                className={`checked:bg-green-500 outline-none focus:outline-none checked:right-0 duration-200 ease-in absolute block w-6 h-6 rounded-full bg-white border-4 appearance-none cursor-pointer`}
                                              />
                                              <label
                                                htmlFor="storeAtivo"
                                                className="block h-6 overflow-hidden bg-gray-300 rounded-full cursor-pointer"
                                              ></label>

                                            </>
                                          }

                                        </div>

                                      </div>}
                                    <button
                                      type="submit"
                                      className="py-2 px-4 m-2 bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-green-200 text-white w-30 transition ease-in duration-200 text-center text-base font-semibold focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg "
                                      onClick={() => { handleSave() }}
                                    >
                                      Salvar
                                    </button>
                                  </div>
                                </div>
                              </section>
                            ) : action.index === 'atendentes' ? (
                              < Departamentos />)
                              :
                              action.index === 'clientes' ?
                                <Clientes />
                                :
                                <></>
                            }
                          </Dialog>
                        </Modal>
                      </DialogTrigger>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div >
      </div >

    </>
  )
}
